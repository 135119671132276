import { publishMakeRequest, unPublishComponentsByMakeId } from 'apis/CMS/CMS';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';

const usePublishMake = () => {
  const { handleError } = useErrorHandler();
  const { activeAlert } = useCmsContext();
  const { choice, setComponents, handleChoiceChange } = useEditorContext();
  const { topic, make } = choice;
  const makeId = make?._id;
  const publishMake = async () => {
    try {
      if (topic.publish) {
        const { data: publishedMake } = await publishMakeRequest(makeId);
        handleChoiceChange('make', publishedMake);
        activeAlert({ title: 'הצלחנו', text: `<p>המייק פורסם בהצלחה</p>` });
      } else {
        activeAlert({
          title: 'לא הצלחנו לפרסם',
          text: `<p>צריך לפרסם את הטופיק קודם</p>`,
        });
      }
    } catch (error) {
      handleError(error);
    }
  };
  const unPublishMake = async () => {
    try {
      const {
        data: { make, components },
      } = await unPublishComponentsByMakeId(makeId);
      handleChoiceChange('make', make);
      setComponents(components);
      activeAlert({ title: 'הצלחנו', text: `<p>הפרסום הוסר למייק</p>` });
    } catch (error) {
      handleError(error);
    }
  };
  return { publishMake, unPublishMake };
};

export default usePublishMake;
