/*
// This route take the user to specific track in discovery page.
*/
export const getTrackRoute = (trackRouteName) => {
  return `/track/${trackRouteName}`;
};

export const getTopicRoute = (trackRouteName, topicRouteName) =>
  `/track/${trackRouteName}/topic/${topicRouteName}`;
/*
// This route take the user to specific make.
// Default is intro component of this make.
*/
export const getMakeRoute = (trackRouteName, topicRouteName, makeRouteName) => {
  return `/track/${trackRouteName}/${topicRouteName}/${makeRouteName}`;
};

/*
// This route take the user to specific component.
*/
export const getComponentRoute = (
  trackRouteName,
  topicRouteName,
  makeRouteName,
  componentId
) => {
  return componentId
    ? `/track/${trackRouteName}/topic/${topicRouteName}/${makeRouteName}/component/${componentId}`
    : `/track/${trackRouteName}/topic/${topicRouteName}/${makeRouteName}/component`;
};
export const getQuizComponentRoute = (
  trackRouteName,
  topicRouteName,
  makeRouteName,
  componentId
) => {
  return componentId
    ? `/track/${trackRouteName}/topic/${topicRouteName}/${makeRouteName}/quizMake/component/${componentId}`
    : `/track/${trackRouteName}/topic/${topicRouteName}/${makeRouteName}/quizMake/component`;
};

export const getProfileOptionRoute = (profileOption) => {
  return `${profileOption}`;
};
