import React, { useEffect } from 'react';

import LvlUpBackground from 'assets/icons/lvlUpBackground.svg';
import LvlUpMedalTagIcon from 'assets/icons/lvlUpMedalTag.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setShowLvlUpModal } from 'services/redux/ui.slice.js';

import * as S from './LvlUpModal.style.js';
import { getRankIcon } from 'utils/helpers/index.js';
const LvlUpModal = ({ textColor, background, bgImageOpacity }) => {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.ui.isMobile);
  const rank = useSelector((state) => state.auth.user.rank);
  return (
    <S.LvlUpContainer
      color={textColor}
      background={background}
      onClick={() => dispatch(setShowLvlUpModal(false))}
    >
      <S.BackgroundImage
        bgImageOpacity={bgImageOpacity}
        src={LvlUpBackground}
        alt="background"
      />
      <S.CongratsText>
        <S.SecondaryText>{'כל הכבוד!'}</S.SecondaryText>
        <S.PrimaryText>{'עלית רמה'}</S.PrimaryText>
      </S.CongratsText>
      <S.MedalContainer isMobile={isMobile}>
        <S.RankIcon src={getRankIcon(rank)} alt="rank" />
        <S.MedalTag src={LvlUpMedalTagIcon} alt="medal" />
        <S.Level>{rank}</S.Level>
      </S.MedalContainer>
      {/* //TODO For Points Feature - UI READY */}
      {/* <S.RewardWrapper>
          <S.RewardContainer>
            <S.PointsWon>{'x100'}</S.PointsWon>
            <S.Bonus>{'בונוס עלית רמה'}</S.Bonus>
          </S.RewardContainer>
          <img src={coinIcon} alt="coin" />
        </S.RewardWrapper> */}
      <S.ClaimButton color={textColor} onClick={() => dispatch(setShowLvlUpModal(false))}>
        {'לרמה הבאה!'}
      </S.ClaimButton>
    </S.LvlUpContainer>
  );
};

export default LvlUpModal;
