import {
  deleteFacilitatorComponent,
  getFacilitatorComponents,
  getFacilitatorMakes,
} from 'apis/CMS/facilitator';

import { useErrorHandler } from 'react-error-boundary';
import { useFacilitatorContext } from '../../../context/useFacilitatorContext';

const useDeleteComponent = () => {
  const handleError = useErrorHandler();
  const { choice, setMakes, setComponents } = useFacilitatorContext();
  const { make, component } = choice;
  const makeId = make?._id;
  const componentId = component?._id;

  const deleteComponent = async () => {
    try {
      await deleteFacilitatorComponent(makeId, componentId);
      const { data: makeData } = await getFacilitatorMakes();
      setMakes(makeData);
      const { data: componentData } = await getFacilitatorComponents(makeId);
      setComponents(componentData);
    } catch (error) {
      handleError(error);
    }
  };
  return { deleteComponent };
};

export default useDeleteComponent;
