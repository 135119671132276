import { getQuizMakeComponents, updateQuizMakeComponentIndex } from 'apis/CMS/quizMake';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';

const useDraggableItems = () => {
  const handleError = useErrorHandler();
  const { choice, handleChoiceChange, setQuizComponents } = useEditorContext();
  const { mode } = useCmsContext();
  const { make, quizComponent } = choice;
  const makeId = make?.quizMake?._id;
  const componentId = quizComponent?._id;
  const onDragEnd = async (item, destinationIndex, sourceIndex) => {
    try {
      await updateQuizMakeComponentIndex(makeId, componentId, {
        destinationIndex,
        sourceIndex,
      });
      const { data: componentsData } = await getQuizMakeComponents(makeId);
      setQuizComponents(componentsData);
      return componentsData;
    } catch (error) {
      handleError(error);
    }
  };
  const onItemClick = (item) => {
    handleChoiceChange(mode, item);
  };
  return { onDragEnd, onItemClick };
};

export default useDraggableItems;
