import { api, getData } from 'apis/index';
import { API_VERSION } from 'apis/CONSTANTS';

export const getAllTournaments = () =>
  api.get(`${API_VERSION}/tournament/tournaments`).then(getData);

export const deleteTournamentById = (tournamentId) =>
  api.put(`${API_VERSION}/tournament/delete/tournament/${tournamentId}`).then(getData);

export const editTournamentById = (tournamentId, data) =>
  api
    .put(`${API_VERSION}/tournament/edit/tournament/${tournamentId}`, { data })
    .then(getData);
export const createNewTournament = () =>
  api.post(`${API_VERSION}/tournament/create`).then(getData);

export const updatePendingInivtaions = (data) =>
  api.put(`${API_VERSION}/pendingInvitation/update`, data).then(getData);

export const addClass = (data) =>
  api.put(`${API_VERSION}/tournament/addClass`, data).then(getData);

export const deleteClass = (tournamentId, classId) =>
  api
    .put(
      `${API_VERSION}/tournament/deleteClass/tournament/${tournamentId}/class/${classId}`
    )
    .then(getData);

export const addFacilitator = (tournamentId) =>
  api
    .put(`${API_VERSION}/tournament/addFacilitator/tournament/${tournamentId}`)
    .then(getData);

export const deleteFacilitator = (tournamentId, facilitatorId, data) =>
  api
    .put(
      `${API_VERSION}/tournament/deleteFacilitator/tournament/${tournamentId}/facilitator/${facilitatorId}`,
      data
    )
    .then(getData);
