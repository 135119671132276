import { getFacilitatorMakes, updateFacilitatorMake } from 'apis/CMS/facilitator';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useFacilitatorContext } from '../../../context/useFacilitatorContext';

const useUpdateMake = () => {
  const { choice, setMakes } = useFacilitatorContext();
  const { make } = choice;
  const makeId = make?._id;
  const { currentSelectedData } = useCmsContext();
  const updateMake = async () => {
    const res = await updateFacilitatorMake(makeId, currentSelectedData);
    const { data: fetchMakes } = await getFacilitatorMakes();
    setMakes(fetchMakes);
    return res.data;
  };
  return { updateMake };
};

export default useUpdateMake;
