import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';

export const Title = styled.p`
  font-size: 2rem;
  color: var(--color-charcoal-grey);
  font-family: ${primaryFont.bold};
  margin: 1rem;
`;

export const verticalListStyle = (droppableStyle) => ({
  ...droppableStyle,
});

export const horizontalListStyle = (droppableStyle) => ({
  display: 'flex',
  overflow: 'auto',
  direction: 'ltr',
  textAlign: 'center',
  justifyContent: 'end',
  padding: '1rem',
  width: '85%',
  columnGap: '1rem',
  alignItems: 'center',
  margin: '0 auto',
  ...droppableStyle,
});
