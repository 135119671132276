import { api, getData } from 'apis/index';

import { API_VERSION, GET_MAKE } from './CONSTANTS';

export const getMakeByField = (params = {}) => {
  const request = {
    params: { ...params },
  };
  return api.get(`${API_VERSION}/makes`, request).then(getData);
};

export const getMake = (userId, makeId) => {
  return api.get(`${API_VERSION}/${GET_MAKE(userId, makeId)}`).then(getData);
};

export const getMakesByTopic = (topicId, userId) => {
  return api.get(`${API_VERSION}/makes/topic/${topicId}/${userId}/makes`).then(getData);
};
