import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';

export const MakeStatus = styled.p`
  font-family: ${primaryFont.bold};
  font-size: 1.6rem;
  text-align: center;
  font-weight: bold;
  padding: 0.5rem 1rem 0.4rem;
  height: 3rem;
  margin: 1rem;
  border-radius: 13.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  align-self: flex-end;
`;
