import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_URL, API_VERSION } from '../CONSTANTS';
import {
  GET_FACILITATOR_MAKE,
  GET_ALL_FACILITATOR_COMPONENTS,
  GET_CLASS_RESULTS,
} from './constants';

export const facilitatorApi = createApi({
  reducerPath: 'facilitatorApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}${API_VERSION}`,
    prepareHeaders: (headers) => {
      headers.set(
        'authorization',
        `Basic ${btoa('secret:' + process.env.REACT_APP_BASIC_AUTH_TOKEN)}`
      );
      return headers;
    },
  }),
  keepUnusedDataFor: 60 * 60,

  endpoints: (builder) => ({
    getFacilitatorMake: builder.query({
      query: (makeId) => GET_FACILITATOR_MAKE(makeId),
      transformResponse: (response) => response.data,
    }),
    getAllFacilitatorComponents: builder.query({
      query: ({ makeId }) => GET_ALL_FACILITATOR_COMPONENTS(makeId),
      transformResponse: (response) => response.data,
    }),
    getClassResults: builder.query({
      query: ({ tournamentId, classId }) => GET_CLASS_RESULTS(tournamentId, classId),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetFacilitatorMakeQuery,
  useGetAllFacilitatorComponentsQuery,
  useGetClassResultsQuery,
} = facilitatorApi;
