import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  row-gap: 1rem;
`;
export const Title = styled.h2`
  font-size: 2.5rem;
  font-family: 'OpenSansHebrew ExtraBold', serif;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  position: relative;
  width: 100%;
`;
