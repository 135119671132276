import Selector from 'pages/CMSPage/components/inputTypes/Selector/Selector';
import React from 'react';
import * as S from './Discussion.style';
import useDiscussion from './hooks/useDiscussion';
const Discussion = ({ component }) => {
  const {
    currentComponentId,
    handleMakeChange,
    handleComponentChange,
    currentMakeId,
    makes,
    discussionComponents,
  } = useDiscussion(component);

  return (
    <>
      <S.Container>
        <S.EditContent>
          <Selector
            options={[...makes?.map(({ makeName }) => makeName)]}
            values={[...makes?.map(({ _id }) => _id)]}
            defaultOption={''}
            onChange={(value) => handleMakeChange(value)}
            label={'רשימת המייקים'}
            value={currentMakeId || 'DEFAULT'}
            width={'30rem'}
            margin={'0 0 2rem 0'}
            showLabel={true}
          />
          <Selector
            options={[...discussionComponents.map(({ position }) => position)]}
            values={[...discussionComponents.map(({ _id }) => _id)]}
            defaultOption={''}
            onChange={(value) => handleComponentChange(value)}
            label={'מספר הקומפננטות'}
            value={currentComponentId || 'DEFAULT'}
            width={'30rem'}
            showLabel={true}
          />
        </S.EditContent>
      </S.Container>
    </>
  );
};

export default Discussion;
