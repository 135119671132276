import { sendFileToS3, getSignedUrlS3 } from 'apis/uploads';

export const uploadFile = async (file, excludeUniqueName) => {
  try {
    const uniqueNumber = new Date().getTime();
    const uniqueName = excludeUniqueName ? file?.name : uniqueNumber + file?.name;
    const blob = file.slice(0, file.size, file.type);
    const newFile = new File([blob], uniqueName, {
      type: file.type,
    });
    const { data: signedURL } = await getSignedUrlS3(newFile.name);
    await sendFileToS3(signedURL, newFile);
    const url = `https://makes-data-uploader.s3.us-east-2.amazonaws.com/${newFile.name}`;
    return url;
  } catch (e) {
    console.log(e);
    return 'error';
  }
};
