import { useParams } from 'react-router-dom';
import AvatarSkeleton from '../AvatarSkeleton/AvatarSkeleton';
import * as S from './SubnavSkeleton.style';
const SubnavSkeleton = () => {
  const params = useParams();
  const { trackRoute } = params;

  return (
    <>
      <S.Container>
        {trackRoute && trackRoute !== 'recommendation' && <AvatarSkeleton />}
        <S.TopicList currentTrackRoute={trackRoute}>
          {Array.from({ length: 3 }).map((topic, index) => (
            <S.TopicItem key={index} />
          ))}
        </S.TopicList>
      </S.Container>
    </>
  );
};

export default SubnavSkeleton;
