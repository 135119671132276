export const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  useSearch: false,
  spellcheck: false,
  language: 'he',
  direction: 'rtl',
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  toolbarButtonSize: 'small',
  uploader: {
    insertImageAsBase64URI: true,
  },
  allowResizeY: true,
  height: '50rem',
  style: {
    font: '1.2rem OpenSansHebrew Regular',
  },
  buttonsSM: ['bold', 'italic', 'underline', 'ul', 'ol', 'link', 'image'],
};
