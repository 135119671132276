import React from 'react';
import * as S from './ActionButton.style';
const ActionButton = (props) => {
  const { background, handleOnClick, disabled, value } = props;
  return (
    <>
      <S.Button
        background={background}
        disabled={disabled}
        opacity={disabled ? 0.2 : 1}
        onClick={handleOnClick}
      >
        {value}
      </S.Button>
    </>
  );
};

export default ActionButton;
