import useImageUpload from 'pages/CMSPage/hooks/useImageUpload';
import { useState } from 'react';
import { getNewQuestionObject, resetQuestionFieldsByType } from '../utils/utils';
import useQuestionsObjHandler from './useQuestionsObjHandler';

const useQuestionHandler = () => {
  const { handeUpdatesInQuestionsObj } = useQuestionsObjHandler();
  const { uploadImage } = useImageUpload();
  const [isEditorOpened, setOpenedEditor] = useState(null);

  const handleQuestionTextChange = (event, question) => {
    const newValue = event.target.value;
    question.title.text = newValue;
    handeUpdatesInQuestionsObj(question);
  };

  const handleChangeQuestionType = (newType, question) => {
    const updatedQuestion = resetQuestionFieldsByType(newType, question);
    updatedQuestion.type = newType;
    handeUpdatesInQuestionsObj(updatedQuestion);
  };

  const addNewQuestion = (type) => {
    const isGuru = type === 'guru';
    const newQuestionObject = getNewQuestionObject(isGuru);
    handeUpdatesInQuestionsObj(newQuestionObject);
  };
  const handleEditorButton = (index) => {
    index === isEditorOpened ? setOpenedEditor(null) : setOpenedEditor(index);
  };
  const handleQuestionImageChange = async (event, question) => {
    const url = await uploadImage(event);
    question.title.imageUrl = url;
    handeUpdatesInQuestionsObj(question);
  };

  const handleStoryText = (event, question) => {
    question.story.text = event.target.value;
    handeUpdatesInQuestionsObj(question);
  };
  const handleStoryImageChange = async (event, question) => {
    const url = await uploadImage(event);
    question.story.imageUrl = url;
    handeUpdatesInQuestionsObj(question);
  };

  return {
    handleQuestionTextChange,
    addNewQuestion,
    handleChangeQuestionType,
    handleEditorButton,
    handleQuestionImageChange,
    isEditorOpened,
    handleStoryText,
    handleStoryImageChange,
  };
};

export default useQuestionHandler;
