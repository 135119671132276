import { createTopic } from 'apis/CMS/editor';
import { getTopicById, getTopics } from 'apis/topics';
import { getTracks } from 'apis/tracks';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import useCreateMake from './useCreateMake';

const useCreateTopic = () => {
  const handleError = useErrorHandler();
  const { createNewMake } = useCreateMake();
  const userId = useSelector((state) => state.auth?.user?._id);
  const { topics, setTopics, setTracks, choice } = useEditorContext();
  const { track } = choice;
  const trackId = track?._id;
  const createNewTopic = async (newTrackId, intialData) => {
    try {
      const intialTopicData = intialData || {
        topicName: `טופיק חדש ${topics.length + 1}`,
        topicRoute: `newtopic${topics.length + 1}`,
      };
      const trackIdToUpdate = newTrackId || trackId;
      const { data: newTopic } = await createTopic(trackIdToUpdate, intialTopicData);
      const { _id: newTopicId } = newTopic;
      const { data } = await getTopics(trackIdToUpdate, userId);
      setTopics(data);
      const { data: tracksData } = await getTracks(userId);
      setTracks(tracksData);
      const initialMakeData = {
        makeName: `מייק חדש 1`,
        makeRoute: `newmake1`,
      };
      await createNewMake(newTopicId, initialMakeData);
      const { data: updatedTopic } = await getTopicById(newTopicId);
      return updatedTopic;
    } catch (error) {
      handleError(error);
    }
  };
  return { createNewTopic };
};

export default useCreateTopic;
