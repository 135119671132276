import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { track } from 'mixpanel-browser';
import { getSpecificObjFromArrayOfObjects } from 'utils/helpers';
import {
  convertMetaStruct,
  convertMetaMakes,
  convertMetaComponents,
} from 'utils/helpers/meta';

import {
  META_BASE_URL,
  META_GET_USER_TREE,
  META_APPID,
  META_GET_SCHOOLS,
  META_SIGNUP,
} from '../makesApi/constants';
import { getMetaData } from '../utils';

const contentTransformFn = (response, args) => {
  const { convertTo, topicId, makeId } = args;

  switch (convertTo) {
    case 'topics':
      return convertMetaStruct(response.data);
    case 'makes':
      return convertMetaMakes(response.data);
    case 'components':
      return convertMetaComponents(response.data, topicId, makeId);
    default:
      return response.data;
  }
};
export const metaApi = createApi({
  reducerPath: 'metaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: META_BASE_URL,
  }),

  endpoints: (builder) => ({
    getSchools: builder.query({
      query: () => META_GET_SCHOOLS,
    }),
    signup: builder.mutation({
      query: ({ firstName, lastName, phone, email, gender, school, schoolClass }) => {
        const formObject = {
          appid: META_APPID,
          appsecret: process.env.REACT_APP_META_APPSECRET,
          fname: firstName,
          lname: lastName,
          email,
          phone,
          gender,
          school_id: school.details.school_id,
          class_id: schoolClass.class_id,
        };

        // const getFormData = (object) => {
        //   const formData = new FormData();
        //   Object.keys(object).forEach((key) => formData.append(key, object[key]));
        //   return formData;
        // };
        const getFormData = (object) => {
          const formData = new FormData();
          Object.keys(object).forEach((key) => {
            formData.append(key, object[key]);
          });
          return formData;
        };

        const bodyFormData = getFormData(formObject);

        return {
          url: META_SIGNUP,
          method: 'POST',
          body: bodyFormData,
        };
      },
    }),
    getMetaContent: builder.query({
      query: ({ metaStudentId, makeId, topicId }) => ({
        url: META_GET_USER_TREE,
        method: 'GET',
        params: {
          appid: META_APPID,
          appsecret: process.env.REACT_APP_META_APPSECRET,
          student_id: metaStudentId,
        },
      }),
      transformResponse: (response, meta, args) => contentTransformFn(response, args),
    }),

    setupInitialComponent: builder.query({
      async queryFn({ metaStudentId, user, params }) {
        const { topicRoute, makeRoute } = params;

        const topicId = topicRoute;
        try {
          const { data: topics } = await getMetaData(metaStudentId, 'topics');
          const topic = topics.find((t) => t._id === topicId);
          const { data: makes } = await getMetaData(metaStudentId, 'makes');

          const make = makes[topicId].find((make) => make.makeName === makeRoute);
          const makeId = make._id;
          const components = await getMetaData(
            metaStudentId,
            'components',
            makeId,
            topicId
          );
          return { data: { topic, make, components: components.data } };
        } catch (error) {
          return { error: error };
        }
      },
    }),
    getNextMake: builder.query({
      async queryFn(_, { getState }) {
        const state = getState();
        const studentId = state.auth.user.metaStudentId;
        const currentMake = state.app.currentMake;
        const makeId = state.app.currentMake._id;
        const currentTopic = state.app.currentTopic;
        const topicId = state.app.currentTopic._id;
        const makesSize = currentTopic.makesId.length - 1;
        const currentMakeIndex = currentTopic?.makesId?.indexOf(currentMake._id) - 1;

        try {
          const topics = await getMetaData(studentId, 'topics', makeId, topicId);
          const topicIndex = topics.data.findIndex((t) => t._id === currentTopic._id);
          if (makesSize - 1 > currentMakeIndex) {
            const makes = await getMetaData(studentId, 'makes', makeId, topicId);
            const nextMake = makes.data[[topicId]][currentMakeIndex + 1];
            return { data: { nextMake, nextTopic: currentTopic } };
          }
          if (topics?.data.length - 1 > topicIndex) {
            const nextTopic = topics.data[topicIndex + 1];
            const makes = await getMetaData(studentId, 'makes', makeId, nextTopic._id);
            const nextMake = makes.data[nextTopic._id][0];
            return { data: { nextMake, nextTopic } };
          }
          if (topics.data.length - 1 === topicIndex) {
            const nextTopic = topics.data[0];
            const makes = await getMetaData(studentId, 'makes', makeId, nextTopic._id);
            const nextMake = makes.data[nextTopic._id][0];
            return { data: { nextMake, nextTopic } };
          }
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});
export const {
  useSignupMutation,
  useGetSchoolsQuery,
  useGetMetaContentQuery,
  useGetNextMakeQuery,
  useSetupInitialComponentQuery,
  usePrefetch,
} = metaApi;
