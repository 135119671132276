import styled from 'styled-components';
import { DefaultLabelStyle, SelectorDefaultStyle } from '../../GlobalStyle/Global.style';
import selectArrow from 'assets/icons/selectArrow.svg';
export const Select = styled(SelectorDefaultStyle)`
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  background-color: ${({ background }) => background};
  background-image: ${({ background }) => background && `url(${selectArrow})`};
  color: ${({ color }) => color};
`;

export const Label = styled(DefaultLabelStyle)`
  display: ${({ showLabel }) => (showLabel ? '' : 'none')};
`;
