import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import ContentCreator from './components/ContentCreator/ContentCreator';
import MakeStatus from './components/MakeStatus/MakeStatus';
import * as S from './Teaser.styles';
import Details from './components/Details/Details';
const Teaser = ({
  make,
  handleOnClick,
  teaser,
  thumbnail,
  index,
  contentCreator,
  makeStatus,
  topicName,
  isIOS,
}) => {
  const [playing, setPlaying] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const activeSlide = useSelector((state) => state.ui.activeSlide);
  const isMobile = useSelector((state) => state.ui.isMobile);
  const { trackRoute } = useParams();
  const playerRef = useRef(null);
  const thumbnailRef = useRef(null);

  const isActive = activeSlide === index;
  const preload = isActive ? 'auto' : 'none';

  useEffect(() => {
    if (!isActive && isMobile) {
      setPlaying(false);
    }
  }, [isActive, playing]);

  useEffect(() => {
    if (!isMobile) {
      setPlaying(false);
    }
  }, [trackRoute]);

  useEffect(() => {
    if (!waiting) return;
    const player = playerRef.current?.player?.player?.player;
    player?.pause();

    const timer = setTimeout(() => {
      player?.play();
      setPlaying(true);
    }, 250);
    return () => clearTimeout(timer);
  }, [waiting]);

  const fallbackToIteaser = () => {
    return (
      <S.TeaserWrapper>
        <S.FallBackImage src={teaser} />
        <Details
          make={make}
          handleOnClick={handleOnClick}
          topicName={topicName}
          contentCreator={contentCreator}
          makeStatus={makeStatus}
        />
      </S.TeaserWrapper>
    );
  };

  if (!teaser.includes('.mp4')) {
    return fallbackToIteaser();
  }

  const renderVideoTeaser = () => {
    if (isActive && isMobile) {
      return (
        isActive &&
        isMobile && (
          <ReactPlayer
            ref={playerRef}
            url={teaser}
            playing={true}
            onPlay={() => {
              if (isIOS) {
                return setWaiting(true);
              }
              setPlaying(true);
            }}
            loop={true}
            muted={true}
            controls={false}
            height={thumbnailRef.current?.clientHeight}
            width={thumbnailRef.current?.clientWidth}
            preload={preload}
            playsinline={true}
          />
        )
      );
    }
    if (!isMobile) {
      return (
        <ReactPlayer
          url={teaser}
          playing={true}
          autoPlay={true}
          onPlay={() => {
            setPlaying(true);
          }}
          loop={true}
          muted={true}
          controls={false}
          width="100%"
          height="100%"
          playsinline={true}
        />
      );
    }
  };

  const renderThumbnail = () => {
    return <S.Image playing={playing} src={thumbnail} ref={thumbnailRef} />;
  };

  return (
    <S.TeaserWrapper thumbnail={thumbnail}>
      {renderThumbnail()}
      {renderVideoTeaser()}
      <Details
        topicName={topicName}
        contentCreator={contentCreator}
        makeStatus={makeStatus}
      />
    </S.TeaserWrapper>
  );
};

export default Teaser;
