import { api, getData } from 'apis/index';

import {
  API_VERSION,
  CREATE_NEW_MAKE,
  CREATE_NEW_COMPONENT,
  UPDATE_TOPIC,
  UNPUBLISH_MAKES_BY_TOPIC_ID,
  UNPUBLISH_TOPICS_BY_TRACK_ID,
  CREATE_NEW_FACILITATOR_MAKE,
  UNPUBLISH_TOPICS_BY_MAKE_ID,
  UNPUBLISH_COMPONENT,
  PUBLISH_TRACK,
  PUBLISH_TOPIC,
  PUBLISH_MAKE,
  PUBLISH_COMPONENT,
} from '../CONSTANTS';

export const createNewMake = (trackId, topicId, data) => {
  return api
    .post(`${API_VERSION}/${CREATE_NEW_MAKE(trackId, topicId)}`, data)
    .then(getData);
};

export const createNewComponentToExistMake = (trackId, topicId, makeId, data) => {
  return api
    .post(`${API_VERSION}/${CREATE_NEW_COMPONENT(trackId, topicId, makeId)}`, data)
    .then(getData);
};

export const updateTopic = (trackId, topicId, data) => {
  return api.put(`${API_VERSION}/${UPDATE_TOPIC(trackId, topicId)}`, data).then(getData);
};

export const unPublishMakesByTopicId = (topicId) => {
  return api.put(`${API_VERSION}/${UNPUBLISH_MAKES_BY_TOPIC_ID(topicId)}`).then(getData);
};
export const unPublishTopicsByTrackId = (trackId) => {
  return api.put(`${API_VERSION}/${UNPUBLISH_TOPICS_BY_TRACK_ID(trackId)}`).then(getData);
};

export const unPublishComponentsByMakeId = (makeId) => {
  return api.put(`${API_VERSION}/${UNPUBLISH_TOPICS_BY_MAKE_ID(makeId)}`).then(getData);
};
export const unPublishComponentRequest = (componentId) => {
  return api.put(`${API_VERSION}/${UNPUBLISH_COMPONENT(componentId)}`).then(getData);
};
export const publishTrackRequest = (trackId) =>
  api.put(`${API_VERSION}/${PUBLISH_TRACK(trackId)}`).then(getData);

export const publishTopicRequest = (topicId) =>
  api.put(`${API_VERSION}/${PUBLISH_TOPIC(topicId)}`).then(getData);

export const publishMakeRequest = (makeId) =>
  api.put(`${API_VERSION}/${PUBLISH_MAKE(makeId)}`).then(getData);

export const publishComponentRequest = (componentId) =>
  api.put(`${API_VERSION}/${PUBLISH_COMPONENT(componentId)}`).then(getData);
