import styled from 'styled-components/macro';

export const ItemContainer = styled.li`
  display: flex;
  flex-direction: column;
  text-align: center;
  list-style: none;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin: 0 0.5rem;
`;

export const ItemName = styled.span`
  color: black;
  white-space: nowrap;
  font-size: 1.4rem;
`;
export const ItemIcon = styled.img`
  width: 3.5rem;
  height: 2.5rem;
`;
export const ItemIconWrapper = styled.div`
  border: 2px solid black;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ selected }) =>
    selected ? 'rgb(92, 189, 137)' : 'var(--color-white)'};
`;
