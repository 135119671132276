import React from 'react';
import { circleStyle, squareStyle } from './Item.style';

const Item = ({ provided, callback, item, currentItem, shape, itemKey, index }) => {
  const getItemStyle = (draggableStyle) =>
    shape === 'circle'
      ? circleStyle(draggableStyle, currentItem, item)
      : squareStyle(draggableStyle, currentItem, item);

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getItemStyle(provided.draggableProps.style)}
      onClick={() => callback(item)}
    >
      {itemKey ? item[itemKey] : index + 1}
    </div>
  );
};

export default Item;
