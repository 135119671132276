import React, { useEffect, useState } from 'react';
import { isEmptyObject } from 'utils/helpers';
import { useCmsContext } from '../contexts/cmsContext';

const useValidateQuestionEditor = () => {
  const [isQuestionInvalid, setQuestionValidation] = useState(false);
  const [message, setMessage] = useState('');
  const { currentSelectedData } = useCmsContext();

  useEffect(() => {
    if (
      currentSelectedData?.type === 'choiceQuestion' ||
      currentSelectedData?.type === 'guru' ||
      currentSelectedData?.type === 'quizQuestion'
    )
      if (
        !currentSelectedData.data.questions ||
        isEmptyObject(currentSelectedData.data.questions)
      ) {
        setQuestionValidation(true);
        setMessage('לפחות שאלה אחת!');
      } else setQuestionValidation(false);
    else setQuestionValidation(false);
  }, [currentSelectedData]);

  return { isQuestionInvalid, message };
};

export default useValidateQuestionEditor;
