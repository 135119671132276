import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'components/auth/auth.slice';
import activityReducer from 'components/auth/userActivity.slice';
import { combineReducers } from 'redux';
import { makesApi } from 'services/api/makesApi/makesApi';
import { metaApi } from 'services/api/metaApi/metaApi';
import { facilitatorApi } from 'services/api/facilitatorApi/facilitatorApi';

import appSlice from './app.slice';
import uiSlice from './ui.slice';

const reducers = combineReducers({
  [makesApi.reducerPath]: makesApi.reducer,
  [metaApi.reducerPath]: metaApi.reducer,
  [facilitatorApi.reducerPath]: facilitatorApi.reducer,
  auth: authReducer,
  app: appSlice,
  userActivity: activityReducer,
  ui: uiSlice,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(makesApi.middleware)
      .concat(metaApi.middleware)
      .concat(facilitatorApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});
