// eslint-disable-next-line no-undef
export const BASE_URL = process.env.REACT_APP_API_URL;
export const DISCORD_BASE_URL = 'https://discord.com/api/v9/';
// export const BASE_URL = 'https://mobile.makesapp.com/';
export const API_VERSION = `api/v2`;

// components
export const GET_COMPONENT_BY_MAKE_ID_COMPONENT_ID = (makeId, componentId, userId) =>
  `makes/${makeId}/components/${componentId}/${userId}`;

export const GET_ALL_COMPONENTS_BY_MAKE_ID = (makeId, userId) =>
  `makes/${makeId}/components/${userId}`;

//makes
export const GET_ALL_USER_MAKES = (userId) => `progress/${userId}/getMakes`;
export const GET_MAKE = (userId, makeId) => `makes/getMake/${userId}/${makeId}`;

//tracks
export const GET_TRACKS = (userId) => `tracks/${userId}`;

//progress
export const GET_PROGRESS_OF_USER = (userId) => `progress/${userId}`;
export const GET_PROGRESS_OF_USER_BY_MAKE_ID = (userId) => `progress/user/${userId}`;
export const UPDATE_PROGRESS_OF_USER = (userId, makeId) =>
  `progress/user/${userId}/${makeId}`;
export const GET_FOUR_USER_PROGRESS_BY_TRACK = (trackId) =>
  `progress/user/track/${trackId}`;
//topics
export const UPLOAD_INFO_IMG = (makeId) => `makes/${makeId}/upload`;
export const GET_TOPICS_BY_TRACK_ID = (trackId, userId) =>
  `tracks/${trackId}/${userId}/topics`;
export const GET_TOPIC_BY_ID = (topicId) => `tracks/topics/${topicId}`;

//users
export const USER_UPLOAD = 'discord/upload';
export const GET_DETAILS_USER = (userId) => `users/${userId}/details`;
export const UPDATE_USER = (id, src) => `users/${src}/${id}`;

//meta
// export const META_BASE_URL = 'https://www.mymeta.co.il/';
export const META_BASE_URL = 'https://www.classit.co/';
export const META_APPID = 'makes';

export const META_SIGNUP = 'signup_student';
export const GOALS = '/student/api_goals';
export const COMPLETION_UNITS = '/student/api_done_units';
//CMS
export const CREATE_NEW_MAKE = (trackId, topicId) =>
  `CMS/track/${trackId}/topics/${topicId}`;
export const CREATE_NEW_COMPONENT = (trackId, topicId, makeId) =>
  `CMS/track/${trackId}/topics/${topicId}/makes/${makeId}`;

export const UPDATE_TOPIC = (trackId, topicId) =>
  `CMS/track/${trackId}/topics/${topicId}/update`;

export const UNPUBLISH_MAKES_BY_TOPIC_ID = (topicId) =>
  `CMS/topics/${topicId}/unPublish/makes`;

export const UNPUBLISH_TOPICS_BY_TRACK_ID = (trackId) =>
  `CMS/tracks/${trackId}/unPublish/topics`;

export const UNPUBLISH_TOPICS_BY_MAKE_ID = (makeId) =>
  `CMS/makes/${makeId}/unPublish/components`;

export const UNPUBLISH_COMPONENT = (componentId) =>
  `CMS/components/${componentId}/unPublish`;

export const PUBLISH_TRACK = (trackId) => `CMS/tracks/${trackId}/publish`;
export const PUBLISH_TOPIC = (topicId) => `CMS/topics/${topicId}/publish`;
export const PUBLISH_MAKE = (makeId) => `CMS/makes/${makeId}/publish`;
export const PUBLISH_COMPONENT = (componentId) => `CMS/components/${componentId}/publish`;

export const UPLOAD_MAKE = (userId, makeId) =>
  `progress/user/${userId}/${makeId}/uploadMake`;

//uploads
export const GET_SIGNED_URL = (key) => `upload?key=${key}`;

export const GET_USER_ACTIVITIES = (userId) => `userActivity/${userId}/getActivity`;

//facilitator

export const CREATE_NEW_FACILITATOR_MAKE = () => `facilitator/createNewMake`;
export const GET_ALL_FACILITATOR_MAKES = () => `facilitator/getAllMakes`;
export const GET_ALL_COMPONENTS_BY_FACILITATOR_MAKE_ID = (makeId) =>
  `facilitator/make/${makeId}/components`;
