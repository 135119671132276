import { createQuizMakeComponent, getQuizMakeComponents } from 'apis/CMS/quizMake';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';

const useCreateQuizComponent = () => {
  const { activeAlert } = useCmsContext();
  const { setQuizComponents, choice } = useEditorContext();
  const { make } = choice;
  const makeId = make?.quizMake?._id;
  const createQuizComponent = async (newMakeId) => {
    const makeIdToUpdate = newMakeId || makeId;

    const { data: newComponent } = await createQuizMakeComponent(makeIdToUpdate);
    const { data: componentsData } = await getQuizMakeComponents(makeIdToUpdate);
    setQuizComponents(componentsData);
    activeAlert({
      title: 'הצלחנו',
      text: `<p>הקומפננטה התווספה בהצלחה</p>`,
    });
    return newComponent;
  };
  return { createQuizComponent };
};

export default useCreateQuizComponent;
