import brandingDark from 'assets/icons/MLogoBlack.svg';
import tournament from 'assets/icons/tournament.svg';
import AvatarUser from 'components/auth/components/AvatarUser';
import Hamburger from 'components/Hamburger/Hamburger';
import IconWithText from 'components/IconWithText/IconWithText';
import Navigation from 'components/Navigation/Navigation';
import ComponentPageHeaderItems from 'layouts/ComponentPageHeader/components/ComponentPageHeaderElements/ComponentPageHeaderItems';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  TOURNAMENT_LEADER_BOARD,
  DEFAULTQUERIES,
  RECOMMENDATIONS,
} from 'routes/CONSTANTS';

import * as S from './Header.styles';

const Header = ({ type, children, profile }) => {
  const isMobile = useSelector((state) => state.ui.isMobile);
  const logo = brandingDark;
  const history = useHistory();
  const userActiveTournament = useSelector(
    (state) => state?.auth?.user?.activeTournament
  );

  const handleLogoClicked = () => {
    history.push(RECOMMENDATIONS);
  };

  const handleTournamentClicked = () => {
    history.push(`${TOURNAMENT_LEADER_BOARD}${DEFAULTQUERIES.leaderBoard}`);
  };

  const renderRightContent = () => {
    if (type !== 'component') {
      return (
        <S.IconsContainer>
          <Hamburger>
            <Navigation />
          </Hamburger>
          <AvatarUser />
          {renderTournament()}
        </S.IconsContainer>
      );
    } else return <ComponentPageHeaderItems />;
  };

  const renderTournament = () => {
    if (userActiveTournament) {
      return (
        <>
          <IconWithText
            icon={tournament}
            alt="tournament"
            handleOnClick={handleTournamentClicked}
          >
            {'טורניר'}
          </IconWithText>
        </>
      );
    }
  };
  return (
    <>
      <S.Wrapper id={'header'} noChildren={!children} isMobile={isMobile} type={type}>
        {renderRightContent()}
        <S.Logo alt={'logo'} src={logo} onClick={handleLogoClicked} />
        <S.LeftContent>{children}</S.LeftContent>
      </S.Wrapper>
    </>
  );
};

export default Header;
