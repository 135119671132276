import { PERMISSIONS } from 'config/constants/constants';
import useGetAllFacilitatorMakes from 'pages/CMSPage/hooks/useGetAllFacilitatorMakes';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useGetFacilitatorComponents from './useGetAllFacilitatorComponents';

const useGetInitialData = (choice) => {
  const [makes, setMakes] = useState([]);
  const [components, setComponents] = useState([]);
  const { make } = choice;
  const makeId = make?._id;
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const permission = useSelector((state) => state?.auth?.user?.permission);
  const { getAllFacilitatorComponents } = useGetFacilitatorComponents();
  const { getAllFacilitatorMakes } = useGetAllFacilitatorMakes();
  useEffect(() => {
    if (!isLoggedIn || !permission === PERMISSIONS.ADMIN) return;
    getAllFacilitatorMakes().then((data) => {
      setMakes(data.data);
      setComponents([]);
    });
  }, []);

  useEffect(() => {
    if (makeId)
      [getAllFacilitatorComponents(makeId).then((data) => setComponents(data.data))];
  }, [makeId]);

  return {
    setComponents,
    setMakes,
    makes,
    components,
  };
};
export default useGetInitialData;
