import {
  createFacilitatorMake,
  getFacilitatorMake,
  getFacilitatorMakes,
} from 'apis/CMS/facilitator';
import { useFacilitatorContext } from '../../../context/useFacilitatorContext';
import useCreateComponent from './useCreateComponent';

const useCreateMake = () => {
  const { createNewComponent } = useCreateComponent();
  const { makes, setMakes } = useFacilitatorContext();
  const createNewMake = async () => {
    const intialMakeData = {
      quizName: `מייק חדש ${makes.length + 1}`,
    };
    const { data: makeData } = await createFacilitatorMake(intialMakeData);
    const { _id: newMakeId } = makeData;
    const { data: makesData } = await getFacilitatorMakes();
    setMakes(makesData);
    await createNewComponent(newMakeId);
    const { data: updatedMake } = await getFacilitatorMake(newMakeId);

    return updatedMake;
  };
  return { createNewMake };
};

export default useCreateMake;
