import { useState, useEffect } from 'react';

import learningIcon from 'assets/icons/hamburger/learning.svg';
import logoutIcon from 'assets/icons/hamburger/logout.svg';
import makeLogo from 'assets/icons/makes-logo.svg';
import makesIcon from 'assets/icons/hamburger/makesIcon.svg';
import closeIcon from 'assets/icons/close_component.svg';
import profileIcon from 'assets/icons/hamburger/profile.svg';
import TitleLink from 'components/HamburgerTitleLink/TitleLink';
import SocialLinks from 'components/SocialLinks/SocialLinks';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  LOGIN,
  SIGN_IN_OPEN_FROM,
  MY_MAKES,
  XP,
  LEADER_BOARD,
  ABOUT,
  RECOMMENDATIONS,
  ON_BOARDING,
  FACILITATOR_DASHBOARD,
} from 'routes/CONSTANTS';
import { toggleHamburger } from 'services/redux/ui.slice';
import { userLogoutEvent } from 'services/segmentIO/categories/UserState';

import NavBar from './components/HamburgerNavBar/HamburgerNavBar';
import * as S from './navigation.styles';

const Navigation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isHamburgerOpen = useSelector((state) => state.ui.isHamburgerOpen);
  const isMobile = useSelector((state) => state.ui.isMobile);
  const permission = useSelector((state) => state.auth?.user?.permission);

  const [activeIndex, setActiveIndex] = useState(null);

  const profileLinks = [
    {
      name: 'המייקים שלי',
      route: MY_MAKES,
      profileOptionName: 'myMakes',
    },
    { name: 'ההתקדמות שלי', route: XP, profileOptionName: 'xp' },
    {
      name: 'טבלת שיאים',
      route: LEADER_BOARD,
      profileOptionName: 'leaderboard',
    },
    {
      name: 'הצטרפות לקבוצה',
      route: ON_BOARDING,
      profileOptionName: 'onBoarding',
    },
  ];

  if (permission === 3)
    profileLinks.push({
      name: 'מערכת מנחה',
      route: FACILITATOR_DASHBOARD,
      profileOptionName: 'Facilitator',
    });

  useEffect(() => {
    profileLinks.forEach((element, index) => {
      if (element.profileOptionName === params.profileOption) {
        setActiveIndex(index);
        return;
      }
    });
  }, [params.profileOption]);

  const loginProfileOnClick = (profileComponent, i) => {
    setActiveIndex(i);
    //! uncomment to enable redirect to profile page
    if (isLoggedIn) {
      dispatch(toggleHamburger());
      return history.push(profileComponent);
    } else {
      history.push({
        pathname: LOGIN,
        state: { prevPath: location.pathname, from: SIGN_IN_OPEN_FROM.DISCOVERY },
      });
    }
  };
  const navigateToAboutPage = () => {
    dispatch(toggleHamburger());
    return history.push({
      pathname: ABOUT,
      state: { from: location.pathname },
    });
  };

  const handleUserLogin = () => {
    if (isLoggedIn) {
      userLogoutEvent();
      localStorage.clear();
      window.location.reload();
    } else {
      history.push({
        pathname: LOGIN,
        state: { prevPath: location.pathname, from: SIGN_IN_OPEN_FROM.DISCOVERY },
      });
    }
  };

  const renderProfileOptions = () => {
    return (
      <>
        {profileLinks.map((link, index) => (
          <S.SubTitle
            key={index}
            active={activeIndex === index}
            onClick={() => {
              loginProfileOnClick(link.route, index);
            }}
          >
            {link.name}
          </S.SubTitle>
        ))}
      </>
    );
  };

  const hamburgerHandleClick = () => {
    dispatch(toggleHamburger());
    return history.push(RECOMMENDATIONS);
  };

  return (
    <S.Container isMobile={isMobile}>
      <S.TopSection>
        <S.MakesIcon
          src={makeLogo}
          alt="logo"
          onClick={() => dispatch(toggleHamburger())}
        />
        <S.CloseIcon
          src={closeIcon}
          alt="closeIcon"
          onClick={() => dispatch(toggleHamburger())}
        />
      </S.TopSection>
      <S.IconsWrapper>
        <SocialLinks />
      </S.IconsWrapper>
      <S.NavContainer>
        <TitleLink
          imgAlt="profile"
          icon={profileIcon}
          callback={() => loginProfileOnClick(profileLinks[0].route, 0)}
        >
          פרופיל
        </TitleLink>
        <S.SubTitleWrapper>{renderProfileOptions()}</S.SubTitleWrapper>
      </S.NavContainer>

      <S.NavContainer>
        <TitleLink
          imgAlt="learning"
          icon={learningIcon}
          callback={() => hamburgerHandleClick()}
        >
          עולמות תוכן
        </TitleLink>

        <S.SubTitleWrapper>
          <S.SubTitle>
            <NavBar />
          </S.SubTitle>
        </S.SubTitleWrapper>
      </S.NavContainer>

      {
        <S.BottomContainer>
          <TitleLink
            imgAlt="about"
            icon={makesIcon}
            callback={() => navigateToAboutPage()}
          >
            מי אנחנו ?
          </TitleLink>
          <TitleLink
            imgAlt="logout"
            icon={logoutIcon}
            callback={() => handleUserLogin()}
            isLoggedIn={isLoggedIn}
          >
            {isLoggedIn ? 'התנתקות' : 'התחברות'}
          </TitleLink>
        </S.BottomContainer>
      }
    </S.Container>
  );
};
export default Navigation;
