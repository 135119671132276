import styled from 'styled-components/macro';

/* Customize the label (the container) */
export const Container = styled.div`
  position: relative;
  cursor: pointer;
`;

/* Hide the browser's default radio button */

/* Create a custom radio button */
export const CheckMark = styled.span`
  height: 2rem;
  border-radius: 50%;
  width: 2rem;
  background-color: ${({ checked }) =>
    checked ? 'var(--color-green)' : 'var(--color-white)'};
  border: 2px solid var(--color-light-grey);
  position: relative;
  ${({ customStyle }) => customStyle};
`;
