import { deleteTrackRequest } from 'apis/CMS/editor';
import { getTracks } from 'apis/tracks';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

const useDeleteTrack = () => {
  const handleError = useErrorHandler();
  const userId = useSelector((state) => state.auth?.user?._id);
  const { choice, setTracks, setTopics, setMakes, setComponents } = useEditorContext();
  const { track } = choice;
  const trackId = track?._id;

  const deleteTrack = async () => {
    try {
      await deleteTrackRequest(trackId);
      const { data: trackData } = await getTracks(userId);
      setTracks(trackData);
      setTopics([]);
      setMakes([]);
      setComponents([]);
    } catch (error) {
      handleError(error);
    }
  };
  return { deleteTrack };
};

export default useDeleteTrack;
