import xpIcon from 'assets/icons/xpIcon.svg';
import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import * as S from './Xp.style';

const Xp = () => {
  return (
    <S.Container>
      <img alt="xp" src={xpIcon} />
      <Input type={'text'} value="100" onChange={() => {}} />
    </S.Container>
  );
};

export default Xp;
