import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';
import { device } from 'utils/helpers/deviceScreen';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: 3rem;
  @media ${device.tablet} {
    height: 4rem;
  }
`;
export const Icon = styled.img`
  width: 2rem;
  height: 2rem;
  @media ${device.tablet} {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const IconWrapper = styled.div`
  margin-bottom: 0.15rem;
`;
export const Value = styled.p`
  font-size: 0.8rem;
  border-radius: 0.4rem;
  font-family: ${primaryFont.bold};
  padding: 0.1rem 0.5rem;
  text-align: center;
  background-color: var(--color-charcoal-grey);
  color: var(--color-white);
  @media ${device.tablet} {
    border-radius: 0.6rem;
    font-size: 1rem;
    padding: 0.1rem 0.8rem 0;
  }
`;
