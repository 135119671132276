import styled from 'styled-components/macro';
import { skeletonLoader } from '../Skeleton.style';

export const AvatarBar = styled.div`
  display: flex;
`;
export const Avatar = styled.div`
  width: 3.5rem;
  color: black;
  height: 3.5rem;
  border-radius: 50%;
  background-color: var(--color-light-grey);
  margin-left: -1rem;
  justify-content: center;
  align-items: center;

  animation: ${skeletonLoader} 1s ease-in-out infinite;
`;
