import { deleteTopicRequest } from 'apis/CMS/editor';
import { getTopics } from 'apis/topics';
import { getTracks } from 'apis/tracks';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

const useDeleteTopic = () => {
  const handleError = useErrorHandler();
  const userId = useSelector((state) => state.auth?.user?._id);
  const { choice, setTracks, setTopics, setMakes, setComponents } = useEditorContext();
  const { track, topic } = choice;
  const trackId = track?._id;
  const topicId = topic?._id;

  const deleteTopic = async () => {
    try {
      await deleteTopicRequest(trackId, topicId);
      const { data: trackData } = await getTracks(userId);
      setTracks(trackData);
      const { data: topicData } = await getTopics(trackId, userId);
      setTopics(topicData);
      setMakes([]);
      setComponents([]);
    } catch (error) {
      handleError(error);
    }
  };
  return { deleteTopic };
};

export default useDeleteTopic;
