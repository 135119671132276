import { updatePendingInivtaions } from 'apis/CMS/tournaments';
import { useTournamentContext } from 'pages/CMSPage/contexts/tournamentContext';
import { useErrorHandler } from 'react-error-boundary';
import { customErrorMsg } from 'utils/helpers';

const usePendingInvitations = () => {
  const handleError = useErrorHandler();
  const { setIsLoading } = useTournamentContext();
  const updatePendingInvitation = async (data) => {
    try {
      setIsLoading(true);
      const { data: result } = await updatePendingInivtaions(data);
      setIsLoading(false);
      return result;
    } catch (error) {
      return handleError(customErrorMsg('updatePendingInvitation', error));
    }
  };
  return { updatePendingInvitation };
};

export default usePendingInvitations;
