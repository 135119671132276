import styled from 'styled-components/macro';

export const PlayerWrapper = styled.div`
  width: 100%; // Reset width
  height: 100%; // Reset height

  position: relative;
  .react-player {
    aspect-ratio: 16/9;
    position: relative;
  }

  .react-player > div {
    position: absolute; // Scaling will occur since parent is relative now
  }
`;
