import Spinner from 'components/Spinner';
import VideoPlayer from 'components/VideoPlayer';
import { useEffect, useRef, useState } from 'react';
import Input from '../Input/Input';
import * as S from './Video.style';
const Video = (props) => {
  const { value, callback, label } = props;
  const [url, setUrl] = useState(value);
  useEffect(() => {
    setUrl(value);
  }, [value]);
  return (
    <>
      <S.FileContainer>
        {url && (
          <VideoPlayer
            url={url}
            controls={true}
            width={'50%'}
            height={'300px'}
            fallback={<Spinner />}
          ></VideoPlayer>
        )}
        <Input
          label={label}
          value={url}
          onChange={(event) => callback(event)}
          type={'text'}
        />
      </S.FileContainer>
    </>
  );
};

export default Video;
