import { publishComponentRequest, unPublishComponentRequest } from 'apis/CMS/CMS';
import useShowAlert from 'hooks/useShowAlert';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';

const usePublishComponent = () => {
  const { activeAlert } = useCmsContext();
  const handleError = useErrorHandler();
  const { choice, handleChoiceChange } = useEditorContext();
  const { component, make } = choice;
  const componentId = component?._id;

  const publishComponent = async () => {
    try {
      if (make.publish) {
        const { data: publishedComponent } = await publishComponentRequest(componentId);
        handleChoiceChange('component', publishedComponent);
        activeAlert({ title: 'הצלחנו', text: `<p>הקומפננטה פורסמה בהצלחה</p>` });
      } else {
        activeAlert({
          title: 'לא הצלחנו לפרסם',
          text: `<p>צריך לפרסם את המייק קודם</p>`,
        });
      }
    } catch (error) {
      handleError(error);
    }
    //TODO validaation should be here
  };
  const unPublishComponent = async () => {
    try {
      const { data: component } = await unPublishComponentRequest(componentId);
      handleChoiceChange('component', component);
      activeAlert({ title: 'הצלחנו', text: `<p>הפרסום הוסר לקומפננטה</p>` });
    } catch (error) {
      handleError(error);
    }
  };
  return { publishComponent, unPublishComponent };
};

export default usePublishComponent;
