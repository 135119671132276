import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';
import { skeletonLoader } from '../Skeleton.style';
export const MakeCard = styled.div`
  overflow: hidden;
  color: var(--color-light-charcoal);
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const BackgroundWrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 2;
  padding: 1rem;
  animation: ${skeletonLoader} 1s ease-in-out infinite;
  width: 100%;
  overflow: hidden;
  flex: 0.75;
  aspect-ratio: 1/1;
`;

export const MakeDetails = styled.div`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 0.15;
  row-gap: 1rem;
`;

export const MakeName = styled.h1`
  margin: 0;
  width: 100%;
  height: 1.5rem;

  animation: ${skeletonLoader} 1s ease-in-out infinite;
  @media ${device.laptopL} {
    height: 2rem;
  }
`;
export const MakeSubTitle = styled.div`
  margin-top: 0.5rem;
  width: 100%;
  height: 1.5rem;

  animation: ${skeletonLoader} 1s ease-in-out infinite;
  @media ${device.laptopL} {
    height: 2rem;
  }
`;

export const TitleContainer = styled.div``;
export const IconsWrapper = styled.div`
  width: 50%;
  height: 2rem;
  animation: ${skeletonLoader} 1s ease-in-out infinite;
`;
export const IconContainer = styled.div`
  display: flex;
`;

export const Icon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
`;
export const IconDescription = styled.p`
  margin: 0 0.25rem 0 1rem;
`;
export const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StarImg = styled.img`
  width: 2rem;
  height: 2rem;
  margin-left: 0.15rem;
`;

export const StarContainer = styled.div`
  display: flex;
`;
