import * as S from './ComponentSequenceButton.style';
const ComponentSequenceButton = (props) => {
  const { components, callback, mode } = props;
  return (
    <S.ComponentSequenceButton
      opacity={components.length && mode === 'make' ? '1' : ' 0.2'}
      disabled={!components.length || mode !== 'make'}
      onClick={callback}
    >
      עבור לרצף הקומפוננטות
    </S.ComponentSequenceButton>
  );
};

export default ComponentSequenceButton;
