import * as S from './TitleLink.styles';
const TitleLink = ({ imgAlt, icon, callback, children, isLoggedIn }) => {
  return (
    <S.TitleContainer onClick={callback}>
      <S.TitleWrapper>
        <S.Logo>
          <S.Image alt={imgAlt} src={icon} />
        </S.Logo>
        <S.Title>{children}</S.Title>
      </S.TitleWrapper>
    </S.TitleContainer>
  );
};
export default TitleLink;
