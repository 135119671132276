import { createComponent, getAllComponentsByMake } from 'apis/CMS/editor';
import { getMakesByTopic } from 'apis/makes';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

const useCreateComponent = () => {
  const handleError = useErrorHandler();
  const userId = useSelector((state) => state.auth?.user?._id);
  const { setComponents, setMakes, choice } = useEditorContext();
  const { topic, make } = choice;
  const makeId = make?._id;
  const topicId = topic?._id;
  const createNewComponent = async (newTopicId, newMakeId, initialData) => {
    const topicIdToUpdate = newTopicId || topicId;

    try {
      const intialComponentData = initialData || {
        type: 'text',
        lock: false,
        points: 0,
        data: {},
      };
      const makeIdToUpdate = newMakeId || makeId;

      const { data: componentData } = await createComponent(
        makeIdToUpdate,
        intialComponentData
      );
      const { component: newComponent } = componentData;
      const { data: componentsData } = await getAllComponentsByMake(
        makeIdToUpdate,
        userId
      );
      setComponents(componentsData);
      const { data } = await getMakesByTopic(topicIdToUpdate, userId);
      setMakes(data);
      return newComponent;
    } catch (error) {
      handleError(error);
    }
  };
  return { createNewComponent };
};

export default useCreateComponent;
