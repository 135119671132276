import { socialLinks } from 'utils/socialLinks.js/socialLinks';

import * as S from './socialLinks.styles';

const SocialLinks = () => {
  return (
    <>
      {socialLinks.map((item, index) => (
        <a href={item.link} rel="noopener noreferrer" target="_blank" key={index}>
          <S.LinkIcon src={item.icon} alt={item.name}></S.LinkIcon>
        </a>
      ))}
    </>
  );
};

export default SocialLinks;
