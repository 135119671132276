import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useState } from 'react';
import makeSchema from '../../validation/schemas/makeSchema';

const useMakeEditing = () => {
  const [errors, setErrors] = useState({ makeName: '', makeRoute: '' });

  const { handleData } = useCmsContext();

  const onTextChange = (field, event) => {
    const value = event.target.value.toLowerCase();
    if (errors[field] !== undefined) validateField(field, value);
    validateField(field, value);
    handleData(field, value);
  };

  const validateField = (field, value) => {
    const { error } = makeSchema.extract(field).validate(value);
    if (error) setErrors((prev) => ({ ...prev, [field]: error.message }));
    else setErrors((prev) => ({ ...prev, [field]: '' }));
  };
  return { errors, onTextChange };
};

export default useMakeEditing;
