import React, { forwardRef } from 'react';

import * as S from './Iframe.styles';

const Iframe = forwardRef((props, ref) => {
  const { url, height, width, handleOnLoad } = props;

  return (
    <S.Iframe
      ref={ref}
      height={height}
      width={width}
      src={url}
      loading="lazy"
      onLoad={handleOnLoad}
    />
  );
});

export default Iframe;
