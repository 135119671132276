import { getFacilitatorComponents } from 'apis/CMS/facilitator';
import { useErrorHandler } from 'react-error-boundary';
import { customErrorMsg } from 'utils/helpers';

const useGetFacilitatorComponents = () => {
  const handleError = useErrorHandler();
  const getAllFacilitatorComponents = async (makeId) => {
    try {
      const result = await getFacilitatorComponents(makeId);
      return { data: result?.data };
    } catch (error) {
      return handleError(customErrorMsg('getAllfacilitatorComponents', error));
    }
  };
  return { getAllFacilitatorComponents };
};

export default useGetFacilitatorComponents;
