import { updateRank } from 'components/auth/auth.slice';
import { LEVEL_UP } from 'config/constants/pointsConstants';
import { useDispatch, useSelector } from 'react-redux';
import { setShowLvlUpModal } from 'services/redux/ui.slice';

import usePointsUpdate from './points/usePointsUpdate';

const useUpdateRank = () => {
  const { pointsUpdate } = usePointsUpdate();
  const trackId = useSelector((state) => state?.app?.currentTrack?._id);

  const dispatch = useDispatch();
  const rankUpdate = (userId, data) => {
    dispatch(setShowLvlUpModal(true));
    dispatch(updateRank(data.rankName));
    pointsUpdate({ userId, body: { actionType: LEVEL_UP }, trackId });
  };
  return { rankUpdate };
};

export default useUpdateRank;
