import ActionButton from 'pages/CMSPage/components/ActionButton/ActionButton';
import ComponentSequenceButton from 'pages/CMSPage/components/ComponentSequenceButton/ComponentSequenceButton';
import GoBackButton from 'pages/CMSPage/components/GoBackButton/GoBackButton';
import Selector from 'pages/CMSPage/components/inputTypes/Selector/Selector';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import * as S from './Menu.style';
import MenuLayout from 'pages/CMSPage/layouts/MenuLayout/MenuLayout';
import useHandleMenuActions from './hooks/useMenuHandler';
import { useFacilitatorContext } from '../../context/useFacilitatorContext';
const Menu = () => {
  const { makes, choice, components } = useFacilitatorContext();
  const { mode } = useCmsContext();
  const { make } = choice;
  const {
    deleteItem,
    createItem,
    handlePreviousMode,
    handleSequenceComponentsButton,
    handleSelectorChange,
  } = useHandleMenuActions();

  const renderActionButtonByMode = () => {
    switch (mode) {
      case null:
      case 'make':
        return `מייק`;
      case 'component':
        return `קומפוננטה`;
      default:
        return ``;
    }
  };

  const menuLayoutProps = {
    handlePreviousMode: handlePreviousMode,
    renderItem: renderActionButtonByMode(),
    createItem: createItem,
    deleteItem: deleteItem,
    components: components,
    handleSequenceComponentsButton: handleSequenceComponentsButton,
    mode: mode,
    disableAddBtn: false, //action buttons in menu never disabled in facilitator
    disableDeleteBtn: !mode || (mode === 'component' && components.length === 1),
  };

  const componentSequenceButtonProps = {
    components: components,
    mode: mode,
    callback: handleSequenceComponentsButton,
  };

  const menuSelectorProps = {
    options: [...makes.map(({ makeName }) => makeName)],
    values: [...makes.map(({ _id }) => _id)],
    defaultOption: 'בחר',
    onChange: (newValue) => handleSelectorChange('make', newValue),
    value: make?._id,
  };
  const addButtonProps = {
    value: `הוסף ${renderActionButtonByMode()} +`,
    handleOnClick: createItem,
    disabled: false,
    background: 'green',
  };
  const deleteButtonProps = {
    value: `מחק ${renderActionButtonByMode()} +`,
    handleOnClick: deleteItem,
    disabled: !mode || (mode === 'component' && components.length === 1),
    background: 'red',
  };

  return (
    <>
      <MenuLayout {...menuLayoutProps}>
        <GoBackButton callback={handlePreviousMode} />
        <Selector {...menuSelectorProps} />
        <ComponentSequenceButton {...componentSequenceButtonProps} />
        <S.ActionButtonsContainer>
          <ActionButton {...addButtonProps} />
          <ActionButton {...deleteButtonProps} />
        </S.ActionButtonsContainer>
      </MenuLayout>
    </>
  );
};

export default Menu;
