import styled from 'styled-components';
import selectArrow from 'assets/icons/selectArrow.svg';
import { primaryFont } from 'styles/typography.styles';

export const DefaultLabelStyle = styled.label`
  font-size: 2rem;
  display: block;
  white-space: nowrap;
  color: var(--color-charcoal-grey);
  font-family: ${primaryFont.bold};
`;

export const DefaultInputField = styled.input`
  white-space: break-spaces;
  width: 100%;
  padding: 0.75rem;
  font-size: 1.8rem;
  font-family: ${primaryFont.regular};
  color: var(--color-charcoal-grey);
  border: 0.1rem solid;
  border-color: var(----color-mid-grey);
  border-radius: 0.5rem;
  line-height: 1;
  background-color: white;
`;

export const SelectorDefaultStyle = styled.select`
  padding: 0.7rem 3.5rem;
  background: white;
  color: white;
  font-size: 2rem;
  text-align: center;
  overflow: hidden;
  font-family: ${primaryFont.bold};
  background-repeat: no-repeat;
  background-position-x: 1rem;
  background-position-y: center;
  appearance: none;
  border-radius: 1rem;
  background-image: url(${selectArrow});
  background-color: #47434d;
`;

export const DefaultTextAreaStyle = styled.textarea`
  white-space: break-spaces;
  padding: 1rem;
  font-size: 1.8rem;
  font-family: ${primaryFont.regular};
  color: var(--color-charcoal-grey);
  border: 0.1rem solid;
  border-color: var(----color-mid-grey);
  border-radius: 0.5rem;
  width: 100%;
  margin: 1rem 0;
  background-color: white;
  resize: none;
`;
