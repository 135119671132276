import { DefaultLabelStyle } from 'pages/CMSPage/components/GlobalStyle/Global.style';
import styled from 'styled-components/macro';
import plusIcon2 from 'assets/icons/plusIcon2.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;
export const PlusButton = styled.button`
  appearance: none;
  background: var(--color-grey);

  background-image: url(${plusIcon2});
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  height: 3.8rem;
  border-radius: 0.5rem;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: center;
`;
export const CloseIcon = styled.img`
  cursor: pointer;
  align-self: flex-end;
  padding-bottom: 1.5rem;
`;
export const Label = styled(DefaultLabelStyle)``;
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
  align-self: ${({ alignSelf }) => alignSelf};
`;
