import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import { useTournamentContext } from 'pages/CMSPage/contexts/tournamentContext';
import * as S from './EditingFields.style';
import useEditingFields from './hooks/useEditingFields';
import { formatDate } from 'utils/helpers';
import AccordionItems from './components/AccordionItems/AccordionItems';
import ClassFields from './components/ClassFields/ClassFields';
import FacilitatorFields from './components/FacilitatorFields/FacilitatorFields';
import Accordion from 'components/Accordion/Accordion';
import { validateTournamentName } from '../../validation/validation';

const EditingFields = () => {
  const { currentTournament } = useTournamentContext();
  const { handleTournamentNameChange, handleStartDate, handleEndDate } =
    useEditingFields();

  return (
    <S.Container>
      <S.DatesContainer>
        <Input
          type={'date'}
          onChange={(event) => handleStartDate(event)}
          label={'תאריך ההתחלה'}
          value={formatDate(currentTournament?.startDate)}
        />
        <Input
          type={'date'}
          onChange={(event) => handleEndDate(event)}
          label={'תאריך הסיום'}
          min={formatDate(currentTournament?.startDate) || formatDate(Date.now())}
          value={formatDate(currentTournament?.endDate)}
        />
      </S.DatesContainer>
      <S.SectionContainer>
        <S.Section>
          <S.InputWrapper>
            <Input
              onChange={(event) => handleTournamentNameChange(event)}
              value={currentTournament?.tournamentName}
              type={'text'}
              label={'שם הטורניר'}
              placeholder={'שם הטורניר'}
              required={true}
              handleOnInvalid={validateTournamentName}
            />
          </S.InputWrapper>
          {currentTournament && <ClassFields />}
        </S.Section>
        <S.Section>{currentTournament && <FacilitatorFields />}</S.Section>
        <S.Section>
          {currentTournament && (
            <Accordion>
              {({ openIndexes, handleItemClick }) => (
                <AccordionItems
                  openIndexes={openIndexes}
                  handleItemClick={handleItemClick}
                />
              )}
            </Accordion>
          )}
        </S.Section>
      </S.SectionContainer>
    </S.Container>
  );
};

export default EditingFields;
