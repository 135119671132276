import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import TextList from 'pages/CMSPage/components/inputTypes/TextList/TextList';
import useHandleComponentData from 'pages/CMSPage/hooks/useHandleComponentData';
import ComponentOptions from '../../ComponentOptions/ComponentOptions';
import * as S from './Google.style';
import closeIcon from 'assets/icons/close_component.svg';

const Google = ({ component }) => {
  const { handleComponentDataObj } = useHandleComponentData({ component });
  const { data } = component;
  const { title, secondaryTitle, keyWords = [] } = data;
  const titleProps = {
    type: 'text',
    label: 'כותרת',
    value: title,
    onChange: (event) => handleComponentDataObj('title', event.target.value),
  };
  const subTitleProps = {
    type: 'text',
    label: 'תת כותרת',
    value: secondaryTitle,
    onChange: (event) => handleComponentDataObj('secondaryTitle', event.target.value),
  };
  const keywordsProps = {
    list: keyWords,
    label: 'מילות מפתח',
    handleAddItem: () => {
      const updatedKeywords = [...keyWords, ''];
      handleComponentDataObj('keyWords', updatedKeywords);
    },
  };

  return (
    <S.Container>
      <S.Section>
        <ComponentOptions component={component} />
      </S.Section>
      <S.Section>
        <S.TextInputWrapper>
          <Input {...titleProps} />
        </S.TextInputWrapper>
        <S.TextInputWrapper>
          <Input {...subTitleProps} />
        </S.TextInputWrapper>
        <TextList {...keywordsProps}>
          {keyWords.map((keyword, index) => (
            <S.TextInputWrapper>
              <Input
                type={'text'}
                value={keyword}
                onChange={(event) => {
                  const updatedKeywords = [...keyWords];
                  updatedKeywords[index] = event.target.value;
                  handleComponentDataObj('keyWords', updatedKeywords);
                }}
              />
              <S.CloseIcon
                src={closeIcon}
                onClick={() => {
                  const updatedKeywords = [...keyWords];
                  updatedKeywords.splice(index, 1);
                  handleComponentDataObj('keyWords', updatedKeywords);
                }}
                alt="closeIcon"
              />
            </S.TextInputWrapper>
          ))}
        </TextList>
      </S.Section>
    </S.Container>
  );
};

export default Google;
