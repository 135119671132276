import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';

export const Title = styled.h1`
  font-size: 2rem;
  color: #47434d;
  font-family: 'OpenSansHebrew ExtraBold', serif;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  color: var(--color-charcoal-grey);
  font-family: ${primaryFont.bold};
  width: 12.5%;
`;
