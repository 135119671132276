import {
  getFacilitatorComponents,
  getFacilitatorMakes,
  updateFacilitateComponentIndex,
} from 'apis/CMS/facilitator';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useErrorHandler } from 'react-error-boundary';
import { useFacilitatorContext } from '../../context/useFacilitatorContext';

const useDraggableItems = () => {
  const handleError = useErrorHandler();
  const { setMakes, choice, handleChoiceChange, setComponents } = useFacilitatorContext();
  const { mode } = useCmsContext();
  const { make, component } = choice;
  const makeId = make?._id;
  const componentId = component?._id;
  const onDragEnd = async (item, destinationIndex, sourceIndex) => {
    try {
      switch (mode) {
        case 'component': {
          await updateFacilitateComponentIndex(makeId, componentId, {
            destinationIndex,
            sourceIndex,
          });
          const { data } = await getFacilitatorMakes();
          setMakes(data);
          const { data: componentsData } = await getFacilitatorComponents(makeId);
          setComponents(componentsData);
          return componentsData;
        }
        default:
          break;
      }
    } catch (error) {
      handleError(error);
    }
  };
  const onItemClick = (item) => {
    handleChoiceChange(mode, item);
  };
  return { onDragEnd, onItemClick };
};

export default useDraggableItems;
