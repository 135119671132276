import DraggableItems from 'pages/CMSPage/components/DraggableItems/DraggableItems';
import PreviewMode from 'pages/CMSPage/components/PreviewMode/PreviewMode';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import EditModeLayout from 'pages/CMSPage/layouts/EditModeLayout/EditModeLayout';
import useDraggableItems from './hooks/dragNdrop.js/useDraggableItems';
import * as S from './QuizEditor.style';
import ActionButton from 'pages/CMSPage/components/ActionButton/ActionButton';
import ChoiceQuestion from 'pages/CMSPage/EditorType/MainEditor/components/ComponentEditor/components/ChoiceQuestion/ChoiceQuestion';
import useUpdateQuizComponent from './hooks/CRUD/useUpdateQuizComponent';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import usePublishQuizComponent from './hooks/usePublishQuizComponent';
import useValidateQuestionEditor from 'pages/CMSPage/hooks/useValidateQuestionEditor';

const QuizEditor = () => {
  const { currentSelectedData } = useCmsContext();
  const component = currentSelectedData;
  const { type, publish } = component;
  const { updateQuizComponent } = useUpdateQuizComponent();
  const { onDragEnd, onItemClick } = useDraggableItems();
  const { publishComponent, unPublishComponent } = usePublishQuizComponent();
  const { draggableList, choice } = useEditorContext();
  const { isQuestionInvalid } = useValidateQuestionEditor();

  const { make } = choice;
  const { _id: makeId } = make;
  const url = `${process.env.REACT_APP_BASE_URL}/facilitator/${makeId}`;
  const urlProps = { href: url, target: '_blank' };
  const renderComponentImage = () => {
    const image = require(`assets/icons/cmsChoiceQuestionExample.png`).default;
    return image;
  };
  const renderComponentEditor = () => {
    return <ChoiceQuestion component={component} />;
  };

  const draggableItemsProps = {
    draggableList: draggableList,
    onDragEnd: onDragEnd,
    onItemClick: onItemClick,
    shape: 'circle',
    direction: 'horizontal',
  };

  const updateButtonProps = {
    background: 'green',
    handleOnClick: updateQuizComponent,
    value: 'עדכן',
    disabled: isQuestionInvalid,
  };
  const publishButtonProps = {
    background: 'orange',
    handleOnClick: publish ? unPublishComponent : publishComponent,
    value: publish ? 'הסר פרסום' : 'פרסם',
  };
  return (
    <EditModeLayout>
      {currentSelectedData && draggableList && (
        <>
          <S.DraggableItemsContainer>
            <DraggableItems {...draggableItemsProps} />
          </S.DraggableItemsContainer>
          <S.Container>
            <S.EditContent>{type && renderComponentEditor()}</S.EditContent>
            <S.PreviewContainer>
              <PreviewMode image={renderComponentImage()}>
                <ActionButton {...updateButtonProps} />
                <ActionButton {...publishButtonProps} />
              </PreviewMode>
              <S.Link {...urlProps}>{'קישור למייק'}</S.Link>
            </S.PreviewContainer>
          </S.Container>
        </>
      )}
    </EditModeLayout>
  );
};

export default QuizEditor;
