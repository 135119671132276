import { getTopics } from 'apis/topics';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

const useGetTopicsByTrackId = () => {
  const handleError = useErrorHandler();
  const userId = useSelector((state) => state.auth?.user?._id);
  const getTopicsByTrackId = async (trackId) => {
    try {
      const { data: topics } = await getTopics(trackId, userId);
      return topics;
    } catch (err) {
      handleError(err);
    }
  };
  return { getTopicsByTrackId };
};

export default useGetTopicsByTrackId;
