import { useRef, useEffect } from 'react';

import * as Sentry from '@sentry/react';
import {
  fetchUserDataAnonymous,
  updateUserDb,
  getUser,
  updateXp,
  updateUserProp,
  updateUserActiveTournament,
} from 'components/auth/auth.slice';
import {
  setMiniGamesFinished,
  setOnBoarding,
  setParticipatedDiscussionComponents,
  setVisitedTracks,
} from 'components/auth/userActivity.slice';
import { ObjectId } from 'bson';
import ErrorFallBack from 'components/ErrorFallBack/ErrorFallBack';
import { FIRST_TIME_LOGIN } from 'config/constants/xpConstants';
import useIsMobile from 'hooks/useIsMobile';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import RouteConfig from 'routes/RouteConfig';
import { setMobileOrDesktop } from 'services/redux/ui.slice';

import * as S from './App.styles';
import UseScrollTop from './hooks/useScrollTop';
import useUpdateUserXp from 'hooks/xp/useUpdateUserXp';
import useGetUserActivity from 'hooks/userActivity/useGetUserActivity';
import useGetActiveTournament from 'hooks/queries/useGetActiveTournament';

const App = () => {
  const isMobile = useIsMobile();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const user = useSelector((state) => state.auth.user);
  const userId = useSelector((state) => state.auth?.user?._id);
  const makeElRef = useRef(null);
  const dispatch = useDispatch();

  const { updateUserXp } = useUpdateUserXp();
  const { userActivity } = useGetUserActivity();
  const { tournament: activeTournament } = useGetActiveTournament();
  useEffect(() => {
    if (localStorage.getItem('persist:root')) {
      localStorage.removeItem('persist:root');
    }
  }, []);

  useEffect(() => {
    if (!window.sessionStorage.getItem('firstTimeUser')) {
      window.sessionStorage.setItem('firstTimeUser', 'true');
    } else {
      window.sessionStorage.setItem('firstTimeUser', 'false');
    }

    const userId = window.localStorage.getItem('makes_userId');
    const anonymousId = localStorage.getItem('makes_anonymous_id');
    if (!isLoggedIn && userId) {
      dispatch(getUser(userId));
    } else if (!isLoggedIn && !anonymousId) {
      const newAnonymousId = new ObjectId();
      localStorage.setItem('makes_anonymous_id', newAnonymousId);
      dispatch(fetchUserDataAnonymous(newAnonymousId));
      dispatch(updateUserDb());
    } else if (anonymousId) {
      dispatch(fetchUserDataAnonymous(anonymousId));
      dispatch(updateUserDb());
    }
  }, []);
  window.localStorage.setItem('check', 'true');

  useEffect(() => {
    if (userActivity) {
      dispatch(setVisitedTracks(userActivity.data.visitedTracks));
      dispatch(setOnBoarding(userActivity.data.isOnBoardingSeen));
      dispatch(setMiniGamesFinished(userActivity.data.miniGamesFinished));
      dispatch(
        setParticipatedDiscussionComponents(
          userActivity.data.discussionComponentsParticipated
        )
      );
    }
  }, [userActivity]);

  useEffect(() => {
    if (userId && isLoggedIn && activeTournament) {
      dispatch(updateUserActiveTournament({ activeTournament: activeTournament }));
    }
  }, [activeTournament, user]);
  useEffect(() => {
    if (user?.xp === 0) {
      updateUserFirstLoginXp();
    }
  }, [userId]);

  useEffect(() => {
    dispatch(setMobileOrDesktop(isMobile));
  }, [isMobile]);

  const updateUserFirstLoginXp = async () => {
    const { data } = await updateUserXp({ userId, actionName: FIRST_TIME_LOGIN });
    dispatch(updateXp({ currentXp: data.newPoints, previousXp: data.oldPoints }));
  };

  return user ? (
    <>
      <S.App>
        <S.Container>
          {/* <AddToHome /> */}
          <Router>
            <UseScrollTop />
            <Sentry.ErrorBoundary
              fallback={({ error, componentStack, resetError }) => (
                <ErrorFallBack resetError={resetError} />
              )}
            >
              <S.Makes ref={makeElRef}>
                <RouteConfig makeElRef={makeElRef} />
              </S.Makes>
            </Sentry.ErrorBoundary>
          </Router>
        </S.Container>
      </S.App>
    </>
  ) : null;
};

export default Sentry.withProfiler(App);
