import {
  useGetAllTracksQuery,
  useGetRecommendationTrackQuery,
} from 'services/api/makesApi/makesApi';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { customErrorMsg } from 'utils/helpers';

const useGetTracks = (fromComponentPage) => {
  const handleError = useErrorHandler();
  const userId = useSelector((state) => state?.auth?.user?._id);

  const {
    data: tracks,
    error,
    isFetching: isFetchingTracks,
  } = useGetAllTracksQuery(userId, { skip: !userId });

  const {
    data: recommendationTrack,
    error: recommendationTrackError,
    isFetching: isFetchingRecommendationTrack,
  } = useGetRecommendationTrackQuery({ userId }, { skip: !userId || fromComponentPage });

  if (error) {
    return handleError(customErrorMsg('getAllTracks', error));
  }

  if (recommendationTrackError) {
    return handleError(customErrorMsg('recommendationTrack', recommendationTrackError));
  }
  let tracksArr = null;
  if (tracks && recommendationTrack) tracksArr = [recommendationTrack, ...tracks];
  return {
    tracks: tracksArr,
    isFetching: isFetchingRecommendationTrack || isFetchingTracks,
  };
};
export default useGetTracks;
