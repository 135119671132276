import { useEffect, useState } from 'react';

import log_out from 'assets/icons/log_out.svg';
import placeholder_user from 'assets/icons/placeholder_user.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { LOGIN, SIGN_IN_OPEN_FROM, MY_MAKES } from 'routes/CONSTANTS';
import { userLogoutEvent } from 'services/segmentIO/categories/UserState';

import * as S from '../login.styles';

const AvatarUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useSelector((state) => state.ui.isMobile);
  const location = useLocation();
  const [src, setSrc] = useState(placeholder_user);
  const user = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  useEffect(() => {
    if (isLoggedIn) {
      const { userAvatar, discordId, googleId, anonymousId } = user;

      if (discordId && userAvatar) {
        setSrc(`https://cdn.discordapp.com/avatars/${discordId}/${userAvatar}.png`);
      } else if (googleId && userAvatar) {
        setSrc(userAvatar);
      } else {
        setSrc(log_out);
      }
    }
  }, []);
  const logoutOnClick = () => {
    userLogoutEvent();
    setSrc(placeholder_user);
    localStorage.clear();
    window.location.reload();
  };

  const loginOnClick = () => {
    //! uncomment to enable redirect to profile page
    if (isLoggedIn) {
      return history.push(MY_MAKES);
    } else {
      history.push({
        pathname: LOGIN,
        state: { prevPath: location.pathname, from: SIGN_IN_OPEN_FROM.DISCOVERY },
      });
    }
  };
  return (
    <S.AvatarLoginUser>
      {!isLoggedIn && !isMobile ? (
        <S.Logout
          isMobile={isMobile}
          onClick={logoutOnClick}
          alt="logout"
          src={log_out}
        />
      ) : null}
      <S.Button onClick={loginOnClick}>
        <S.Image isLoggedIn={isLoggedIn} alt={'userAvatar'} src={src} />
      </S.Button>
    </S.AvatarLoginUser>
  );
};

export default AvatarUser;
