import useScrollToSides from 'hooks/useScrollToSides';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as S from './NavItem.style';
const NavItem = ({ path, params, track, scrollToFunc, tracks }) => {
  const { _id: trackId, trackName, trackRoute } = track;
  const currentTrackId = useSelector((state) => state.app.currentTrack?._id);
  const currentTrackIndex = tracks?.findIndex((track) => track._id === currentTrackId);
  const index = tracks?.findIndex((track) => track._id === trackId);
  const { ref } = useScrollToSides(
    currentTrackIndex,
    index,
    tracks?.length,
    scrollToFunc
  );

  const renderItemIcon = () => {
    //TODO name of the icon should equal to trackRoute
    //TODO If Icon doesnr exist should return default trackImage
    try {
      return require(`assets/icons/Tracks/${trackRoute}.svg`).default;
    } catch (err) {
      return '';
    }
  };

  return (
    <NavLink
      ref={ref}
      exact
      to={{
        pathname: path,
      }}
    >
      <S.ItemContainer>
        <S.ItemIconWrapper selected={params.trackRoute === trackRoute}>
          <S.ItemIcon
            src={
              renderItemIcon() ||
              'https://media.istockphoto.com/photos/canadian-rockies-banff-national-park-dramatic-landscape-picture-id1342152935?b=1&k=20&m=1342152935&s=170667a&w=0&h=q9-vhO5MC7zwaxX8_zFUiqMnQJ5udMjEBf0npeCCAGs='
            }
            alt={'ItemIcon'}
          />
        </S.ItemIconWrapper>
        <S.ItemName>{trackName}</S.ItemName>
      </S.ItemContainer>
    </NavLink>
  );
};

export default NavItem;
