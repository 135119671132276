import React, { useState } from 'react';
import QuestionInstance from './components/QuestionInstance/QuestionInstance';
import * as S from './QuestionEditor.style';
import useQuestionHandler from './hooks/useQuestionHandler';
import { useQuestionEditorContext } from '../../context/questionEditor/questionEditorContext';
import useValidateQuestionEditor from 'pages/CMSPage/hooks/useValidateQuestionEditor';
const QuestionEditor = ({ type }) => {
  const { addNewQuestion } = useQuestionHandler();
  const { questions } = useQuestionEditorContext();
  const { isQuestionNotValid, message } = useValidateQuestionEditor();
  const renderQuestions = () => {
    return Object.entries(questions).map((question, index) => {
      const [questionId, questionData] = question;
      return (
        <QuestionInstance
          key={questionId}
          question={questionData}
          index={index}
          questions={questions}
          editorType={type}
        />
      );
    });
  };
  return (
    <S.Container>
      <S.TitleContainer>
        <S.Title>{'שאלות'}</S.Title>
        <S.AddButton onClick={() => addNewQuestion(type)}>+</S.AddButton>
      </S.TitleContainer>
      {questions && renderQuestions()}
      {isQuestionNotValid && <S.AlertMessage>{message}</S.AlertMessage>}
    </S.Container>
  );
};

export default QuestionEditor;
