import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { useGetUserProgressByUserIdQuery } from 'services/api/makesApi/makesApi';
import { customErrorMsg } from 'utils/helpers';

const useGetUserProgressByUserId = () => {
  const handleError = useErrorHandler();
  const userId = useSelector((state) => state.auth?.user?._id);
  const { data: userProgresses, error: getUserProgressesError } =
    useGetUserProgressByUserIdQuery(
      {
        userId,
      },
      { skip: !userId }
    );
  if (getUserProgressesError)
    return handleError(customErrorMsg('getUserProgressesError', getUserProgressesError));

  return { userProgresses };
};

export default useGetUserProgressByUserId;
