import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const Container = styled.section`
  background: var(--color-background);
  display: flex;
  height: 100%;
  overflow: ${({ isHamburgerOpen }) => isHamburgerOpen && 'hidden'};
  width: 100%;
  margin: unset;
  flex-direction: column;
  @media ${device.tablet} {
    width: 80%;
    margin: 0 auto;
  }
`;
export const DiscoveryPageHeader = styled.div`
  position: ${({ isMobile }) => isMobile && 'sticky'};
  top: 0;
  background: var(--color-background);
  z-index: 100;
`;

export const ChildrenWrapper = styled.div`
  flex: 1;
`;
