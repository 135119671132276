import styled from 'styled-components';

export const Container = styled.ul`
  display: flex;
  align-items: center;
  margin-right: ${({ currentTrackRoute }) =>
    currentTrackRoute !== 'recommendation' && '1.5rem'};
  column-gap: 0.5rem;

  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;
