import { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getAppData } from 'services/redux/app.slice';
import MakesCard from '../../MakeCard/MakeCard';
import { useHistory } from 'react-router-dom';
import useGetTracks from 'hooks/queries/useGetTracks';
import { getComponentRoute, getQuizComponentRoute } from 'routes/helper';
import { makeClickedEvent } from 'services/segmentIO/categories/Navigation';
import * as S from './DesktopContent.styles';
const DesktopContent = ({ makesData }) => {
  const [allTopics, setAllTopics] = useState([]);
  const appData = useSelector(getAppData);
  const history = useHistory();
  const { tracks } = useGetTracks();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let el = document.getElementById(location.hash.slice(1));

      const offset = el?.getBoundingClientRect().top + window.scrollY;

      window.scroll({
        top: offset,
        behavior: 'smooth',
      });
    }
  }, [location?.hash]);

  useEffect(() => {
    if (!makesData) return;
    setAllTopics((prev) => [...prev, ...makesData.topicsData]);
  }, [makesData]);

  const handleClick = (e, make, isQuiz = false) => {
    e.stopPropagation();
    const track = tracks.find((track) => {
      return track._id === make.trackId || track._id === make.make?.trackId;
    });
    const topic = allTopics.find((topic) => {
      return topic._id === make.topicId;
    });

    const trackRoute = track?.trackRoute;
    const topicRoute = topic?.topicRoute || make?.topicRoute;
    const makeRoute = make?.makeRoute;
    const isCampaign = make?.isCampaign;
    const makeName = make.makeName;

    !isQuiz && makeClickedEvent(appData, makeName, makeRoute, isCampaign);
    const route = isQuiz
      ? getQuizComponentRoute(trackRoute, topicRoute, makeRoute)
      : getComponentRoute(trackRoute, topicRoute, makeRoute);

    history.push({
      pathname: route,
    });
  };

  const renderCards = useMemo(() => {
    const filteredMakes = makesData && makesData.makes.filter((make) => !make.isCampaign);

    return (
      makesData &&
      filteredMakes.map((make, i) => {
        const id = make.isFirst ? make.swiperTopicId : '';

        return (
          <MakesCard
            make={make}
            handleOnClick={handleClick}
            topics={makesData.topicsData}
            count={i}
            id={id}
            key={i}
          />
        );
      })
    );
  }, [makesData, allTopics]);

  return <S.Container cards={3}>{makesData && renderCards}</S.Container>;
};
export default DesktopContent;
