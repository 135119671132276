import Joi from 'joi';
import objectId from 'joi-objectid';
import { safeCharactersRegex } from 'utils/helpers/regexes';
import { topicNameErrorMessages, topicRouteErrorMessages } from '../errorMessages/topic';

Joi.objectId = objectId(Joi);
//TODO validate object ids in array
const topicSchema = Joi.object({
  topicName: Joi.string()
    .required()
    .messages({ ...topicNameErrorMessages }),
  topicRoute: Joi.string()
    .regex(safeCharactersRegex)
    .required()
    .trim()
    .messages({ ...topicRouteErrorMessages }),
  topicDescription: Joi.string(),
  publish: Joi.boolean(),
  makesId: Joi.array(),
  _id: Joi.objectId(),
  index: Joi.number(),
  __v: Joi.number(),
});

export default topicSchema;
