import { getAllComponentsByMake } from 'apis/CMS/editor';
import { getQuizMakeComponents } from 'apis/CMS/quizMake';
import { getMakesByTopic } from 'apis/makes';
import { getTopics } from 'apis/topics';
import { getTracks } from 'apis/tracks';
import { PERMISSIONS } from 'config/constants/constants';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

const useGetInitialData = (choice) => {
  const handleError = useErrorHandler();
  const { currentSelectedData } = useCmsContext();
  const [tracks, setTracks] = useState([]);
  const [topics, setTopics] = useState([]);
  const [makes, setMakes] = useState([]);
  const [components, setComponents] = useState([]);
  const [quizComponents, setQuizComponents] = useState([]);
  const { topic, track, make } = choice;
  const trackId = track?._id;
  const topicId = topic?._id;
  const makeId = make?._id;

  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const permission = useSelector((state) => state?.auth?.user?.permission);
  const userId = useSelector((state) => state?.auth?.user?._id);

  useEffect(() => {
    if (!isLoggedIn || permission !== PERMISSIONS.ADMIN) return;
    const fetchTracks = async () => {
      try {
        const { data } = await getTracks(userId);
        setTracks(data);
      } catch (error) {
        handleError(error);
      }
    };
    fetchTracks();
  }, []);

  useEffect(() => {
    if (!trackId) return;
    const fetchTopics = async () => {
      try {
        const { data } = await getTopics(trackId, userId);
        setTopics(data);
        setMakes([]);
        setComponents([]);
      } catch (error) {
        handleError(error);
      }
    };
    fetchTopics();
  }, [trackId]);

  useEffect(() => {
    if (!topicId) return;
    const fetchMakes = async () => {
      try {
        const { data } = await getMakesByTopic(topicId, userId);
        setMakes(data);
        setComponents([]);
      } catch (error) {
        handleError(error);
      }
    };
    fetchMakes();
  }, [topicId]);

  useEffect(() => {
    if (!makeId) return;
    const fetchComponents = async () => {
      try {
        const { data } = await getAllComponentsByMake(makeId, userId);
        setComponents(data);
      } catch (error) {
        handleError(error);
      }
    };
    const fetchQuizComponents = async () => {
      try {
        const { data } = await getQuizMakeComponents(make.quizMake._id);
        setQuizComponents(data);
      } catch (error) {
        handleError(error);
      }
    };
    if (make?.quizMake) fetchQuizComponents();
    fetchComponents();
  }, [makeId, make]);
  return {
    setComponents,
    setMakes,
    setTopics,
    setTracks,
    tracks,
    topics,
    makes,
    components,
    quizComponents,
    setQuizComponents,
  };
};
export default useGetInitialData;
