import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';

export const ComponentSequenceButton = styled.button`
  background: white;
  height: 3.8rem;
  opacity: ${({ opacity }) => opacity};
  min-width: 25rem;
  margin: auto 1rem;
  padding: 0.7rem 1rem;
  border-radius: 0.5rem;
  border: 0.1rem solid var(--color-charcoal-grey);
  color: var(--color-charcoal-grey);
  font-family: ${primaryFont.bold};
  font-size: 2rem;
  cursor: pointer;
`;
