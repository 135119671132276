import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import LockFields from './components/LockFields/LockFields';
import * as S from './ComponentOptions.style';
import MotivationLayer from './components/MotivationLayer/MotivationLayer';
const ComponentOptions = ({ component }) => {
  const { subTitle, type } = component;
  const { handleData } = useCmsContext({ component });
  const subTitleProps = {
    label: 'כותרת משנה',
    value: subTitle,
    onChange: (event) => handleData('subTitle', event.target.value),
  };
  return (
    <S.Container>
      <S.TextInputWrapper>
        <Input {...subTitleProps} />
      </S.TextInputWrapper>
      <LockFields type={type} component={component} />
      <MotivationLayer handleData={handleData} />
    </S.Container>
  );
};

export default ComponentOptions;
