import { useErrorHandler } from 'react-error-boundary';
import { useGetXpLevelsQuery } from 'services/api/makesApi/makesApi';
import { customErrorMsg } from 'utils/helpers';

const useGetXpLevels = () => {
  const handleError = useErrorHandler();
  const { data: xpLevels, error } = useGetXpLevelsQuery();

  if (error) return handleError(customErrorMsg('getXpLevelsError', error));
  return { xpLevels };
};

export default useGetXpLevels;
