import AvatarSkeleton from 'components/SkeletonLoader/SkeletonDiscovery/AvatarSkeleton/AvatarSkeleton';
import React from 'react';
import { useParams } from 'react-router-dom';

import { isEmptyObject } from 'utils/helpers';

import AvatarBarView from './AvatarBarView';
import useGetAvatarUsers from './hooks/useGetAvatarUsers';
import useGetAvatarUsersWithUserId from './hooks/userGetAvatarUsersWithUserId';
const AvatarBarContainer = () => {
  const params = useParams();
  const { trackRoute } = params;
  const { userDetailsWithOutId } = useGetAvatarUsers();
  const { userDetailsWithId } = useGetAvatarUsersWithUserId();
  const userDetails = userDetailsWithOutId || userDetailsWithId;

  return userDetails &&
    trackRoute !== 'recommendation' &&
    userDetails?.users.length > 0 ? (
    <AvatarBarView userDetails={userDetails} />
  ) : (
    <></>
  );
};
export default React.memo(AvatarBarContainer, function areEqual(prevProps, nextProps) {
  return prevProps.trackId === nextProps.trackId;
});
