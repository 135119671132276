import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';

export const Container = styled.div``;

export const Icon = styled.img`
  margin: 0.5rem;
  padding: 0.4rem;
  cursor: pointer;
`;

export const Label = styled.label`
  font-size: 2rem;
  color: var(--color-charcoal-grey);
  font-family: ${primaryFont.bold};
  margin: 1rem 0;
  display: block;
`;
