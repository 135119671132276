import {
  deleteQuizMakeComponent,
  getQuizMakeComponents,
  getQuizMakes,
} from 'apis/CMS/quizMake';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';

const useDeleteQuizComponent = () => {
  const handleError = useErrorHandler();
  const { activeAlert } = useCmsContext();
  const { choice, setQuizComponents } = useEditorContext();
  const { make, quizComponent } = choice;
  const makeId = make?.quizMake?._id;
  const componentId = quizComponent?._id;

  const deleteQuizComponent = async () => {
    try {
      await deleteQuizMakeComponent(makeId, componentId);
      const { data: componentsData } = await getQuizMakeComponents(makeId);
      setQuizComponents(componentsData);
      activeAlert({ title: 'הצלחנו', text: `<p>הקומפננטה נמחקה בהצלחה</p>` });
    } catch (error) {
      handleError(error);
    }
  };
  return { deleteQuizComponent };
};

export default useDeleteQuizComponent;
