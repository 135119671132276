import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';
export const AvatarWithoutImg = styled.div`
  width: 3.5rem;
  color: black;
  height: 3.5rem;
  background-color: ${({ extraAvatar }) => extraAvatar && 'var(--color-white)'};
  border-radius: 50%;
  display: flex;
  margin-left: -1rem;
  justify-content: center;
  border: 1px solid var(--color-black);
  align-items: center;
  font-size: 1.25rem;
  font-family: ${primaryFont.bold};
`;
