import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useErrorHandler } from 'react-error-boundary';
import { useFacilitatorContext } from '../../context/useFacilitatorContext';
import useUpdateComponent from '../CRUD/update/useUpdateComponent';
import useUpdateMake from '../CRUD/update/useUpdateMake';

const useUpdateHandler = () => {
  const handleError = useErrorHandler();
  const { mode, setLoading, activeAlert } = useCmsContext();
  const { handleChoiceChange } = useFacilitatorContext();
  const { updateComponent } = useUpdateComponent();
  const { updateMake } = useUpdateMake();
  const updateItem = async () => {
    try {
      setLoading(true);
      switch (mode) {
        case null:
        case 'make': {
          const make = await updateMake();
          handleChoiceChange(mode, make);

          break;
        }
        case 'component': {
          const component = await updateComponent();
          handleChoiceChange(mode, component);
          break;
        }
      }
      activeAlert({ title: 'הצלחנו', text: `<p>התוכן עודכן בהצלחה</p>` });
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };
  return { updateItem };
};

export default useUpdateHandler;
