import React from 'react';
import * as S from './Discussion.style';
import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import Selector from 'pages/CMSPage/components/inputTypes/Selector/Selector';
import TextArea from 'pages/CMSPage/components/inputTypes/TextArea/TextArea.js';
import Image from 'pages/CMSPage/components/inputTypes/Image/Image';
import useHandleComponentData from 'pages/CMSPage/hooks/useHandleComponentData';
import ComponentOptions from 'pages/CMSPage/components/ComponentOptions/ComponentOptions';
import useDiscussonHandler from './hooks/useDiscussonHandler';
import { CONTENT_TYPE } from 'pages/CMSPage/Constants/component.js';
const Discussion = ({ component }) => {
  const { handleComponentDataObj } = useHandleComponentData();
  const { handleVideoType, handleImageType, handleContentType } = useDiscussonHandler();
  const { data } = component;
  const {
    content = {
      type: 'video',
      data: '',
    },
    text = '',
    title = '',
  } = data;

  const renderContentType = () => {
    if (content?.type === 'video')
      return (
        <Input
          type={'text'}
          label="תוכן"
          value={content.data}
          onChange={(event) => handleVideoType(event)}
        />
      );
    return (
      <Image
        label="תוכן"
        value={content.data}
        onChange={(imageFile) => handleImageType(imageFile)}
      />
    );
  };

  const contentTypeSelectorProps = {
    label: 'סוג התוכן',
    value: content?.type,
    options: [...Object.keys(CONTENT_TYPE).map((item) => CONTENT_TYPE[item])],
    values: [...Object.keys(CONTENT_TYPE).map((item) => item)],
    defaultOption: 'בחר סוג תוכן',
    onChange: (newValue) => handleContentType(newValue),
  };
  const titleProps = {
    type: 'text',
    label: 'כותרת',
    value: title,
    onChange: (event) => handleComponentDataObj('title', event.target.value),
  };
  const textProps = {
    label: 'טקסט',
    value: text,
    onChange: (event) => handleComponentDataObj('text', event.target.value),
  };

  return (
    <S.Container>
      <ComponentOptions component={component} />
      <Selector {...contentTypeSelectorProps} />
      {renderContentType()}
      <Input {...titleProps} />
      <TextArea {...textProps} />
    </S.Container>
  );
};

export default Discussion;
