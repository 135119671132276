import React from 'react';
import * as S from './GoBackButton.style';
import rightArrow from 'assets/icons/rightArrow.svg';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';

const GoBackButton = (props) => {
  const { callback } = props;
  const { showPreviousBtn } = useCmsContext();
  return (
    <S.GoBackButtonContainer>
      <S.GoBackButton
        opacity={!showPreviousBtn ? 0.2 : 1}
        onClick={() => callback()}
        disabled={!showPreviousBtn}
      >
        <img src={rightArrow} />
      </S.GoBackButton>
    </S.GoBackButtonContainer>
  );
};

export default GoBackButton;
