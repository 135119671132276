import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import useDeleteTrack from '../CRUD/delete/useDeleteTrack';
import useDeleteTopic from '../CRUD/delete/useDeleteTopic';
import useDeleteMake from '../CRUD/delete/useDeleteMake';
import useDeleteComponent from '../CRUD/delete/useDeleteComponent';
import { useErrorHandler } from 'react-error-boundary';
import useDeleteQuizComponent from '../../components/QuizEditor/hooks/CRUD/useDeleteQuizComponent';

const useDeleteHandler = () => {
  const handleError = useErrorHandler();
  const { deleteTrack } = useDeleteTrack();
  const { deleteTopic } = useDeleteTopic();
  const { deleteMake } = useDeleteMake();
  const { deleteQuizComponent } = useDeleteQuizComponent();
  const { deleteComponent } = useDeleteComponent();
  const { mode, setMode, setLoading, activeAlert } = useCmsContext();
  const { handleChoiceChange } = useEditorContext();
  const deleteItem = async () => {
    try {
      setLoading(true);
      switch (mode) {
        case 'track': {
          await deleteTrack();
          setMode(null);
          break;
        }
        case 'topic': {
          await deleteTopic();
          setMode('track');
          break;
        }
        case 'make': {
          await deleteMake();
          setMode('topic');
          break;
        }
        case 'component': {
          await deleteComponent();
          setMode('make');
          break;
        }
        case 'quizComponent': {
          await deleteQuizComponent();
          setMode('make');
          break;
        }
      }
      handleChoiceChange(mode, null);
      activeAlert({ title: 'הצלחנו', text: `<p>נמחק בהצלחה ${mode}ה</p>` });
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };
  return { deleteItem };
};
export default useDeleteHandler;
