import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 85%;
  margin: 0 auto;
  padding-top: 5rem;
`;
export const EditContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;
export const DraggableItemsContainer = styled.div``;
export const PreviewContainer = styled.div`
  margin: 1rem 0;
  overflow: hidden;
`;
