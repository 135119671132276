import useHandleComponentData from 'pages/CMSPage/hooks/useHandleComponentData';
import { useQuestionEditorContext } from '../../../context/questionEditor/questionEditorContext';

const useQuestionsObjHandler = () => {
  const { questions } = useQuestionEditorContext();
  const { handleComponentDataObj } = useHandleComponentData();

  const deleteQuestion = (question) => {
    const updatedQuestionsObj = { ...questions };
    delete updatedQuestionsObj[question._id];
    handleComponentDataObj('questions', { ...updatedQuestionsObj });
  };

  const handeUpdatesInQuestionsObj = (updatedQuestion) => {
    const { _id } = updatedQuestion;
    handleComponentDataObj('questions', { ...questions, [_id]: updatedQuestion });
  };

  return {
    handeUpdatesInQuestionsObj,
    deleteQuestion,
  };
};

export default useQuestionsObjHandler;
