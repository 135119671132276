import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import * as S from './Youtube.style';
import ComponentOptions from '../../ComponentOptions/ComponentOptions';
import Video from 'pages/CMSPage/components/inputTypes/Video/Video';
import TextArea from 'pages/CMSPage/components/inputTypes/TextArea/TextArea';
import useHandleComponentData from 'pages/CMSPage/hooks/useHandleComponentData';
const Youtube = ({ component }) => {
  const { handleComponentDataObj } = useHandleComponentData();
  const { data } = component;
  const { title, url, text } = data;
  const titleProps = {
    type: 'text',
    label: 'כותרת של הוידאו',
    onChange: (event) => handleComponentDataObj('title', event.target.value),
    value: title,
  };
  const videoProps = {
    label: 'לינק של הודיאו',
    value: url,
    callback: (event) => handleComponentDataObj('url', event.target.value),
  };
  const extraContentProps = {
    label: 'תוכן נוסף',
    onChange: (event) => handleComponentDataObj('text', event.target.value),
    value: text,
  };
  return (
    <S.Container>
      <S.Section>
        <ComponentOptions component={component} />
      </S.Section>
      <S.Section>
        <Input {...titleProps} />
        <Video {...videoProps} />
        <TextArea {...extraContentProps} />
      </S.Section>
    </S.Container>
  );
};

export default Youtube;
