import ActionButton from 'pages/CMSPage/components/ActionButton/ActionButton';
import Circle from 'pages/CMSPage/components/Circle/Circle';
import Selector from 'pages/CMSPage/components/inputTypes/Selector/Selector';
import MenuLayout from 'pages/CMSPage/layouts/MenuLayout/MenuLayout';
import * as S from './Menu.style';
import leaderboard from 'assets/icons/leaderboard.svg';
import { useTournamentContext } from 'pages/CMSPage/contexts/tournamentContext';
import { getAllTournaments } from 'apis/CMS/tournaments';
import useCreateTournament from '../../hooks/useCreateTournament';

const Menu = () => {
  const {
    tournaments,
    setCurrentTournament,
    currentTournament,
    setIsEditing,
    setTournaments,
  } = useTournamentContext();

  const { createTournament } = useCreateTournament();
  const handleCreateTournament = async () => {
    const { data: newTournament } = await createTournament();
    const { data: allTournaments } = await getAllTournaments();
    setTournaments(allTournaments);
    setCurrentTournament(newTournament);
    setIsEditing(true);
  };

  const handleTournamentChange = (tournamentId) => {
    const tournament = tournaments.find((tournament) => tournament._id === tournamentId);
    setCurrentTournament(tournament);
    setIsEditing(true);
  };

  const selectorProps = {
    options: [...tournaments?.map(({ tournamentName }) => tournamentName)],
    values: [...tournaments?.map(({ _id }) => _id)],
    defaultOption: 'אנא בחר',
    showLabel: false,
    onChange: (tournamentId) => handleTournamentChange(tournamentId),
    value: currentTournament?._id,
    label: 'בחר טורניר',
  };

  return (
    <MenuLayout>
      <S.RightContent>
        <Circle
          icon={leaderboard}
          handleOnClick={() => {
            setCurrentTournament(null);
            setIsEditing(false);
          }}
        />
        <Selector {...selectorProps} />
      </S.RightContent>
      <ActionButton
        background={'green'}
        value={'הוסף טורניר חדש +'}
        handleOnClick={handleCreateTournament}
      />
    </MenuLayout>
  );
};

export default Menu;
