import AvatarUser from 'components/auth/components/AvatarUser';
import Header from 'layouts/Header';
import React from 'react';
import NavigationButtons from '../NavigationButtons/NavigationButtons';
import * as S from './CMSHeader.style';
const CMSHeader = () => {
  return (
    <S.HeaderContainer>
      <Header type={'cms'}>
        <NavigationButtons />
      </Header>
    </S.HeaderContainer>
  );
};

export default CMSHeader;
