import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { createContext, useContext, useEffect, useState } from 'react';
import useGetIntialData from '../hooks/useGetInitialData';

const EditorContext = createContext();

const useEditorContext = () => {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error('useEditorContext must be used within a CMSProvider');
  }
  return context;
};

const EditorProvider = (props) => {
  const [draggableList, setDraggableList] = useState(null);
  const [choice, setChoice] = useState({
    track: null,
    topic: null,
    make: null,
    component: null,
    quizComponent: null,
  });
  const { track, topic, make, component, quizComponent } = choice;
  const { setCurrentSelectedData, mode, setShowPreviousBtn } = useCmsContext();

  const {
    setComponents,
    setMakes,
    setTopics,
    setTracks,
    tracks,
    topics,
    makes,
    components,
    quizComponents,
    setQuizComponents,
  } = useGetIntialData(choice);
  useEffect(() => {
    switch (mode) {
      case 'track': {
        setCurrentSelectedData(track);
        setDraggableList({
          title: 'רשימת הטראקים',
          list: tracks,
          currentItem: track,
          key: 'trackName',
        });
        break;
      }
      case 'topic': {
        setCurrentSelectedData(topic);
        setDraggableList({
          title: 'רשימת הטופיקים',
          list: topics,
          currentItem: topic,
          key: 'topicName',
        });
        break;
      }
      case 'make': {
        setCurrentSelectedData(make);
        setDraggableList({
          title: 'רשימת המייקס',
          list: makes,
          currentItem: make,
          key: 'makeName',
        });
        break;
      }
      case 'component': {
        setCurrentSelectedData(component);
        setDraggableList({
          list: components,
          currentItem: component,
        });
        break;
      }
      case 'quizComponent': {
        setCurrentSelectedData(quizComponent);
        setDraggableList({
          list: quizComponents,
          currentItem: quizComponent,
        });
        break;
      }
      default: {
        setCurrentSelectedData({});
        return setShowPreviousBtn(false);
      }
    }
    return setShowPreviousBtn(true);
  }, [mode, choice]);

  const handleChoiceChange = (key, value) => {
    switch (key) {
      case 'track': {
        setChoice((prev) => ({
          ...prev,
          [key]: value,
          topic: null,
          make: null,
          component: null,
          quizComponent: null,
        }));
        break;
      }
      case 'topic': {
        setChoice((prev) => ({
          ...prev,
          [key]: value,
          make: null,
          component: null,
          quizComponent: null,
        }));
        break;
      }
      case 'make': {
        setChoice((prev) => ({
          ...prev,
          [key]: value,
          component: null,
          quizComponent: null,
        }));
        break;
      }
      case 'component': {
        setChoice((prev) => ({ ...prev, [key]: value, quizComponent: null }));
        break;
      }
      case 'quizComponent': {
        setChoice((prev) => ({ ...prev, [key]: value, component: null }));
        break;
      }
      default: {
        setChoice({
          track: null,
          topic: null,
          make: null,
          component: null,
        });
        break;
      }
    }
  };

  const value = {
    setComponents,
    setMakes,
    setTopics,
    setTracks,
    handleChoiceChange,
    draggableList,
    choice,
    tracks,
    topics,
    makes,
    components,
    setQuizComponents,
    quizComponents,
  };
  return <EditorContext.Provider value={value} {...props} />;
};
export { EditorProvider, useEditorContext };
