import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';

export const Container = styled.div`
  position: relative;
  padding: 8rem;
  width: 100%;
  text-align: center;
  color: white;
  background: black;
  font-family: ${primaryFont.bold};
  overflow-y: auto;
  scroll-behavior: smooth;
`;

export const ImageContainer = styled.div`
  margin-top: 5rem;
`;

export const Title = styled.h1`
  font-family: ${primaryFont.bold};
  font-size: 3rem;
`;

export const Paragraph = styled.h3`
  font-family: ${primaryFont.regular};
  font-weight: 500;
  font-size: 2.4rem;
  margin-bottom: 2rem;
`;

export const SubParagraph = styled.p`
  font-family: ${primaryFont.regular};
  font-size: 1.4rem;
  font-weight: 100;
  margin-bottom: 1rem;
`;

export const ButtonContainer = styled.button`
  font-family: ${primaryFont.bold};
  font-size: 2rem;
  height: 5rem;
  width: 36rem;
  border-radius: 15px;
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background: rgb(88, 211, 159);
  color: white;
  font-weight: bold;
`;
