import { ObjectID } from 'bson';

export const getNewQuestionObject = (isGuru) => {
  const _id = new ObjectID().toString();
  const newObj = {
    _id,
    type: 'text',
    title: { text: '', imageUrl: '' },
    answers: [
      { content: '', feedback: { text: '', image: '' }, _id: new ObjectID().toString() },
      { content: '', feedback: { text: '', image: '' }, _id: new ObjectID().toString() },
      { content: '', feedback: { text: '', image: '' }, _id: new ObjectID().toString() },
      { content: '', feedback: { text: '', image: '' }, _id: new ObjectID().toString() },
    ],
    correctIndexAnswer: 1,
    correctFeedback: '',
    wrongFeedback: [
      { title: '', subTitle: '' },
      { title: '', subTitle: '' },
      { title: '', subTitle: '' },
    ],
  };
  if (isGuru) {
    newObj.story = { text: '', imageUrl: '' };
    newObj.level = 'easy';
  }
  return newObj;
};
// text = 4 text answers
// image = 4 image answers
// twoOptions = 2 text answers

export const resetQuestionFieldsByType = (newType, question) => {
  const updatedQuestion = { ...question };
  switch (newType) {
    case 'twoOptions': {
      updatedQuestion['answers'] = [
        { content: '', feedback: { text: '', image: '' } },
        { content: '', feedback: { text: '', image: '' } },
      ];
      updatedQuestion['wrongFeedback'] = [
        { title: '', subTitle: '' },
        { title: '', subTitle: '' },
      ];
      break;
    }

    case 'image':
    case 'text': {
      updatedQuestion['answers'] = [
        { content: '', feedback: { text: '', image: '' } },
        { content: '', feedback: { text: '', image: '' } },
        { content: '', feedback: { text: '', image: '' } },
        { content: '', feedback: { text: '', image: '' } },
      ];
      updatedQuestion['wrongFeedback'] = [
        { title: '', subTitle: '' },
        { title: '', subTitle: '' },
        { title: '', subTitle: '' },
        { title: '', subTitle: '' },
      ];
      break;
    }
  }
  return updatedQuestion;
};
