import { updateTrackRequest } from 'apis/CMS/editor';
import { getTracks } from 'apis/tracks';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

const useUpdateTrack = () => {
  const handleError = useErrorHandler();
  const userId = useSelector((state) => state.auth.user?._id);
  const { currentSelectedData, activeAlert } = useCmsContext();
  const { choice, setTracks } = useEditorContext();
  const { track } = choice;
  const trackId = track?._id;
  const updateTrack = async () => {
    try {
      const res = await updateTrackRequest(trackId, currentSelectedData);
      const { data: fetchTracks } = await getTracks(userId);
      setTracks(fetchTracks);
      activeAlert({ title: 'הצלחנו', text: `<p>הטראק עודכן בהצלחה</p>` });
      return res.data;
    } catch (error) {
      handleError(error);
    }
  };
  return { updateTrack };
};

export default useUpdateTrack;
