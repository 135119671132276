import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useErrorHandler } from 'react-error-boundary';
import { useFacilitatorContext } from '../../context/useFacilitatorContext';
import useCreateComponent from '../CRUD/create/useCreateComponent';
import useCreateMake from '../CRUD/create/useCreateMake';

const useCreateHandler = () => {
  const handleError = useErrorHandler();
  const { mode, setMode, activeAlert, setLoading } = useCmsContext();
  const { handleChoiceChange, choice } = useFacilitatorContext();
  const { make } = choice;
  const { createNewMake } = useCreateMake();
  const { createNewComponent } = useCreateComponent();
  const createItem = async () => {
    try {
      setLoading(true);
      let data;
      switch (mode) {
        case null:
        case 'make': {
          data = await createNewMake();
          handleChoiceChange('make', data);
          setMode('make');
          break;
        }
        case 'component': {
          data = await createNewComponent();
          handleChoiceChange('component', data);
          setMode('component');
          break;
        }
      }
      activeAlert({
        title: 'הצלחנו',
        text: `<p>נוסף בהצלחה ${mode === null ? 'המייק' : mode}ה</p>`,
      });
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };
  return { createItem };
};
export default useCreateHandler;
