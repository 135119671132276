import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { useGetTournamentByIdQuery } from 'services/api/makesApi/makesApi';
import { customErrorMsg } from 'utils/helpers';

const useGetTournamentById = (tournamentId) => {
  const handleError = useErrorHandler();
  const {
    data: tournament,
    error,
    isLoading,
  } = useGetTournamentByIdQuery(tournamentId, {
    skip: !tournamentId,
  });

  if (error) return handleError(customErrorMsg('getTournamentById', error));

  return { tournament, isLoading };
};

export default useGetTournamentById;
