import { RECOMMENDATION_TRACK_ID } from 'config/constants/constants';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { useGetAvatarUsersWithUserIdQuery } from 'services/api/makesApi/makesApi';
import { customErrorMsg } from 'utils/helpers';

const useGetAvatarUsersWithUserId = () => {
  const handleError = useErrorHandler();
  const user = useSelector((state) => state.auth.user);
  const trackId = useSelector((state) => state.app?.currentTrack?._id);

  const { data: userDetailsWithId, error } = useGetAvatarUsersWithUserIdQuery(
    { trackId, userId: user?._id },
    {
      skip: !user?._id || trackId === RECOMMENDATION_TRACK_ID,
    }
  );

  if (error) {
    return handleError(customErrorMsg('getAvatarUsers', error));
  }
  return { userDetailsWithId };
};

export default useGetAvatarUsersWithUserId;
