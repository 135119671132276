import { getFacilitatorMakes } from 'apis/CMS/facilitator';
import { useErrorHandler } from 'react-error-boundary';
import { customErrorMsg } from 'utils/helpers';

const useGetAllFacilitatorMakes = () => {
  const handleError = useErrorHandler();
  const getAllFacilitatorMakes = async () => {
    try {
      const result = await getFacilitatorMakes();
      return { data: result?.data };
    } catch (error) {
      return handleError(customErrorMsg('getTournaments', error));
    }
  };
  return { getAllFacilitatorMakes };
};

export default useGetAllFacilitatorMakes;
