import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';

export const Container = styled.div`
  background-color: #d0d0d0;
  min-height: 10rem;
`;

export const Content = styled.div`
  width: 85%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
