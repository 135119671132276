import * as S from './MenuLayout.style';

const MenuLayout = ({ children }) => {
  return (
    <S.Container>
      <S.Content>{children}</S.Content>
    </S.Container>
  );
};
export default MenuLayout;
