import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import isValidUrl from 'utils/helpers/isValidUrl';

import * as S from './richTextParser.styles';

const RichTextParser = ({ data }) => {
  const isMobile = useSelector((state) => state.ui.isMobile);
  return <S.Wrapper isMobile={isMobile}>{ReactHtmlParser(data)}</S.Wrapper>;
};
export default RichTextParser;
