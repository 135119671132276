import Joi from 'joi';
import objectId from 'joi-objectid';
import { safeCharactersRegex } from 'utils/helpers/regexes';
import { makeNameErrorMessages, makeRouteErrorMessages } from '../errorMessages/make';

Joi.objectId = objectId(Joi);

const makeSchema = Joi.object({
  makeName: Joi.string()
    .required()
    .messages({ ...makeNameErrorMessages }),
  makeRoute: Joi.string()
    .lowercase()
    .regex(safeCharactersRegex)
    .required()
    .trim()
    .messages({ ...makeRouteErrorMessages }),
  makeDescription: Joi.string(),
  contentCreator: Joi.string(),
  publish: Joi.boolean(),
  componentsId: Joi.array(),
  _id: Joi.objectId(),
  index: Joi.number(),
  __v: Joi.number(),
});

export default makeSchema;
