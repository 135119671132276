import { useErrorHandler } from 'react-error-boundary';
import { useUpdateUserXpMutation } from 'services/api/makesApi/makesApi';
import { customErrorMsg } from 'utils/helpers';

const useUpdateUserXp = () => {
  const handleError = useErrorHandler();
  const [updateUserXp, { error: updateXpError }] = useUpdateUserXpMutation();

  if (updateXpError) return handleError(customErrorMsg('updateXpErro', updateXpError));

  return { updateUserXp };
};

export default useUpdateUserXp;
