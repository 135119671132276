import React from 'react';

import * as Sentry from '@sentry/react';
import oops from 'assets/icons/oops.svg';
import { useHistory } from 'react-router-dom';

import * as S from './errorFallBack.styles';
import { RECOMMENDATIONS } from 'routes/CONSTANTS';

const ErrorFallBack = ({ resetError }) => {
  Sentry.captureMessage('OPS MSG');
  const history = useHistory();

  const handleOnClick = () => {
    resetError();
    history.push(RECOMMENDATIONS);
    window.location.reload();
  };
  return (
    <S.Container>
      <S.ImageContainer>
        <img src={oops} alt="oops" />
      </S.ImageContainer>
      <S.Title>אהה.. אופס..</S.Title>
      <S.Paragraph>נראה לנו שמשהו השתבש</S.Paragraph>

      <S.SubParagraph>
        זה לא אתם, זה אנחנו. אנחנו עובדים על לסדר את התקלה כמה שיותר מהר
      </S.SubParagraph>
      <S.SubParagraph>
        אם התקלה נמשכת, בואו לכעוס עלינו בשרת{' '}
        <a
          href="https://discord.gg/nPrNrXuNBv"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'var(--color-green)' }}
        >
          הדיסקורד{' '}
        </a>
        שלנו
      </S.SubParagraph>
      <S.ButtonContainer onClick={handleOnClick}>לעמוד הראשי</S.ButtonContainer>
    </S.Container>
  );
};

export default ErrorFallBack;
