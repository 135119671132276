import { updateMakeRequest } from 'apis/CMS/editor';
import { getMakesByTopic } from 'apis/makes';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

const useUpdateMake = () => {
  const handleError = useErrorHandler();
  const userId = useSelector((state) => state.auth.user?._id);
  const { choice, setMakes } = useEditorContext();
  const { track, topic, make } = choice;
  const trackId = track?._id;
  const topicId = topic?._id;
  const makeId = make?._id;
  const { currentSelectedData, activeAlert } = useCmsContext();
  const updateMake = async () => {
    try {
      const res = await updateMakeRequest(makeId, currentSelectedData);
      const { data: fetchMakes } = await getMakesByTopic(topicId, userId);
      setMakes(fetchMakes);
      activeAlert({ title: 'הצלחנו', text: `<p>המייק עודכן בהצלחה</p>` });
      return res.data;
    } catch (error) {
      handleError(error);
    }
  };
  return { updateMake };
};

export default useUpdateMake;
