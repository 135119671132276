import Image from 'pages/CMSPage/components/inputTypes/Image/Image';
import TextArea from 'pages/CMSPage/components/inputTypes/TextArea/TextArea';
import React from 'react';
import useQuestionHandler from '../../../../../../hooks/useQuestionHandler';

const Question = ({ question }) => {
  const { title } = question;
  const { handleQuestionTextChange, handleQuestionImageChange } = useQuestionHandler();
  return (
    <>
      <>
        <TextArea
          value={title?.text}
          onChange={(event) => handleQuestionTextChange(event, question)}
        />
        <Image
          value={title?.imgUrl}
          onChange={(imageFile) => handleQuestionImageChange(imageFile, question)}
        />
      </>
    </>
  );
};

export default Question;
