import { SIGN_IN_OPEN_FROM } from 'routes/CONSTANTS';
import {
  getSpecificObjFromArrayOfObjects,
  getComponentTitle,
  getMakeType,
} from 'utils/helpers';

import {
  SIGN_IN_OPEN,
  NO_DIC_CLK,
  Discussion_Comment,
  SKIP_CLK,
  NEXT_COMPONENT,
  SUBMISSION_LOAD,
  COMP_CLOSE,
  UP_NEXT_MAKE,
  SITE_VISIT,
  MAKE_CLK,
  TRACK_CLK,
  META_MODAL_OPENED,
  META_WAITING_LIST,
  TERMS_AND_CONDITIONS_MP,
  PRIVACY_POLICY_MP,
  DISK_HELP_CLK,
  ONBOARDING_CLK,
  ONBOARDING_NEXT_COMP,
  ONBOARDING_INTER_COMP,
  ONBOARDING_GENDER,
  ONBOARDING_CITY,
  ONBOARDING_CLASS,
  ONBOARDING_COMPETITION_INFO,
  ONBOARDING_MATH_UNIT,
  ONBOARDING_DONE,
  NAVIGATION_IN_INFO,
  SHARE_A_MAKE,
  CAMPAIGN_ENTRY,
  PREVIOUS_COMPONENT,
  PROFILE_LEADERBOARD_CLICK,
  GURU_REVEAL,
  GURU_CHOICE,
  RECIEVE_STAR,
} from '../CONSTANT';
import { track as TRACK } from '../Methods';

export const signInOpenEvent = (appData, from) => {
  switch (from) {
    case SIGN_IN_OPEN_FROM.INSIDE_MAKE: {
      const { track, topic, make } = appData;
      const isCampaign = make?.isCampaign;
      const makeType = getMakeType({ isCampaign });

      return TRACK(SIGN_IN_OPEN, {
        'Make Name': make?.makeName,
        'Make Route': make?.makeRoute,
        'Make Type': makeType,
        'Topic Name': topic?.topicName,
        'Topic Route': topic?.topicRoute,
        'Track Name': track?.trackName,
        'Track Route': track?.trackRoute,
        from: from,
      });
    }
    case SIGN_IN_OPEN_FROM.DISCOVERY:
      return TRACK(SIGN_IN_OPEN, { from });
    default:
      break;
  }
};

export const navigationInInfo = (appData, componentOrder, navigationButton) => {
  const { track, topic, make } = appData;
  const isCampaign = make?.isCampaign;
  const makeType = getMakeType({ isCampaign });
  const type = { done: 'V', points: 'Stars', info: '!' };
  TRACK(NAVIGATION_IN_INFO, {
    'Make Name': make?.makeName,
    'Make Route': make?.makeRoute,
    'Make Type': makeType,
    'Topic Route': topic?.topicRoute,
    'Track Route': track?.trackRoute,
    'Component Number': componentOrder,
    'Navigation Button': type[navigationButton],
  });
};

export const shareMakeClickedEvent = (appData) => {
  const { track, topic, make } = appData;
  const isCampaign = make?.isCampaign;
  const makeType = getMakeType({ isCampaign });
  TRACK(SHARE_A_MAKE, {
    'Make Name': make?.makeName,
    'Make Route': make?.makeRoute,
    'Make Type': makeType,
    'Topic Name': topic?.topicName,
    'Topic Route': topic?.topicRoute,
    'Track Name': track?.trackName,
    'Track Route': track?.trackRoute,
  });
};

export const noDiscordClickedEvent = () => {
  TRACK(NO_DIC_CLK);
};
export const skipClickedEvent = (comp) => {
  TRACK(SKIP_CLK, { comp });
};

export const nextComponentNavigationEvent = (appData) => {
  const { track, topic, make, components, currentIndex } = appData;
  const { type } = components[currentIndex];
  const componentTitle = getComponentTitle(components, currentIndex, type);
  const isCampaign = make?.isCampaign;
  const makeType = getMakeType({ isCampaign });
  TRACK(NEXT_COMPONENT, {
    'Make Name': make?.makeName,
    'Make Route': make?.makeRoute,
    'Make Type': makeType,
    'Topic Name': topic?.topicName,
    'Topic Route': topic?.topicRoute,
    'Track Name': track?.trackName,
    'Track Route': track?.trackRoute,
    'Component title': componentTitle,
    'Component type': type,
    'Component Order': currentIndex,
  });
};

export const campaignEntryEvent = (appData) => {
  const { track, topic, make } = appData;
  const isCampaign = make?.isCampaign;
  const makeType = getMakeType({ isCampaign });
  TRACK(CAMPAIGN_ENTRY, {
    'Make Name': make?.makeName,
    'Make Route': make?.makeRoute,
    'Make Type': makeType,
    'Topic Name': topic?.topicName,
    'Topic Route': topic?.topicRoute,
    'Track Name': track?.trackName,
    'Track Route': track?.trackRoute,
  });
};

export const previousComponentNavigationEvent = (appData) => {
  const { track, topic, make, components, currentIndex } = appData;
  const isCampaign = make?.isCampaign;
  const makeType = getMakeType({ isCampaign });
  const type = components[currentIndex]?.type;
  const componentTitle = getComponentTitle(components, currentIndex, type);

  TRACK(PREVIOUS_COMPONENT, {
    'Make Name': make?.makeName,
    'Make Route': make?.makeRoute,
    'Make Type': makeType,
    'Topic Name': topic?.topicName,
    'Topic Route': topic?.topicRoute,
    'Track Name': track?.trackName,
    'Track Route': track?.trackRoute,
    'Component title': componentTitle,
    'Component type': type,
    'Component Order': currentIndex,
    'Is Campaign': isCampaign,
  });
};

export const componentCloseEvent = (
  prevLocationState,
  currentComponents,
  currentIndex,
  type,
  isCampaign
) => {
  const { makeRoute, topicRoute, trackRoute } = prevLocationState;
  const makeType = getMakeType({ isCampaign });
  const componentTitle = getComponentTitle(currentComponents, currentIndex, type);
  TRACK(COMP_CLOSE, {
    'Make Name': makeRoute,
    'Make Type': makeType,
    'Topic Name': topicRoute,
    'Track Name': trackRoute,
    'Component title': componentTitle,
    'Component type': type,
    'Component Order': currentIndex,
  });
};
export const componentUploadOnMounting = (appData) => {
  const { track, topic, make, components, currentIndex } = appData;
  const isCampaign = make?.isCampaign;
  const makeType = getMakeType({ isCampaign });
  const type = components[currentIndex]?.type;
  const componentTitle = getComponentTitle(components, currentIndex, type);

  TRACK(SUBMISSION_LOAD, {
    'Make Name': make?.makeName,
    'Make Route': make?.makeRoute,
    'Make Type': makeType,
    'Topic Name': topic?.topicName,
    'Topic Route': topic?.topicRoute,
    'Track Name': track?.trackName,
    'Track Route': track?.trackRoute,
    'Component title': componentTitle,
    'Component Order': currentIndex,
  });
};
export const nextMakeEvent = (appData, opp, nextMakeName) => {
  const { track, topic, make } = appData;
  const isCampaign = make?.isCampaign;
  const makeType = getMakeType({ isCampaign });
  TRACK(UP_NEXT_MAKE, {
    'Current Make': make?.makeName,
    'Make Route': make?.makeRoute,
    'Make Type': makeType,
    'Next Make': nextMakeName,
    'Topic Name': topic?.topicName,
    'Topic Route': topic?.topicRoute,
    'Track Name': track?.trackName,
    'Track Route': track?.trackRoute,
    'Is Auto': opp === 'auto',
    'Is Click': opp === 'click',
  });
};

export const siteVisitEvent = (tracks, trackRoute) => {
  if (tracks?.length && trackRoute) {
    const trackName = getSpecificObjFromArrayOfObjects(
      tracks,
      'trackRoute',
      trackRoute
    )?.trackName;

    TRACK(SITE_VISIT, { 'track name': trackName, 'Track Route': trackRoute });
  } else if (tracks?.length && !trackRoute) {
    TRACK(SITE_VISIT, {
      'track name': tracks[0]?.trackName,
      'Track Route': tracks[0]?.trackRoute,
    });
  }
};

export const makeClickedEvent = (appData, makeName, makeRoute, isCampaign) => {
  const { track, topic } = appData;
  const makeType = getMakeType({ isCampaign });

  TRACK(MAKE_CLK, {
    'Make Name': makeName,
    'Make Type': makeType,
    'Make Route': makeRoute,
    'Topic Name': topic.topicName,
    'Topic Route': topic.topicRoute,
    'Track Name': track.trackName,
    'Track Route': track.trackRoute,
  });
};

export const trackClickedEvent = (track) => {
  TRACK(TRACK_CLK, {
    'Track Name': track.trackName,
    'Track Route': track.trackRoute,
  });
};

export const metaModalClickedEvent = () => {
  TRACK(META_MODAL_OPENED);
};

export const metaWaitingListClickedEvent = () => {
  TRACK(META_WAITING_LIST);
};

export const termsAndPrivacyEvent = (type, prevPath) => {
  switch (type) {
    case 'terms':
      return TRACK(TERMS_AND_CONDITIONS_MP, {
        'Page origin': prevPath === '/login' ? 'Login screen' : 'Discovery',
      });
    case 'privacy':
      return TRACK(PRIVACY_POLICY_MP, {
        'Page origin': prevPath === '/login' ? 'Login screen' : 'Discovery',
      });
    default:
      break;
  }
};

export const discordHelpClickedEvent = (appData) => {
  const { track, topic, make, components, currentIndex } = appData;
  const component = components[currentIndex];
  const { type, _id: currComponentId } = component;

  const isCampaign = make?.isCampaign;
  const makeType = getMakeType({ isCampaign });
  if (currComponentId === components[0]?._id) {
    TRACK(DISK_HELP_CLK, {
      'Make Name': make?.makeName,
      'Make Type': makeType,
      'Make Route': make?.makeRoute,
      'Topic Name': topic?.topicName,
      'Topic Route': topic?.topicRoute,
      'Track Name': track?.trackName,
      'Track Route': track?.trackRoute,
      'Component type': 'Intro',
    });
  } else {
    const componentTitle = getComponentTitle(components, currentIndex, type);

    TRACK(DISK_HELP_CLK, {
      'Make Name': make.makeName,
      'Make Type': makeType,
      'Make Route': make.makeRoute,
      'Topic Name': topic.topicName,
      'Topic Route': topic.topicRoute,
      'Track Name': track.trackName,
      'Track Route': track.trackRoute,
      'Component title': componentTitle,
      'Component type': type,
      'Component Order': currentIndex,
    });
  }
};

export const goToOnBoardingEvent = (action) => {
  TRACK(ONBOARDING_CLK, { 'How Opened': action });
};

export const onBoardingNextComp = ({ compTitle, compOrder, compType }) => {
  TRACK(ONBOARDING_NEXT_COMP, {
    'Component title': compTitle,
    'Component type': compType,
    'Component Order': compOrder,
  });
};

export const onBoardingInterestsCompEvent = (preferences, inputInterest) => {
  TRACK(ONBOARDING_INTER_COMP, {
    'Chosen preferences': preferences,
    'input Interest': inputInterest,
  });
};

export const onBoardingGenderEvent = (gender) => {
  TRACK(ONBOARDING_GENDER, {
    Gender: gender,
  });
};

export const onBoardingCityEvent = (city) => {
  TRACK(ONBOARDING_CITY, {
    'Name of the city': city,
  });
};

export const onBoardingClassEvent = (grade) => {
  TRACK(ONBOARDING_CLASS, {
    'Class name': grade,
  });
};
export const onBoardingCompetitionEvent = (school, grade) => {
  TRACK(ONBOARDING_COMPETITION_INFO, {
    'School name': school,
    'Class name': grade,
  });
};

export const onBoardingMathUnitEvent = (units) => {
  TRACK(ONBOARDING_MATH_UNIT, {
    'Math Units': units,
  });
};

export const onBoardingDoneEvent = (notificationChecked, installchecked) => {
  TRACK(ONBOARDING_DONE, {
    'Is notifications checked': notificationChecked ? 'Yes' : 'No',
    'Is install checked:': installchecked ? 'Yes' : 'No',
  });
};

export const profileLeaderBoardClickEvent = () => {
  TRACK(PROFILE_LEADERBOARD_CLICK, {
    'is leaderboard clicked': true,
  });
};

export const guruRevealEvent = (appData) => {
  const { make, topic, track, components, currentIndex } = appData;
  const questions = components[currentIndex]?.data.questions;
  const question = questions[Object.keys(questions)];
  const questionTitle = question?.title?.text || question?.title?.imageUrl;
  const isCampaign = make?.isCampaign;
  const makeType = getMakeType({ isCampaign });
  TRACK(GURU_REVEAL, {
    'Make Name': make?.makeName,
    'Make Type': makeType,
    'Topic Name': topic?.topicName,
    'Track Name': track?.trackName,
    'Make Route': make?.makeRoute,
    'Topic Route': topic?.topicRoute,
    'Track Route': track?.trackRoute,
    'Question Title': questionTitle,
  });
};

export const guruChoiceEvent = (appData, choice) => {
  const { make, topic, track, components, currentIndex } = appData;
  const questions = components[currentIndex]?.data.questions;
  const question = questions[Object.keys(questions)];
  const questionTitle = question?.title?.text || question?.title?.imageUrl;
  const isCampaign = make?.isCampaign;
  const makeType = getMakeType({ isCampaign });
  TRACK(GURU_CHOICE, {
    'Make Name': make?.makeName,
    'Make Type': makeType,
    'Topic Name': topic?.topicName,
    'Track Name': track?.trackName,
    'Make Route': make?.makeRoute,
    'Topic Route': topic?.topicRoute,
    'Track Route': track?.trackRoute,
    'Question Title': questionTitle,
    'Guru Choice': choice,
  });
};

export const closeFacilitatorComponent = (makeName, currentComponents, currentIndex) => {
  const { type } = currentComponents[currentIndex];
  const componentTitle = getComponentTitle(currentComponents, currentIndex, type);
  TRACK(COMP_CLOSE, {
    'Make Name': makeName,
    'Make Type': 'facilitator',
    'Component title': componentTitle,
    'Component type': type,
    'Component Order': currentIndex,
  });
};

export const facilitatorNextComponentNavigationEvent = (appData) => {
  const { make, components, currentIndex } = appData;
  const { type } = components[currentIndex];
  const componentTitle = getComponentTitle(components, currentIndex, type);
  TRACK(NEXT_COMPONENT, {
    'Make Name': make?.makeName,
    'Make Route': make?.makeRoute,
    'Make Type': 'facilitator',
    'Component title': componentTitle,
    'Component type': type,
    'Component Order': currentIndex,
  });
};

export const facilitatorPreviousComponentNavigationEvent = (appData) => {
  const { make, components, currentIndex } = appData;
  const { type } = components[currentIndex];
  const componentTitle = getComponentTitle(components, currentIndex, type);

  TRACK(PREVIOUS_COMPONENT, {
    'Make Name': make?.makeName,
    'Make Route': make?.makeRoute,
    'Make Type': 'facilitator',
    'Component title': componentTitle,
    'Component type': type,
    'Component Order': currentIndex,
  });
};
