import Image from 'pages/CMSPage/components/inputTypes/Image/Image';
import { TextArea } from 'pages/CMSPage/components/inputTypes/TextArea/TextArea.style';
import React from 'react';
import useQuestionHandler from '../../../../../../hooks/useQuestionHandler';
import * as S from './Story.style';
const Story = ({ question }) => {
  const { story } = question;
  const { handleStoryText, handleStoryImageChange } = useQuestionHandler();

  return (
    <>
      <S.Wrapper>
        <TextArea
          value={story?.text}
          onChange={(event) => handleStoryText(event, question)}
        />
      </S.Wrapper>
      <S.Wrapper>
        <Image
          onChange={(event) => handleStoryImageChange(event, question)}
          value={story?.imageUrl}
        />
      </S.Wrapper>
    </>
  );
};

export default Story;
