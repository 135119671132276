import { createQuizMake } from 'apis/CMS/quizMake';
import { getMake, getMakesByTopic } from 'apis/makes';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

const useCreateQuizMake = () => {
  const handleError = useErrorHandler();
  const userId = useSelector((state) => state.auth?.user?._id);
  const { setLoading, activeAlert } = useCmsContext();
  const { setMakes } = useEditorContext();
  const createNewQuizMake = async (makeId, topicId, trackId) => {
    setLoading(true);
    try {
      const { data: quizMake } = await createQuizMake(makeId, topicId, trackId);
      const { data: originalMake } = await getMake(userId, quizMake.makeId);
      const { data: makes } = await getMakesByTopic(originalMake.topicId, userId);
      setMakes(makes);
      setLoading(false);
      activeAlert({ title: 'הצלחנו', text: `<p>מייק שאלון נוסף בהצלחה</p>` });
      return originalMake;
    } catch (error) {
      handleError(error);
    }
  };
  return { createNewQuizMake };
};

export default useCreateQuizMake;
