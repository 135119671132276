export const validateTournamentName = (event) => {
  if (event.target.validity.valueMissing) {
    event.target.setCustomValidity('שם הטורניר לא יכול להיות ריק');
  }
};

export const validateClassName = (event) => {
  if (event.target.validity.valueMissing) {
    event.target.setCustomValidity('שם הכיתה לא יכול להיות ריק');
  }
};

export const validateFacilitatorName = (event) => {
  if (event.target.validity.valueMissing) {
    event.target.setCustomValidity('שם המנחה לא יכול להיות ריק');
  }
};

export const validateFacilitatorEmail = (event) => {
  if (event.target.validity.typeMismatch) {
    event.target.setCustomValidity(
      `איימיל לא תקין , ודא שכתוב בפורמט הנכון
       דוגמא  :email@example.com`
    );
  }

  if (event.target.validity.valueMissing) {
    event.target.setCustomValidity(' האיימיל לא יכול להיות ריק');
  }
};
