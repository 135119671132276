import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';

const DefaultButtonStyle = styled.button`
  white-space: nowrap;
  border-radius: 1rem;
  padding: 1rem;
  color: white;
  font-size: 1.8rem;
  font-family: ${primaryFont.bold};
  background-color: red;
  cursor: pointer;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
`;
export const Image = styled.img`
  border-radius: 0.5rem;
  display: block;
  height: 90%;
  object-fit: cover;
`;
export const ImageConainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 60rem;
  justify-content: center;
  align-items: center;
`;
export const ImageDescription = styled.p`
  text-align: center;
  font-size: 1.8rem;
  font-family: ${primaryFont.bold};
`;
export const PublishButton = styled(DefaultButtonStyle)`
  background-color: ${({ bg }) => bg};
`;
export const UpdateButton = styled(DefaultButtonStyle)`
  background-color: ${({ bg }) => bg};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
