import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const Avatar = styled.img`
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  margin-left: ${({ type }) => type === 'avatarBar' && '-1.5rem'};
  margin-left: ${({ type }) => type === 'avatarBar' && '-1rem'};

  @media ${device.tablet} {
    margin-left: ${({ type }) => type === 'avatarBar' && '-1.5rem'};
  }
`;
