import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';

export const MotivationRectangle = styled.div`
  width: 10rem;
  height: 8rem;
  background: ${({ image }) => `url(${image})`} no-repeat scroll center center white;
  background-size: contain;
  background-origin: content-box;
  padding: 0.3rem;
  border: ${({ chosen }) =>
    chosen
      ? '3px solid  var(--color-charcoal-grey)'
      : '1px solid var(----color-mid-grey)'};
  border-radius: 1rem;
  margin: 0.5rem 0.8rem;
  cursor: pointer;
  font-family: ${primaryFont.bold};
  line-height: 8rem;
  text-align: center;
  color: var(----color-mid-grey);
`;
