import { useRef, useMemo, useEffect } from 'react';

import JoditEditor from 'jodit-react';
import * as S from './RichTextEditor.style';
import { config } from './config/config';
const RichTextEditor = ({ value, callback, label, extraConfig }) => {
  const editor = useRef(null);

  const joditEditorProps = {
    ref: editor,
    value: value || '',
    config: useMemo(() => ({ ...config, ...extraConfig }), []), //on editor content change/re-render editor component reuse the config and slow the performance , so i wrapped it with useMemo
    tabIndex: 1, // tabIndex of textarea
    onChange: (newContent) => callback(newContent), // preferred to use only this option to update the content for performance reasons
  };

  return (
    <S.Container>
      <S.Label>{label}</S.Label>
      <JoditEditor {...joditEditorProps} />
    </S.Container>
  );
};

export default RichTextEditor;
