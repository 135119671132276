import ActionButton from 'pages/CMSPage/components/ActionButton/ActionButton';
import * as S from './ConfirmationModal.style';

const ConfirmationModal = ({ handleConfirm, handleReject, title }) => {
  return (
    <S.Container>
      <S.Box>
        <S.Title>{title}</S.Title>
        <S.ActionButtonsContainer>
          <ActionButton
            background={'green'}
            value={'לא'}
            handleOnClick={() => handleReject()}
          />
          <ActionButton
            background={'red'}
            handleOnClick={() => handleConfirm()}
            value={'כן'}
          />
        </S.ActionButtonsContainer>
      </S.Box>
    </S.Container>
  );
};

export default ConfirmationModal;
