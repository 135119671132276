import { useState } from 'react';

import completionUnitsIcon from 'assets/icons/done-units-white.svg';
import goalsIcon from 'assets/icons/goals.svg';
import RootModal from 'components/RootModal/RootModal';
import { LockProvider } from 'contexts/componentLockContext';
import { useSelector } from 'react-redux';

import DisplayMetaFeatures from './components/DisplayMetaFeatures/DisplayMetaFeatures';
import * as S from './metaFeatures.styles';

const MetaFeatures = ({ from }) => {
  const [showMetaIframe, setShowMetaIframe] = useState(null);
  const currentTrack = useSelector((state) => state.app.currentTrack);
  const isMetaTrack = currentTrack.trackRoute === 'meta';

  const renderIcons = () => {
    return (
      <S.MetaIconsWrapper>
        <S.DoneUnitIconWrapper>
          <S.CompletionUnitsIcon
            from={from}
            src={completionUnitsIcon}
            alt="יחידות שסיימת"
            onClick={() => handleOnClickMetaIcon('completionUnits')}
          />
          <S.IconText from={from}>יחידות שסיימת</S.IconText>
        </S.DoneUnitIconWrapper>
        <S.GoalsIconWrapper>
          <S.GoalsIcon
            from={from}
            src={goalsIcon}
            alt="היעדים"
            onClick={() => handleOnClickMetaIcon('goals')}
          />
          <S.IconText from={from}>היעדים</S.IconText>
        </S.GoalsIconWrapper>
      </S.MetaIconsWrapper>
    );
  };
  const handleOnClickMetaIcon = (type) => {
    setShowMetaIframe(type);
  };

  return (
    <>
      {isMetaTrack && renderIcons()}
      {showMetaIframe && (
        <RootModal visible={true}>
          <DisplayMetaFeatures
            type={showMetaIframe}
            setShowMetaIframe={setShowMetaIframe}
          />
        </RootModal>
      )}
    </>
  );
};

export default MetaFeatures;
