import { Children } from 'react';

import starIconYellow from 'assets/icons/star-make-fill.svg';
import starIcon from 'assets/icons/star-make.svg';

import * as S from './progressStar.styles';
const ProgressStar = ({ progress, makeId }) => {
  // 6178019be776752fc1afa891
  const TOTAL_STAR = 3;
  const achievedStar = progress?.achievedStar || 0;

  return (
    <S.ContainerStar>
      {Children.toArray(
        Array.from(Array(achievedStar)).map((_) => (
          <S.StarImg alt="star" src={starIconYellow} />
        ))
      )}
      {Children.toArray(
        Array.from(Array(TOTAL_STAR - achievedStar)).map((_) => (
          <S.StarImg alt="star" src={starIcon} />
        ))
      )}
    </S.ContainerStar>
  );
};
export default ProgressStar;
