import blackInfo from 'assets/icons/blackInfo.svg';
import infoActive from 'assets/icons/infoActive.svg';
import { useSelector, useDispatch } from 'react-redux';
import { toggleComponentInfo } from 'services/redux/ui.slice';

import * as S from './infoIcon.styles.js';
const InfoIcon = () => {
  const dispatch = useDispatch();
  const currentTrack = useSelector((state) => state.app.currentTrack);
  const showInfo = useSelector((state) => state.ui.showComponentInfo);
  const isMobile = useSelector((state) => state.ui.isMobile);
  return (
    <>
      {currentTrack.trackRoute !== 'meta' ? (
        <S.InfoWrapper isMobile={isMobile} isActive={showInfo}>
          <img
            alt="info"
            src={showInfo ? infoActive : blackInfo}
            onClick={() => dispatch(toggleComponentInfo())}
          />
          <S.TextHeader isMobile={isMobile}>מידע</S.TextHeader>
        </S.InfoWrapper>
      ) : (
        <></>
      )}
    </>
  );
};
export default InfoIcon;
