import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { createContext, useContext, useEffect, useState } from 'react';
import useGetInitialData from '../hooks/useGetInitialData';

const FacilitatorContext = createContext();

const useFacilitatorContext = () => {
  const context = useContext(FacilitatorContext);
  if (!context) {
    throw new Error('useFacilitatorContext must be used within a CMSProvider');
  }
  return context;
};

const FacilitatorProvider = (props) => {
  const [draggableList, setDraggableList] = useState(null);
  const [choice, setChoice] = useState({
    make: null,
    component: null,
  });
  const { make, component } = choice;
  const { setCurrentSelectedData, mode, setShowPreviousBtn } = useCmsContext();
  const { setComponents, setMakes, makes, components } = useGetInitialData(choice);
  useEffect(() => {
    switch (mode) {
      case 'make': {
        setDraggableList(null);
        setCurrentSelectedData(make);
        break;
      }
      case 'component': {
        setCurrentSelectedData(component);
        setDraggableList({
          list: components,
          currentItem: component,
        });
        break;
      }
      default:
        return setShowPreviousBtn(false);
    }
    return setShowPreviousBtn(true);
  }, [mode, choice]);

  const handleChoiceChange = (key, value) => {
    switch (key) {
      case 'make': {
        setChoice((prev) => ({ ...prev, [key]: value, component: null }));
        break;
      }
      case 'component': {
        setChoice((prev) => ({ ...prev, [key]: value }));
        break;
      }

      default:
        break;
    }
  };

  const value = {
    setComponents,
    setMakes,
    handleChoiceChange,
    draggableList,
    choice,
    makes,
    components,
  };
  return <FacilitatorContext.Provider value={value} {...props} />;
};
export { FacilitatorProvider, useFacilitatorContext };
