import { api, getData } from 'apis/index';

import {
  API_VERSION,
  USER_UPLOAD,
  GET_DETAILS_USER,
  UPDATE_USER,
  UPLOAD_MAKE,
  GET_USER_ACTIVITIES,
} from './CONSTANTS';

export const getUserDetails = (userId) => {
  return api.get(`${API_VERSION}/${GET_DETAILS_USER(userId)}`).then(getData);
};

export const getUserActivity = (userId) => {
  return api.get(`${API_VERSION}/${GET_USER_ACTIVITIES(userId)}`).then(getData);
};

export const updateUser = ({ discordId, googleId, anonymousId }, args) => {
  const body = { ...args };
  if (discordId)
    return api
      .put(`${API_VERSION}/${UPDATE_USER(discordId, 'discord')}`, body)
      .then(getData);
  else if (googleId)
    return api
      .put(`${API_VERSION}/${UPDATE_USER(googleId, 'google')}`, body)
      .then(getData);
  else
    return api
      .put(`${API_VERSION}/${UPDATE_USER(anonymousId, 'anonymous')}`, body)
      .then(getData)
      .catch((error) => {
        // The request was made but no response was received
      });
};

export const uploadMake = (imageData, userId, makeId) => {
  return api.post(
    `${API_VERSION}/${UPLOAD_MAKE(userId, makeId)}`,
    { ...imageData },
    {
      params: {
        userId: userId,
        makeId: makeId,
      },
    }
  );
};

export const userUpload = (fileUrl, publicSharing, userId, makeName) => {
  return api
    .post(
      `${API_VERSION}/${USER_UPLOAD}`,
      { fileUrl },
      {
        params: {
          publicSharing: publicSharing,
          userId: userId,
          makeName: makeName,
        },
      }
    )
    .then(getData);
};

export const deleteAnonymousUser = (anonymousId, newUserId) => {
  return api
    .post(`${API_VERSION}/users/deleteAnonymous/${anonymousId}`, {
      newUserId,
    })
    .then(getData);
};
