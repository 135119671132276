import { getTracks } from 'apis/tracks';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

const useGetAllTracks = () => {
  const handleError = useErrorHandler();
  const userId = useSelector((state) => state.auth?.user?._id);
  const getAllTracks = async () => {
    try {
      const { data: tracks } = await getTracks(userId);
      return tracks;
    } catch (err) {
      handleError(err);
    }
  };
  return { getAllTracks };
};

export default useGetAllTracks;
