/* eslint-disable no-undef */
import { getData } from 'apis/index';
import axios from 'axios';
import metaFormSchema from 'services/validation/schemas/metaFormSchema';
import { META_APPID, META_SIGNUP, META_BASE_URL } from './CONSTANTS';

const META_APPSECRET = process.env.REACT_APP_META_APPSECRET;
let proxy;
process.env.NODE_ENV === 'development'
  ? (proxy = 'https://cors-anywhere.herokuapp.com/')
  : (proxy = '');

export const getSchools = async () => {
  return await axios.get(`${proxy}${META_BASE_URL}api/get_schools`).then(getData);
};

export const signup = ({
  firstName,
  lastName,
  phone,
  email,
  gender,
  school,
  schoolClass,
}) => {
  const formObject = {
    appid: META_APPID,
    appsecret: META_APPSECRET,
    fname: firstName,
    lname: lastName,
    email,
    phone,
    gender,
    school_id: school.details.school_id,
    class_id: schoolClass.class_id,
  };

  const getFormData = async (object) => {
    try {
      await metaFormSchema.validateAsync(object);

      const formData = new FormData();
      Object.keys(object).forEach((key) => {
        formData.append(key, object[key]);
      });

      return formData;
    } catch (error) {
      console.log('validation not passed');
    }
  };

  const bodyFormData = getFormData(formObject);

  return axios.post(`${proxy}${META_BASE_URL}api/${META_SIGNUP}`, bodyFormData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getUserTree = (studentId) => {
  const data = {
    params: {
      appid: META_APPID,
      appsecret: META_APPSECRET,
      student_id: studentId,
    },
  };
  //put proxy back
  return axios.get(`${proxy}${META_BASE_URL}api/units_by_student`, data).then(getData);
};
export const getTempToken = (studentId) => {
  //add proxy later
  return axios
    .get(
      `${META_BASE_URL}api/connect?appid=${META_APPID}&appsecret=${META_APPSECRET}&student_id=${studentId}`
    )
    .then(getData);
};
