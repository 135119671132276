import { useState, useEffect } from 'react';
const useImage = () => {
  const [imageRef, setImageRef] = useState(null); // contains image reference
  const [error, setError] = useState(false); // contains error flag

  const _handleError = () => {
    setError(true);
  }; // set error
  useEffect(() => {
    // use of error event of the image tag
    imageRef?.addEventListener('error', _handleError);

    return () => {
      imageRef?.removeEventListener('error', _handleError);
    };
  }, [imageRef]);

  return [
    setImageRef, // set the image ref
    error, // error flag
  ];
};
export default useImage;
