import React from 'react';
import * as S from './LockFields.style';
import Lock from './components/Lock/Lock';
import Xp from './components/Xp/Xp';
const LockFields = ({ type, component }) => {
  return (
    <S.Container>
      <Lock type={type} component={component} />
      <Xp />
    </S.Container>
  );
};

export default LockFields;
