import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import * as S from './MotivationEmoji.style';
const MotivationEmoji = ({ text = null, callback, type, image }) => {
  const { currentSelectedData } = useCmsContext();
  const component = currentSelectedData;
  const { motivationLayer } = component;

  return (
    <>
      <S.MotivationRectangle
        onClick={() => callback(type)}
        image={image}
        chosen={motivationLayer === type}
      >
        {text}
      </S.MotivationRectangle>
    </>
  );
};

export default MotivationEmoji;
