import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { getTrackRoute } from 'routes/helper';

import * as S from './NavBar.styles';
import { useRef } from 'react';
import NavItem from './NavItem/NavItem';

const NavBar = ({ tracks }) => {
  const params = useParams();
  const ref = useRef();
  const isMobile = useSelector((state) => state.ui.isMobile);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);

  const scrollToFunc = (offSet) => {
    ref.current.scrollTo({
      top: 0,
      left: offSet,
      behavior: 'smooth',
    });
  };

  const NavLinks = (
    <>
      {tracks?.map((track) => {
        const { trackRoute } = track;
        let path = getTrackRoute(trackRoute);
        if (trackRoute === 'meta') {
          if (!isLoggedIn) path = '/login';
          else {
            if (!user?.metaStudentId) {
              path = '/metaSignup';
            } else path = path = getTrackRoute(trackRoute);
          }
        } else path = getTrackRoute(trackRoute);
        return (
          <NavItem
            key={track?._id}
            tracks={tracks}
            scrollToFunc={scrollToFunc}
            path={path}
            params={params}
            track={track}
          />
        );
      })}
    </>
  );

  return (
    <S.NavBarContainer isMobile={isMobile}>
      <S.NavBarWrapper isMobile={isMobile} ref={ref}>
        {NavLinks}
      </S.NavBarWrapper>
    </S.NavBarContainer>
  );
};

export default NavBar;
