import UploadWidget from '../../UploadWidget/UploadWidget';
import switchIcon from 'assets/icons/switchIcon.svg';
import trashIcon from 'assets/icons/trashIcon.svg';
import * as S from './Teaser.styles.js';
import { useRef } from 'react';
import { CLOUD_NAME, UPLOAD_PRESET_FOR_TEASERS } from 'services/cloudinary/constants';

const Teaser = (props) => {
  const { value, handleData, label } = props;
  const settings = {
    cloudName: CLOUD_NAME,
    uploadPreset: UPLOAD_PRESET_FOR_TEASERS,
    multiple: false,
    maxFiles: 1,
    clientAllowedFormats: [
      'mp4',
      'webm',
      'ogg',
      'mov',
      'avi',
      'wmv',
      'flv',
      'mkv',
      'gif',
    ],

    folder: 'teasers',
  };
  const videoStyles = {
    objectFit: 'fill',
    height: '100%',
    width: '100%',
  };
  const uploadRef = useRef(null);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
  };

  const fallbackToImage = () => {
    return (
      <>
        <S.Image alt={'image'} src={value} />
        <S.ChangeFileContainer>
          <S.DeleteIcon onClick={() => handleData('')}>
            <img alt="trash" src={trashIcon} />
          </S.DeleteIcon>
          <S.SwitchIcon onClick={() => uploadRef.current.open()}>
            <img alt="switch" src={switchIcon} />
          </S.SwitchIcon>
        </S.ChangeFileContainer>
      </>
    );
  };

  const renderUploadedImageEditor = () => (
    <S.Container>
      {!value.includes('.mp4') ? (
        fallbackToImage()
      ) : (
        <>
          <video style={videoStyles} autoPlay loop muted src={value}></video>
          <S.ChangeFileContainer>
            <S.DeleteIcon onClick={() => handleData('')}>
              <img alt="trash" src={trashIcon} />
            </S.DeleteIcon>
            <S.SwitchIcon onClick={() => uploadRef.current.open()}>
              <img alt="switch" src={switchIcon} />
            </S.SwitchIcon>
          </S.ChangeFileContainer>
        </>
      )}
    </S.Container>
  );
  return (
    <>
      <S.Label>{label}</S.Label>
      {value && renderUploadedImageEditor()}
      <UploadWidget
        settings={settings}
        label={label}
        handleData={handleData}
        ref={uploadRef}
        value={value}
      />
      {!value && (
        <button
          style={{
            backgroundColor: 'yellow',
            border: '1px solid blue',
            height: '30px',
            width: '100px',
          }}
          onClick={() => uploadRef.current.open()}
        >
          {label}
        </button>
      )}
      {value?.length > 0 && (
        <div style={{ display: 'flex' }}>
          <h2>{value}</h2>
          {!value?.includes('amazonaws') && (
            <button
              style={{
                background: 'green',
                height: '20px',
                width: '100px',
                color: 'white',
                border: '1px solid darkorange',
              }}
              onClick={handleCopy}
            >
              copy
            </button>
          )}
        </div>
      )}

      {!value?.includes('.mp4') && value?.length > 0 && (
        <h2 style={{ color: 'red', fontWeight: 'bold' }}>{label} is not a mp4</h2>
      )}
    </>
  );
};

export default Teaser;
