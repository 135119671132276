import styled from 'styled-components/macro';
export const AccordionWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0;
`;

export const AccordionItem = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0;
  flex-direction: column;
`;

export const AccordionContents = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const AccordionButton = styled.button`
  background-color: var(--color-charcoal-grey);
  padding: 6.5px 10px;
  cursor: pointer;
  align-items: center;

  margin-bottom: 1rem;
  display: flex;
  color: var(--color-white);
  justify-content: space-between;
  font-size: 18px;
  width: 100%;
  border-radius: 6px;
`;

export const Arrow = styled.img`
  ${({ isOpen }) => isOpen && `transform:${isOpen && 'rotate(180deg)'}`}
`;

export const AccordionItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
