import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import React from 'react';
import useFeedbackHandler from '../../../../../../hooks/useFeedbackHandler';
import * as S from './WrongFeedback.style';

const WrongFeedback = ({ question }) => {
  const { wrongFeedback } = question;
  const { handleWrongFeedbackTitleChange, handleWrongFeedbackSubTitleChange } =
    useFeedbackHandler();
  const renderWrongFeedback = () => {
    return wrongFeedback.map((feedback, index) => {
      const { title, subTitle } = feedback;
      return (
        <>
          <S.Wrapper>
            <Input
              type={'text'}
              label={` משוב לתשובה שגויה${index + 1}`}
              value={title}
              placeholder={'כותרת'}
              onChange={(event) =>
                handleWrongFeedbackTitleChange(event.target.value, index, question)
              }
            />
          </S.Wrapper>
          <S.Wrapper>
            <Input
              type={'text'}
              value={subTitle}
              onChange={(event) =>
                handleWrongFeedbackSubTitleChange(event.target.value, index, question)
              }
              placeholder={'תת כותרת'}
            />
          </S.Wrapper>
        </>
      );
    });
  };
  return <S.Container>{renderWrongFeedback()}</S.Container>;
};

export default WrongFeedback;
