import AlertContainer from 'components/Alert';
import Spinner from 'components/Spinner';
import DefaultMenu from '../../components/DefaultMenu/DefaultMenu';
import CMSHeader from '../../components/CMSHeader/CMSHeader';
import { useCmsContext } from '../../contexts/cmsContext';
import ComponentEditor from './components/ComponentEditor/ComponentEditor';
import MakeEditor from './components/MakeEditor/MakeEditor';
import Menu from './components/Menu/Menu';
import TopicEditor from './components/TopicEditor/TopicEditor';
import TrackEditor from './components/TrackEditor/TrackEditor';
import { EditorProvider } from './context/editorContext';
import QuizEditor from 'pages/CMSPage/EditorType/MainEditor/components/QuizEditor/QuizEditor';
const MainEditor = () => {
  const { mode, loading, currentSelectedData, alert } = useCmsContext();
  const renderEditorMode = () => {
    if (!currentSelectedData) return;
    switch (mode) {
      case 'track':
        return <TrackEditor />;
      case 'topic':
        return <TopicEditor />;
      case 'make':
        return <MakeEditor />;
      case 'component':
        return <ComponentEditor />;
      case 'quizComponent':
        return <QuizEditor />;
      default:
        return <DefaultMenu />;
    }
  };
  return (
    <>
      <AlertContainer alert={alert} />
      {loading && <Spinner />}
      <CMSHeader />
      <EditorProvider>
        <Menu />
        {renderEditorMode()}
      </EditorProvider>
    </>
  );
};

export default MainEditor;
