/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { useEffect, useState, useRef } from 'react';

import { META_APPID, META_BASE_URL } from 'apis/CONSTANTS';
import { getTempToken } from 'apis/meta';
import Iframe from 'components/Iframe';
import Spinner from 'components/Spinner';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

import * as S from '../DisplayMetaFeatures/displayMetaFeatures.styles';

const MetaIframe = ({ data }) => {
  const META_APPSECRET = process.env.REACT_APP_META_APPSECRET;
  const handleError = useErrorHandler();
  const url = data.url;

  const studentId = useSelector((state) => state.auth.user.metaStudentId);
  const refContainer = useRef(null);

  const [completeUrl, setCompleteUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [heightDiff, setHeightDiff] = useState(null);

  useEffect(() => {
    const target = refContainer.current;
    const top = target.getBoundingClientRect().top;
    setHeightDiff(top);
  }, [refContainer]);

  useEffect(() => {
    const fetchTempToken = async () => {
      try {
        setIsLoading(true);
        const { token } = await getTempToken(studentId);

        const configureUrl = `${META_BASE_URL}/${url}?appid=${META_APPID}&appsecret=${META_APPSECRET}&token=${token}&student_id=${studentId}`;
        setCompleteUrl(configureUrl);
      } catch (error) {
        handleError(error);
      }
    };
    fetchTempToken();
  }, [url]);

  return (
    <>
      <S.IFrameContainer ref={refContainer} top={heightDiff}>
        {isLoading && <Spinner />}

        {completeUrl && (
          <Iframe
            setIsLoading={setIsLoading}
            url={completeUrl}
            height="100%"
            width="100%"
          />
        )}
      </S.IFrameContainer>
    </>
  );
};

export default MetaIframe;
