export const API_VERSION = `api/v2`;
export const BASE_URL = process.env.REACT_APP_API_URL;
export const DISCORD_BASE_URL = 'https://discord.com/api/v9/';

//MakesApi
export const GET_AVATAR_USERS = (trackId) => {
  return `progress/user/track/${trackId}`;
};
export const GET_SCHOOL_BY_CLASS_ID = (classId) => `users/class/${classId}/getSchool`;
export const GET_ALL_SCHOOLS = (infoLocation) =>
  `users/leaderBoard/getAllSchools?city=${infoLocation}`;
export const GET_CLASSES_BY_SCHOOL_NAME = (schoolName) =>
  `users/leaderBoard/${schoolName}/getClasses`;
export const GET_ALL_TRACKS = (userId) => `tracks/${userId}`;
export const GET_ALL_TOPICS_BY_TRACK_ID = (trackId, userId) =>
  `tracks/${trackId}/${userId}/topics`;
export const GET_ALL_MAKES_BY_TOPIC_ID = (topicId, userId) => {
  return `makes/topic/${topicId}/${userId}/makes`;
};
export const GET_ALL_COMMENTS = (componentId) => {
  return `comments/component/${componentId}`;
};
export const ADD_COMMENTS = (userId, componentId) => {
  return `comments/user/${userId}/component/${componentId}/addComment`;
};

export const DELETE_COMMENT = (userId, componentId, commentId) => {
  return `comments/user/${userId}/component/${componentId}/comment/${commentId}`;
};

//makes
export const GET_MAKE_BY_ID = (makeId, userId) => `makes/${userId}/${makeId}`;
export const GET_ALL_USER_MAKES = (userId) => `progress/${userId}/getMakes`;
export const GET_MAKE = (userId, makeId) => `makes/getMake/${userId}/${makeId}`;

export const GET_NEW_MAKES = (userId) =>
  `makes/getNewMakes?itemNumber=7&userId=${userId}`;

export const GET_MAKES_IN_PROGRESS = (userId) => `makes/getInProgressMakes/${userId}`;

export const GET_POPULAR_MAKES = (userId) =>
  `makes/getPopularMakes?itemNumber=7&userId=${userId}`;

export const GET_RECOMMENDED_MAKES = (userId) =>
  `makes/getRecommendedMakesForUser/${userId}`;

export const UPLOAD_MAKE_TO_DB = (userId, makeId) => {
  return `progress/user/${userId}/${makeId}/uploadMake`;
};

//progress
export const GET_ALL_PROGRESS_OF_USER_BY_TRACK_ID = (userId, trackId) => {
  return `progress/user/${userId}/track/${trackId}`;
};

export const GET_PROGRESS_OF_USER_BY_USER_ID = (userId) => `progress/user/${userId}`;
export const UPDATE_PROGRESS_OF_USER = (userId, makeId) =>
  `progress/user/${userId}/${makeId}`;
export const GET_FOUR_USER_PROGRESS_BY_TRACK = (trackId) =>
  `progress/user/track/${trackId}`;
export const GET_USER_PROGRESS_BY_TRACK = (userId, trackId) => {
  return `progress/user/${userId}/track/${trackId}`;
};
export const UPDATE_USER_PROPERITES = (userId) => {
  return `users/${userId}/updateProperties`;
};
export const GET_USER_PROGRESS_BY_TOPIC_ID = (userId, topicId) => {
  return `progress/user/${userId}/topic/${topicId}`;
};
export const GET_USER_PROGRESS_BY_USER_AND_MAKE_ID = (userId, makeId) => {
  return `progress/user/${userId}/make/${makeId}`;
};

//tournament
export const GET_ALL_TOURNAMENTS = () => 'tournament/tournaments';
export const GET_ACTIVE_TOURNAMENTS = () => 'tournament/activeTournaments';
export const GET_TOURNAMENT_BY_ID = (tournamentId) =>
  `tournament/tournament/${tournamentId}`;
export const ADD_USER_TO_TOURNAMENT = (tournamentId, classId, userId) =>
  `tournament/addUser/tournament/${tournamentId}/class/${classId}/user/${userId}`;
export const UPDATE_MAKE_STATUS_BY_CLASS_ID = (tournamentId, classId, makeId) =>
  `tournament/update/tournament/${tournamentId}/class/${classId}/make/${makeId}`;

export const GET_LEADER_BOARD = (
  selectedOption,
  offset,
  isTournament,
  tournamentId,
  classId
) => {
  return `users/leaderBoard/topTen?leaderboard=${selectedOption}&offset=${offset}&isTournament=${isTournament}&tournamentId=${tournamentId}&classId=${classId}`;
};

export const GET_MY_LEADERBOARD_POSITION = (
  userId,
  selectedOption,
  tournamentId,
  classId,
  isTournament
) => {
  return `users/${userId}/leaderBoard/myPosition?leaderboard=${selectedOption}&isTournament=${isTournament}&tournamentId=${tournamentId}&classId=${classId}`;
};
// //topics
// export const UPLOAD_INFO_IMG = (makeId) => `makes/${makeId}/upload`;
// export const GET_TOPICS_BY_TRACK_ID = (trackId) => `tracks/${trackId}/topics`;

// export const GET_TOPIC_BY_TRACK_ID_AND_TOPIC_ID = (trackId, topicId) =>
//   `tracks/${trackId}/topics/${topicId}`;

// //users
// export const USER_UPLOAD = 'discord/upload';
// export const GET_DETAILS_USER = (userId) => `users/${userId}/details`;
// export const UPDATE_USER = (id, src) => `users/${src}/${id}`;

//Meta
export const META_BASE_URL = 'https://www.classit.co/api/';
// export const META_BASE_URL = 'https://www.mymeta.co.il/api/';
export const META_APPID = 'makes';

export const GOALS = '/student/api_goals';
export const COMPLETION_UNITS = '/student/api_done_units';
export const META_SIGNUP = 'signup_student';
export const META_GET_USER_TREE = 'units_by_student';
export const META_GET_SCHOOLS = 'get_schools';
export const META_CONNECT = 'connect';

//CMS
export const CREATE_NEW_MAKE = (trackId, topicId) =>
  `CMS/track/${trackId}/topics/${topicId}`;
export const CREATE_NEW_COMPONENT = (trackId, topicId, makeId) =>
  `CMS/track/${trackId}/topics/${topicId}/makes/${makeId}`;

export const UPDATE_TOPIC = (trackId, topicId) =>
  `CMS/track/${trackId}/topics/${topicId}/update`;

export const UPLOAD_MAKE = (userId, makeId) =>
  `progress/user/${userId}/${makeId}/uploadMake`;

// components
export const GET_COMPONENT_BY_MAKE_ID_COMPONENT_ID = (makeId, componentId, userId) =>
  `makes/${makeId}/components/${componentId}/${userId}`;

export const GET_ALL_COMPONENTS_BY_MAKE_ID = (makeId, userId) =>
  `makes/${makeId}/components/${userId}`;

export const GET_ALL_QUIZ_MAKE_COMPONENTS_BY_MAKE_ID = (makeId, userId) =>
  `quizMake/${makeId}/components/${userId}`;

export const GET_RECOMMENDATION_TRACK = (userId) => {
  return `tracks/track/recommendation/${userId}`;
};

//topics
export const UPLOAD_INFO_IMG = (makeId) => `makes/${makeId}/upload`;
export const GET_TOPICS_BY_TRACK_ID = (trackId, userId) =>
  `tracks/${trackId}/${userId}/topics`;
export const GET_TOPIC_BY_TRACK_ID_AND_TOPIC_ID = (trackId, userId, topicId) =>
  `tracks/${trackId}/${userId}/topics/${topicId}`;

//users
export const USER_UPLOAD = 'discord/upload';
export const GET_DETAILS_USER = (userId) => `users/${userId}/details`;
export const UPDATE_USER = (id, src) => `users/${src}/${id}`;

//xp
export const GET_XP_LEVELS = `users/getXpLevels`;
export const UPDATE_USER_XP = (userId) => `users/${userId}/updateRank`;
export const GET_USER_XP = (userId) => `users/${userId}/getXP`;
//activity
export const UPDATE_USER_ACTIVITY = (userId) => `userActivity/${userId}/updateActivity`;
//points
export const UPDATE_USER_POINTS = (userId, trackId) =>
  `users/${userId}/addPoints?trackId=${trackId}`;
export const GET_USER_POINTS = (userId) => `users/${userId}/getPoints`;
