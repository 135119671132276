import React from 'react';

import logo from 'assets/icons/makes-logo.svg';
import SocialLinks from 'components/SocialLinks/SocialLinks';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { PRIVACY, TERMS } from 'routes/CONSTANTS';

import * as S from './Footer.styles';

const Footer = () => {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useSelector((state) => state.ui.isMobile);

  const copyright = '© כל הזכויות שמורות';
  const privacy = 'מדיניות הפרטיות';
  const terms = 'תנאי שימוש';

  const handleOnClick = (type) => {
    history.push({
      pathname: type === 'terms' ? TERMS : PRIVACY,
      state: { type, prevPath: location.pathname },
    });
  };

  return (
    <S.Container id={'footer'}>
      <S.IconsContainer isMobile={isMobile}>
        <S.RightSideContainer>
          <SocialLinks />
        </S.RightSideContainer>
        <S.MakesIcon src={logo} alt="logo"></S.MakesIcon>
      </S.IconsContainer>
      <S.TermsContainer isMobile={isMobile}>
        <S.CopyRight>{copyright}</S.CopyRight>
        <S.LinkContainer>
          <S.LinkText onClick={() => handleOnClick('terms')}>{terms}</S.LinkText>|
          <S.LinkText onClick={() => handleOnClick('privacy')}>{privacy}</S.LinkText>
        </S.LinkContainer>
      </S.TermsContainer>
    </S.Container>
  );
};
export default Footer;
