import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';
import { DefaultInputField, DefaultLabelStyle } from '../../GlobalStyle/Global.style';

export const Input = styled(DefaultInputField)`
  display: ${({ display }) => display};
  align-self: ${({ alignSelf }) => alignSelf};
`;
export const AlertMessage = styled.p`
  position: absolute;
  z-index: 999;
  border-radius: 3px;
  border: 1px solid rgb(180, 180, 180);
  background-color: rgb(252, 248, 227);
  color: rgb(185, 74, 72);
  display: block;
  font-size: 1.8rem;
  padding: 1rem 0.5rem;
  font-family: ${primaryFont.bold};
`;

export const Label = styled(DefaultLabelStyle)``;

export const InputWrapper = styled.div`
  position: relative;
`;
