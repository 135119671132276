import { deleteFacilitatorMake, getFacilitatorMakes } from 'apis/CMS/facilitator';
import { useErrorHandler } from 'react-error-boundary';
import { useFacilitatorContext } from '../../../context/useFacilitatorContext';

const useDeleteMake = () => {
  const handleError = useErrorHandler();
  const { setMakes, setComponents, choice } = useFacilitatorContext();
  const { make } = choice;
  const makeId = make?._id;

  const deleteMake = async () => {
    try {
      await deleteFacilitatorMake(makeId);
      const { data: makeData } = await getFacilitatorMakes();
      setMakes(makeData);
      setComponents([]);
    } catch (error) {
      handleError(error);
    }
  };
  return { deleteMake };
};

export default useDeleteMake;
