import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';
import { device } from 'utils/helpers/deviceScreen';
export const Container = styled.footer`
  background-color: var(--color-background);
  color: var(--color-black);
  height: 9rem;
  padding: 2rem;

  @media ${device.tablet} {
    padding: unset;
  }
`;
export const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ isMobile }) => (isMobile ? ' 2rem' : '0')};
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MakesIcon = styled.img`
  height: 3.1rem;
  width: 12.6rem;
`;

export const RightSideContainer = styled.div`
  display: flex;
`;

export const TermsContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LinkText = styled.p`
  font-family: ${primaryFont.regular};
  text-decoration: underline;
  padding: 1rem 0.5rem;
  font-size: 1rem;

  @media ${device.tablet} {
    font-size: 1.2rem;
  }
`;

export const CopyRight = styled.span`
  font-size: ${primaryFont.regular};
  font-size: 1rem;
  @media ${device.tablet} {
    font-size: 1.2rem;
  }
`;
