import { useErrorHandler } from 'react-error-boundary';
import { useUpdateUserActivityMutation } from 'services/api/makesApi/makesApi';
import { customErrorMsg } from 'utils/helpers';

const useUpdateUserActivity = () => {
  const handleError = useErrorHandler();
  const [updateUserActivity, { error: updateUserActivityError }] =
    useUpdateUserActivityMutation();

  if (updateUserActivityError) {
    return handleError(customErrorMsg('updateUserActivity', updateUserActivityError));
  }
  return { updateUserActivity };
};

export default useUpdateUserActivity;
