import { getAllComponentsByMake, updateComponentRequest } from 'apis/CMS/editor';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

const useUpdateComponent = () => {
  const handleError = useErrorHandler();
  const userId = useSelector((state) => state?.auth?.user?._id);
  const { choice, setComponents } = useEditorContext();
  const { component, make } = choice;
  const componentId = component?._id;
  const makeId = make?._id;
  const { currentSelectedData, activeAlert } = useCmsContext();

  const updateComponent = async () => {
    try {
      const res = await updateComponentRequest(componentId, currentSelectedData);
      const { data: fetchComponent } = await getAllComponentsByMake(makeId, userId);
      setComponents(fetchComponent);
      activeAlert({ title: 'הצלחנו', text: `<p>הקומפננטה עודכן בהצלחה</p>` });
      return res.data;
    } catch (error) {
      handleError(error);
    }
  };
  return { updateComponent };
};

export default useUpdateComponent;
