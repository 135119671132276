import styled from 'styled-components/macro';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  ::before {
    content: '';
    position: absolute;
    background-color: var(--color-black);
    z-index: -1;
    opacity: 0.8;
    height: 100%;
    width: 100%;
  }
`;

export const Box = styled.div`
  border: 2px solid var(--color-grey);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 25%;
  padding: 2rem;
  z-index: 1000;
  align-items: center;
  background-color: var(--color-white);
`;
export const Title = styled.h2`
  font-size: 2rem;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  column-gap: 1rem;
  justify-content: flex-end;
`;
