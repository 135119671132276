import { getMakesByTopic } from 'apis/makes';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

const useGetMakesByTopicId = () => {
  const handleError = useErrorHandler();
  const userId = useSelector((state) => state.auth?.user?._id);
  const getMakesByTopicId = async (topicId) => {
    try {
      const { data: makes } = await getMakesByTopic(topicId, userId);
      return makes;
    } catch (err) {
      handleError(err);
    }
  };
  return { getMakesByTopicId };
};

export default useGetMakesByTopicId;
