import { updatePoints } from 'components/auth/auth.slice';
import { useErrorHandler } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { useUpdateUserPointsMutation } from 'services/api/makesApi/makesApi';

const usePointsUpdate = () => {
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const [updateUserPoints, { error: updatePointsError }] = useUpdateUserPointsMutation();

  if (updatePointsError) {
    const message = updatePointsError?.error;
    return handleError(message);
  }

  const pointsUpdate = (request) => {
    updateUserPoints({ ...request })
      .unwrap()
      .then((data) => {
        dispatch(
          updatePoints({
            previousPoints: data.previousPoints,
            currentPoints: data.currentPoints,
          })
        );
      });
  };
  return { pointsUpdate };
};

export default usePointsUpdate;
