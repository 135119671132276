import React from 'react';
import * as S from './QuestionInstane.style';
import EditIcon from 'assets/icons/EditQuestionIcon.svg';
import TrashIcon from 'assets/icons/delete.svg';
import useQuestionsObjHandler from '../../hooks/useQuestionsObjHandler';
import useQuestionHandler from '../../hooks/useQuestionHandler';
import QuestionEditMode from './components/QuestionEditMode/QuestionEditMode';

const QuestionInstance = ({ question, index, editorType }) => {
  const { deleteQuestion } = useQuestionsObjHandler();
  const { handleEditorButton, isEditorOpened } = useQuestionHandler();
  return (
    <>
      <S.Question>
        <S.Title>{`שאלה ${index + 1}`}</S.Title>
        <S.ActionsContainer>
          <S.TrashIcon src={TrashIcon} onClick={() => deleteQuestion(question)} />
          <S.EditIcon src={EditIcon} onClick={() => handleEditorButton(index)} />
        </S.ActionsContainer>
      </S.Question>
      {isEditorOpened === index && (
        <QuestionEditMode editorType={editorType} question={question} />
      )}
    </>
  );
};

export default QuestionInstance;
