export const EDITOR_COMPONENT_TYPES = {
  youtube: 'וידאו',
  text: 'טקסט',
  checkbox: 'צ׳ק בוקס',
  choiceQuestion: 'חידון',
  upload: 'הגשה',
  google: 'גוגל',
  guru: 'גורו',
  discussion: 'דיון',
  iframe: 'הטמעה',
  miniGame: 'משחקונים',
};
export const FACILITATOR_COMPONENT_TYPES = {
  youtube: 'וידאו',
  text: 'טקסט',
  checkbox: 'צ׳ק בוקס',
  google: 'גוגל',
  discussion: 'דיון',
};

export const CONTENT_TYPE = { video: 'וידאו', image: 'תמונה' };
export const QUESTION_TYPE = {
  text: 'שאלה אמריקאית',
  twoOptions: 'שאלה עם שתי אפשרויות',
  image: 'שאלת תמונה',
};

export const STORY_TYPE = {
  text: 'טקסט',
  image: 'תמונה',
};

export const QUESTION_PART_DIC = {
  question: 'השאלה',
  answer: 'תשובות',
  feedback: 'משובים',
};
