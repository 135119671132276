import { api, getData } from 'apis/index';

import {
  API_VERSION,
  GET_TOPICS_BY_TRACK_ID,
  GET_TOPIC_BY_ID,
  UPLOAD_INFO_IMG,
} from './CONSTANTS';

export const getTopics = (trackId, userId) => {
  return api
    .get(`${API_VERSION}/${GET_TOPICS_BY_TRACK_ID(trackId, userId)}`)
    .then(getData);
};
//not using this api?
export const getTopicById = (topicId) => {
  return api.get(`${API_VERSION}/${GET_TOPIC_BY_ID(topicId)}`).then(getData);
};

export const uploadTopicImage = (file, makeId) => {
  return api.post(`${API_VERSION}/${UPLOAD_INFO_IMG(makeId)}`, file).then(getData);
};
