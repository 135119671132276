import RootModal from 'components/RootModal/RootModal';
import ActionButton from 'pages/CMSPage/components/ActionButton/ActionButton';
import { useTournamentContext } from 'pages/CMSPage/contexts/tournamentContext';
import useDeleteTournament from 'pages/CMSPage/TournamentEditor/hooks/useDeleteTournament';
import useEditTournament from 'pages/CMSPage/TournamentEditor/hooks/useEditTournament';
import { useState } from 'react';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import * as S from './EditButtons.style';

const EditButtons = () => {
  const [isModalOpen, setModal] = useState(false);
  const { deleteTournament } = useDeleteTournament();
  const { editTournament } = useEditTournament();

  const { currentTournament } = useTournamentContext();
  const tournamentId = currentTournament?._id;

  return (
    <>
      {isModalOpen && (
        <RootModal visible={isModalOpen}>
          <ConfirmationModal
            title={'האם אתה רוצה שהטורניר יימחק?'}
            handleReject={() => setModal(false)}
            handleConfirm={() => deleteTournament(tournamentId)}
          />
        </RootModal>
      )}
      <S.ActionButtonsContainer>
        <ActionButton
          background={'red'}
          handleOnClick={(event) => {
            event.preventDefault();
            setModal(true);
          }}
          value={'סיים את הטורניר'}
        />
        <ActionButton background={'green'} value={'שמור את הטורניר'} disabled={false} />
      </S.ActionButtonsContainer>
    </>
  );
};

export default EditButtons;
