import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import TextArea from 'pages/CMSPage/components/inputTypes/TextArea/TextArea.js';
import Image from 'pages/CMSPage/components/inputTypes/Image/Image.js';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import useDraggableItems from '../../hooks/dragNdrop/useDraggableItems';
import DraggableItems from 'pages/CMSPage/components/DraggableItems/DraggableItems';
import PreviewMode from 'pages/CMSPage/components/PreviewMode/PreviewMode';
import * as S from './TopicEditor.style';
import EditModeLayout from '../../../../layouts/EditModeLayout/EditModeLayout';
import { useEditorContext } from '../../context/editorContext';
import cmsTopicExample from 'assets/icons/cmsTopicExample.png';
import useUpdateTopic from '../../hooks/CRUD/update/useUpdateTopic';
import usePublishTopic from '../../hooks/publish/usePublishTopic';
import { isObjectPropertiesFalsy } from 'pages/CMSPage/utils/helpers';
import useTopicEditing from './useTopicEditing';
import ActionButton from 'pages/CMSPage/components/ActionButton/ActionButton';

const TopicEditor = () => {
  const { currentSelectedData } = useCmsContext();
  const { publish } = currentSelectedData;
  const { draggableList } = useEditorContext();
  const { topicName, topicRoute, topicDescription, teaser, discordLink } =
    currentSelectedData;
  const { onDragEnd, onItemClick } = useDraggableItems();
  const { updateTopic } = useUpdateTopic();
  const { publishTopic, unPublishTopic } = usePublishTopic();
  const { onTextChange, errors, onImageUpload } = useTopicEditing();
  const draggableItemsProps = {
    draggableList: draggableList,
    onDragEnd: onDragEnd,
    onItemClick: onItemClick,
    shape: 'square',
    direction: 'vertical',
  };

  const topicNameProps = {
    type: 'text',
    label: 'שם הנושא',
    value: topicName,
    onChange: (event) => onTextChange('topicName', event),
    alert: errors?.topicName,
  };
  const topicRouteProps = {
    type: 'text',
    label: 'שם הroute',
    value: topicRoute,
    onChange: (event) => onTextChange('topicRoute', event),
    alert: errors?.topicRoute,
  };

  const topicDescriptionProps = {
    label: 'תקציר',
    value: topicDescription,
    onChange: (event) => onTextChange('topicDescription', event),
  };
  const discordLinkProps = {
    type: 'text',
    label: 'קישור לדיסקורד',
    value: discordLink,
    onChange: (event) => onTextChange('discordLink', event),
  };
  const teaserProps = {
    label: 'תמונת רקע',
    onChange: (file) => onImageUpload(file, 'teaser'),
    value: teaser,
  };

  const updateButtonProps = {
    disabled: isObjectPropertiesFalsy(errors),
    background: 'green',
    handleOnClick: updateTopic,
    value: 'עדכן',
  };
  const publishButtonProps = {
    background: 'orange',
    handleOnClick: publish ? unPublishTopic : publishTopic,
    value: publish ? 'הסר פרסום' : 'פרסם',
  };

  return (
    <EditModeLayout>
      {currentSelectedData && draggableList && (
        <S.Container>
          <S.DraggableItemsContainer>
            <DraggableItems {...draggableItemsProps} />
          </S.DraggableItemsContainer>
          <S.EditContent>
            <Input {...topicNameProps} />
            <Input {...topicRouteProps} />
            <TextArea {...topicDescriptionProps} />
            <Input {...discordLinkProps} />
            <Image {...teaserProps} />
          </S.EditContent>
          <S.PreviewContainer>
            <PreviewMode image={cmsTopicExample}>
              <ActionButton {...updateButtonProps} />
              <ActionButton {...publishButtonProps} />
            </PreviewMode>
          </S.PreviewContainer>
        </S.Container>
      )}
    </EditModeLayout>
  );
};

export default TopicEditor;
