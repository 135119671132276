import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

const ComponentLockContext = React.createContext();

const useLock = () => {
  const context = React.useContext(ComponentLockContext);
  if (!context) {
    throw new Error('useLock must be used within a ComponentLock Provider');
  }
  return context;
};

const LockProvider = (props) => {
  const [locking, setLocking] = useState(false);
  const components = useSelector((state) => state?.app.currentComponents);
  const currentIndex = useSelector((state) => state.app.currentIndex);
  const currentComponent = components && components[currentIndex];

  useEffect(() => {
    //special case to be unlocked always.
    if (props.notLocked) {
      return setLocking(false);
    }

    const { lock } = currentComponent;

    setLocking(lock || false);
  }, [props.notLocked, currentComponent]);

  const value = [locking, setLocking];

  return <ComponentLockContext.Provider value={value} {...props} />;
};

export { LockProvider, useLock };
