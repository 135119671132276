import * as S from './MakeStatus.style';
const MakeStatus = ({ status }) => {
  const backgroundColor =
    status === 'בתהליך' ? 'var(--color-white)' : 'var(--color-green)';

  const color =
    status === 'בתהליך' ? 'var(--color-light-charcoal)' : 'var(--color-white)';

  return (
    status && (
      <S.MakeStatus backgroundColor={backgroundColor} color={color}>
        {status}
      </S.MakeStatus>
    )
  );
};

export default MakeStatus;
