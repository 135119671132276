import styled from 'styled-components/macro';

export const TeaserWrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 0;
  width: 100%;
  overflow: hidden;
  flex: 0.85;
  video {
    aspect-ratio: 1/1;
    object-fit: cover;
  }
`;

export const Image = styled.img`
  min-height: 100%;
  width: 100%;
  aspect-ratio: 1/1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ playing }) => (playing ? -1 : 2)};
  object-fit: cover;
`;

export const FallBackImage = styled.img`
  min-height: 100%;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
`;
