import * as S from './TextList.style';

const TextList = (props) => {
  const { handleAddItem, label, children } = props;

  return (
    <S.Container>
      <S.Label>{label}</S.Label>
      {children}
      <S.PlusButton onClick={(event) => handleAddItem(event)} />;
    </S.Container>
  );
};

export default TextList;
