import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import Image from 'pages/CMSPage/components/inputTypes/Image/Image';
import * as S from './Submission.style';
import useHandleComponentData from 'pages/CMSPage/hooks/useHandleComponentData';
import ComponentOptions from 'pages/CMSPage/components/ComponentOptions/ComponentOptions';
import useImageUpload from 'pages/CMSPage/hooks/useImageUpload';
import RichTextEditor from 'pages/CMSPage/components/RichTextEditor/RichTextEditor';
const Submission = ({ component }) => {
  const { handleComponentDataObj } = useHandleComponentData({ component });
  const { uploadImage } = useImageUpload();
  const { data } = component;
  const { subTitle: text, title, attention, imgUrl } = data;
  const titleProps = {
    type: 'text',
    label: 'כותרת',
    value: title,
    onChange: (event) => handleComponentDataObj('title', event.target.value),
  };
  const imageProps = {
    label: 'תמונה',
    value: imgUrl,
    onChange: async (imageFile) => {
      const url = await uploadImage(imageFile);
      handleComponentDataObj('imgUrl', url);
    },
  };
  const attensionProps = {
    type: 'text',
    label: 'פעולה לקריאה',
    value: attention,
    onChange: (event) => handleComponentDataObj('attention', event.target.value),
  };

  const textProps = {
    label: 'טקסט',
    value: text,
    callback: (value) => handleComponentDataObj('subTitle', value),
    extraConfig: { uploader: null },
  };

  return (
    <S.Container>
      <S.Section>
        <ComponentOptions component={component} />
        <S.Wrapper>
          <Input {...titleProps} />
        </S.Wrapper>
        <S.Wrapper>
          <Image {...imageProps} />
        </S.Wrapper>
        <S.Wrapper>
          <Input {...attensionProps} />
        </S.Wrapper>
      </S.Section>
      <S.Section>
        <RichTextEditor {...textProps} />
      </S.Section>
    </S.Container>
  );
};

export default Submission;
