import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';
export const Container = styled.div`
  display: grid;
  /* grid-template-columns: repeat(3, minmax(200px, 1fr)); */
  gap: 4rem;
  margin-bottom: 15rem;
  flex: 1;

  /* grid-auto-rows: 47.5rem; */
  grid-template-columns: 1fr 1fr 1fr;
  /* overflow: hidden; */
  /* overflow: unset; */
`;
