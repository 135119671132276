import { API_VERSION } from 'apis/CONSTANTS';
import { api, getData } from 'apis/index';

//tracks

export const getTrackById = (trackId) => {
  return api.get(`${API_VERSION}/tracks/${trackId}/track`).then(getData);
};
export const createTrack = (data) => {
  return api.put(`${API_VERSION}/tracks/create`, data).then(getData);
};

export const updateTrackRequest = (trackId, data) => {
  return api.put(`${API_VERSION}/tracks/${trackId}/update`, data).then(getData);
};

export const deleteTrackRequest = (trackId) => {
  return api.put(`${API_VERSION}/tracks/${trackId}/delete`).then(getData);
};

export const updateTrackIndex = (trackId, data) => {
  return api.post(`${API_VERSION}/tracks/${trackId}/updateIndex`, data).then(getData);
};

//topics
export const createTopic = (trackId, data) => {
  return api.put(`${API_VERSION}/tracks/${trackId}/topics/create`, data).then(getData);
};

export const updateTopicRequest = (topicId, data) => {
  return api.put(`${API_VERSION}/tracks/topics/${topicId}/update`, data).then(getData);
};

export const deleteTopicRequest = (trackId, topicId) => {
  return api
    .delete(`${API_VERSION}/tracks/${trackId}/topics/${topicId}/delete`)
    .then(getData);
};

export const updateTopicIndex = (trackId, data) => {
  return api
    .post(`${API_VERSION}/tracks/${trackId}/topics/updateIndex`, data)
    .then(getData);
};

//makes
export const updateMakeRequest = (makeId, data) => {
  return api.put(`${API_VERSION}/tracks/makes/${makeId}/update`, data).then(getData);
};

export const deleteMakeRequest = (topicId, makeId) => {
  return api
    .delete(`${API_VERSION}/tracks/topics/${topicId}/makes/${makeId}/delete`)
    .then(getData);
};
export const createMake = (trackId, topicId, data) => {
  return api
    .put(`${API_VERSION}/tracks/${trackId}/topics/${topicId}/makes/create`, data)
    .then(getData);
};

export const updateMakeIndex = (topicId, makeId, data) => {
  return api
    .put(`${API_VERSION}/tracks/topics/${topicId}/makes/${makeId}/updateIndex`, data)
    .then(getData);
};

export const createComponent = (makeId, data) => {
  return api.put(`${API_VERSION}/makes/${makeId}/components/create`, data).then(getData);
};
export const getAllComponentsByMake = (makeId, userId) => {
  return api.get(`${API_VERSION}/makes/${makeId}/components/${userId}`).then(getData);
};
export const updateComponentIndex = (makeId, componentId, data) => {
  return api
    .post(`${API_VERSION}/makes/${makeId}/components/${componentId}/updateIndex`, data)
    .then(getData);
};

export const deleteComponentRequest = (makeId, componentId) => {
  return api
    .delete(`${API_VERSION}/makes/${makeId}/components/${componentId}/delete`)
    .then(getData);
};

export const updateComponentRequest = (componentId, data) => {
  return api
    .put(`${API_VERSION}/makes/components/${componentId}/update`, data)
    .then(getData);
};
export const getDiscussionComponentsByMakeId = (makeId) =>
  api.get(`${API_VERSION}/makes/${makeId}/components/discussion`).then(getData);
