import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentTrack: null,
  currentTopic: null,
  currentMake: null,
  currentComponents: null,
  currentIndex: null,
};

const appState = (state) => {
  return {
    track: state.app.currentTrack,
    topic: state.app.currentTopic,
    make: state.app.currentMake,
    components: state.app.currentComponents,
    currentIndex: state.app.currentIndex,
  };
};
export const getAppData = createSelector(appState, (appState) => appState);

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCurrentTrack: (state, action) => {
      state.currentTrack = action.payload;
    },
    setCurrentMake: (state, action) => {
      state.currentMake = action.payload;
    },
    setCurrentTopic: (state, action) => {
      state.currentTopic = action.payload;
    },
    setComponents: (state, action) => {
      state.currentComponents = action.payload;
    },

    setCurrentIndex: (state, action) => {
      state.currentIndex = action.payload;
    },
    resetStatesBesideTrack: (state) => {
      state.currentIndex = null;
      state.currentMake = null;
      state.currentTopic = null;
    },
    resetAppSlice: (state) => {
      state.currentTrack = null;
      state.currentTopic = null;
      state.currentMake = null;
      state.currentComponents = null;
      state.currentIndex = null;
    },
  },
});

export const {
  resetStatesBesideTrack,
  setCurrentTrack,
  setCurrentMake,
  setCurrentTopic,
  setComponents,
  setCurrentIndex,
  resetAppSlice,
} = appSlice.actions;

export default appSlice.reducer;
