import { addFacilitator, deleteFacilitator } from 'apis/CMS/tournaments';
import { useTournamentContext } from 'pages/CMSPage/contexts/tournamentContext';

const useFacilitatorFields = () => {
  const { setCurrentTournament, currentTournament } = useTournamentContext();
  const handleFacilitatorDelete = (index) => {
    const updatedFacilitator = [...currentTournament.facilitators];
    updatedFacilitator.splice(index, 1);
    setCurrentTournament((prev) => ({
      ...prev,
      facilitators: [...updatedFacilitator],
    }));
  };

  const handleFacilitatorEmailChange = (event, index) => {
    event.target.setCustomValidity('');
    const updatedFacilitator = [...currentTournament.facilitators];
    updatedFacilitator[index].email = event.target.value;
    setCurrentTournament((prev) => ({
      ...prev,
      facilitators: [...updatedFacilitator],
    }));
  };
  const handleFacilitatorNameChange = (event, index) => {
    event.target.setCustomValidity('');
    const updatedFacilitator = [...currentTournament.facilitators];
    updatedFacilitator[index].userName = event.target.value;
    setCurrentTournament((prev) => ({
      ...prev,
      facilitators: [...updatedFacilitator],
    }));
  };

  const createFacilitator = async (event) => {
    event.preventDefault();
    const { data } = await addFacilitator(currentTournament._id);
    const updatedFacilitator = [...currentTournament.facilitators];
    updatedFacilitator.push(data.facilitators[data.facilitators.length - 1]);
    setCurrentTournament((prev) => ({
      ...prev,
      facilitators: [...updatedFacilitator],
    }));
  };

  const removeFacilitator = async (facilitatorId, email) => {
    await deleteFacilitator(currentTournament._id, facilitatorId, {
      email,
    });
    const updatedTournament = { ...currentTournament };
    const facilitatorIndex = currentTournament.facilitators.findIndex(
      (facilitator) => facilitator._id === facilitatorId
    );
    updatedTournament.facilitators.splice(facilitatorIndex, 1);
    setCurrentTournament(updatedTournament);
  };

  return {
    handleFacilitatorDelete,
    createFacilitator,
    removeFacilitator,
    handleFacilitatorEmailChange,
    handleFacilitatorNameChange,
  };
};

export default useFacilitatorFields;
