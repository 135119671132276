export const squareStyle = (draggableStyle, currentItem, item) => ({
  width: '300px',
  minHeight: '3.5rem',
  whiteSpace: 'break-spaces',
  padding: '0.5rem',
  fontSize: '1.8rem',
  fontFamily:
    item._id === currentItem._id ? 'OpenSansHebrew ExtraBold' : 'OpenSansHebrew Regular',
  color: 'var(--color-charcoal-grey)',
  border: '0.1rem solid',
  borderColor:
    item._id === currentItem._id
      ? 'var(--color-charcoal-grey)'
      : 'var(----color-mid-grey)',
  borderRadius: '0.5rem',
  margin: '1rem 0',
  backgroundColor: 'white',
  ...draggableStyle,
});
export const circleStyle = (draggableStyle, currentItem, item) => ({
  textAlign: 'center',
  width: currentItem._id === item._id ? '3rem' : '2.2rem',
  height: currentItem._id === item._id ? '3rem' : '2.2rem',
  borderRadius: currentItem._id === item._id ? '3rem' : '2.2rem',
  backgroundColor:
    currentItem._id === item._id ? 'var(--color-green)' : 'var(--color-charcoal-grey)',
  fontSize: currentItem._id === item._id ? '1.6rem' : '1rem',
  color: 'white',
  userSelect: 'none',
  fontFamily: "'OpenSansHebrew Bold' ,serif",
  cursor: currentItem._id === item._id ? 'default' : 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...draggableStyle,
});
