import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useState } from 'react';
import trackSchema from '../../validation/schemas/trackSchema';

const useTrackEditing = () => {
  const [errors, setErrors] = useState({ trackName: '', trackRoute: '' });
  const { handleData } = useCmsContext();

  const onTextChange = (field, event) => {
    const value = event.target.value.toLowerCase();
    validateField(field, value);
    handleData(field, value);
  };

  const validateField = (field, value) => {
    const { error } = trackSchema.extract(field).validate(value);
    if (error) setErrors((prev) => ({ ...prev, [field]: error.message }));
    else setErrors((prev) => ({ ...prev, [field]: '' }));
  };
  return { errors, onTextChange };
};

export default useTrackEditing;
