import AvatarBarContainer from 'components/AvatarBar';
import { useSelector } from 'react-redux';
import TopicList from './components/TopicList/TopicList';
import * as S from './SubNav.style';

const SubNav = ({ topics }) => {
  const trackId = useSelector((state) => state.app?.currentTrack?._id);
  const isMobile = useSelector((state) => state.ui?.isMobile);

  return (
    <S.Container isMobile={isMobile}>
      <AvatarBarContainer trackId={trackId} />
      <TopicList topics={topics} />
    </S.Container>
  );
};

export default SubNav;
