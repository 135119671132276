import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
`;
export const EditContent = styled.div`
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
`;
export const DraggableItemsContainer = styled.div``;
export const PreviewContainer = styled.div`
  margin: 1rem 0;
  overflow: hidden;
`;

export const Link = styled.a`
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  font-size: 1.8rem;
  font-family: ${primaryFont.bold};
  margin: 2rem auto;
  display: block;
  text-align: center;
  overflow: auto;
`;

export const Title = styled.h2`
  font-size: 2.5rem;
  font-family: ${primaryFont.bold};
`;

export const QuizContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding-top: 5rem;
`;
