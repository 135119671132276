import * as S from './Circle.style';

const Circle = ({ icon, isSelected, handleOnClick }) => {
  return (
    <S.ItemContainer onClick={handleOnClick}>
      <S.ItemIconWrapper selected={isSelected}>
        <S.ItemIcon src={icon} alt={'ItemIcon'} />
      </S.ItemIconWrapper>
    </S.ItemContainer>
  );
};

export default Circle;
