import { createQuizMake, deleteQuizMake } from 'apis/CMS/quizMake';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useErrorHandler } from 'react-error-boundary';

const useDeleteQuizMake = () => {
  const handleError = useErrorHandler();
  const { setLoading, activeAlert } = useCmsContext();
  const deleteQuiz = async (makeId) => {
    setLoading(true);
    try {
      const { data: quizMake } = await deleteQuizMake(makeId);
      setLoading(false);
      activeAlert({ title: 'הצלחנו', text: `<p>מייק שאלון נמחק בהצלחה</p>` });

      return quizMake;
    } catch (error) {
      handleError(error);
    }
  };
  return { deleteQuiz };
};

export default useDeleteQuizMake;
