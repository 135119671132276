import { deleteComponentRequest, getAllComponentsByMake } from 'apis/CMS/editor';
import { getMakesByTopic } from 'apis/makes';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

const useDeleteComponent = () => {
  const handleError = useErrorHandler();
  const userId = useSelector((state) => state.auth?.user?._id);
  const { choice, setMakes, setComponents } = useEditorContext();
  const { topic, make, component } = choice;
  const topicId = topic?._id;
  const makeId = make?._id;
  const componentId = component?._id;

  const deleteComponent = async () => {
    try {
      await deleteComponentRequest(makeId, componentId);
      const { data: makeData } = await getMakesByTopic(topicId, userId);
      setMakes(makeData);
      const { data: componentData } = await getAllComponentsByMake(makeId, userId);
      setComponents(componentData);
    } catch (error) {
      handleError(error);
    }
  };
  return { deleteComponent };
};

export default useDeleteComponent;
