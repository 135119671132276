import * as Sentry from '@sentry/react';
const isLocal = process.env.NODE_ENV === 'development';

export function initSentry() {
  if (isLocal) {
    return;
  }
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_BUILD_ENV,
  });
}

export function logError(error, errorInfo = null) {
  if (isLocal) {
    return;
  }
}
