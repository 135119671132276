import { addClass, deleteClass } from 'apis/CMS/tournaments';
import { useTournamentContext } from 'pages/CMSPage/contexts/tournamentContext';

const useClassFields = () => {
  const { setCurrentTournament, currentTournament } = useTournamentContext();

  const handleClassNameChange = (event, index) => {
    event.preventDefault();
    event.target.setCustomValidity('');
    const updatedClasses = [...currentTournament.classrooms];
    updatedClasses[index].className = event.target.value;
    setCurrentTournament((prev) => ({ ...prev, classrooms: updatedClasses }));
  };
  const createClass = async (event) => {
    event.preventDefault();
    const { data } = await addClass({ tournament: currentTournament });
    const updatedClassrooms = [...currentTournament.classrooms];
    updatedClassrooms.push(data.classrooms[data.classrooms.length - 1]);
    setCurrentTournament((prev) => ({
      ...prev,
      classrooms: [...updatedClassrooms],
    }));
  };

  const removeClass = async (classId) => {
    await deleteClass(currentTournament._id, classId);
    const updatedTournament = { ...currentTournament };
    const classIndex = currentTournament.classrooms.findIndex(
      (classroom) => classroom._id === classId
    );
    updatedTournament.classrooms.splice(classIndex, 1);
    setCurrentTournament(updatedTournament);
  };
  return { removeClass, createClass, handleClassNameChange };
};

export default useClassFields;
