import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';

export const Container = styled.div`
  display: flex;
  width: 85%;
  margin: 0 auto;
  row-gap: 2rem;
  padding: 5rem 0 3rem 0;
`;
export const PreviewContainer = styled.div`
  flex: 0.3;
`;
export const EditContent = styled.div`
  flex: 0.7;
  display: flex;
  flex-direction: column;
  row-gap: 5rem;
`;
export const DraggableItemsContainer = styled.div`
  background-color: rgb(230, 230, 230);
`;

export const Link = styled.a`
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  font-size: 1.8rem;
  font-family: ${primaryFont.bold};
  margin: 2rem auto;
  display: block;
  text-align: center;
  overflow: auto;
`;
