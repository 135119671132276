import { useTournamentContext } from 'pages/CMSPage/contexts/tournamentContext';

const useEditingFields = () => {
  const { setCurrentTournament, currentTournament } = useTournamentContext();

  const handleEndDate = (event) => {
    const updatedTournament = { ...currentTournament };
    const date = event.target.value;
    updatedTournament.endDate = date;
    setCurrentTournament(updatedTournament);
  };

  const handleStartDate = (event) => {
    const updatedTournament = { ...currentTournament };
    const date = event.target.value;
    updatedTournament.startDate = date;
    setCurrentTournament(updatedTournament);
  };
  const handleTournamentNameChange = (event) => {
    event.preventDefault();
    event.target.setCustomValidity('');
    const updatedTournament = { ...currentTournament };
    updatedTournament.tournamentName = event.target.value;
    setCurrentTournament(updatedTournament);
  };

  return {
    handleTournamentNameChange,
    handleStartDate,
    handleEndDate,
  };
};

export default useEditingFields;
