import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import React from 'react';
import useFeedbackHandler from '../../../../../../hooks/useFeedbackHandler';
import * as S from './CorrectFeedback.style';
const CorrectFeedback = ({ question }) => {
  const { correctFeedback } = question;
  const { handleCorrectFeedbackChange } = useFeedbackHandler();
  return (
    <S.Wrapper>
      <Input
        type={'text'}
        value={correctFeedback}
        onChange={(event) => handleCorrectFeedbackChange(event.target.value, question)}
      />
    </S.Wrapper>
  );
};

export default CorrectFeedback;
