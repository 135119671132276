import RichTextEditor from 'pages/CMSPage/components/RichTextEditor/RichTextEditor';
import useHandleComponentData from 'pages/CMSPage/hooks/useHandleComponentData';
import ComponentOptions from '../../ComponentOptions/ComponentOptions';
import Input from '../../inputTypes/Input/Input';
import * as S from './Text.style';
import useTextEditor from './useTextEditor';
const Text = ({ component }) => {
  const { handleComponentDataObj } = useHandleComponentData();
  const { data } = component;
  const { text, title } = data;

  //upload images to s3 and set images src to the s3 response
  useTextEditor(text);
  const titleProps = {
    type: 'text',
    label: 'כותרת',
    value: title,
    onChange: (event) => handleComponentDataObj('title', event.target.value),
  };
  const richTextEditorProps = {
    label: 'תוכן נוסף',
    value: text,
    callback: (val) => handleComponentDataObj('text', val),
  };
  return (
    <S.Container>
      <S.Section>
        <ComponentOptions component={component} />
        <S.TextInputWrapper>
          <Input {...titleProps} />
        </S.TextInputWrapper>
      </S.Section>
      <S.Section>
        <RichTextEditor {...richTextEditorProps} />
      </S.Section>
    </S.Container>
  );
};

export default Text;
