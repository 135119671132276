import { updateTopicRequest } from 'apis/CMS/editor';
import { getTopics } from 'apis/topics';
import { getTracks } from 'apis/tracks';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

const useUpdateTopic = () => {
  const handleError = useErrorHandler();
  const userId = useSelector((state) => state.auth.user?._id);
  const { choice, setTopics, setTracks } = useEditorContext();
  const { currentSelectedData, activeAlert } = useCmsContext();
  const { track, topic } = choice;
  const trackId = track?._id;
  const topicId = topic?._id;

  const updateTopic = async () => {
    try {
      const res = await updateTopicRequest(topicId, currentSelectedData);
      const { data: fetchTopics } = await getTopics(trackId, userId);
      setTopics(fetchTopics);
      const { data: fetchTracks } = await getTracks(userId);
      setTracks(fetchTracks);
      activeAlert({ title: 'הצלחנו', text: `<p>הטופיק עודכן בהצלחה</p>` });
      return res.data;
    } catch (error) {
      handleError(error);
    }
  };
  return { updateTopic };
};

export default useUpdateTopic;
