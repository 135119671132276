export const CONTENT_CREATORS = [
  {
    icon: 'https://make-creators.s3.us-east-2.amazonaws.com/Ask+Dani.png',
    name: 'אסק דני',
  },
  {
    icon: 'https://make-creators.s3.us-east-2.amazonaws.com/%D7%A9%D7%92%D7%99%D7%90+%D7%91%D7%A8%D7%99%D7%99%D7%98%D7%A0%D7%A8.jpg',
    name: 'שגיא ברייטנר',
  },
  {
    icon: 'https://make-creators.s3.us-east-2.amazonaws.com/%D7%A0%D7%95%D7%A2%D7%9D+%D7%A4%D7%99%D7%A8%D7%95%D7%96.jpeg',
    name: 'נועם פירוז',
  },
  {
    icon: 'https://make-creators.s3.us-east-2.amazonaws.com/%D7%9C%D7%99%D7%91%D7%A0%D7%AA+%D7%90%D7%95%D7%A8.png',
    name: 'ליבנת אור',
  },
  {
    icon: 'https://make-creators.s3.us-east-2.amazonaws.com/%D7%9B%D7%9C%D7%99%D7%A4%D7%99.jpeg',
    name: 'כליפי',
  },
  {
    icon: 'https://make-creators.s3.us-east-2.amazonaws.com/%D7%99%D7%95%D7%91%D7%9C+%D7%99%D7%95%D7%A9%D7%94.png',
    name: 'יובל יושה',
  },
  {
    icon: 'https://make-creators.s3.us-east-2.amazonaws.com/%D7%99%D7%95%D7%91%D7%9C+%D7%90%D7%9C%D7%95%D7%A0%D7%99.png',
    name: 'יובל אלוני',
  },
  {
    icon: 'https://make-creators.s3.us-east-2.amazonaws.com/%D7%90%D7%A1%D7%A3+%D7%A9%D7%A0%D7%94%D7%91.jpeg',
    name: 'אסף שנהב',
  },
  {
    icon: 'https://make-creators.s3.us-east-2.amazonaws.com/%D7%90%D7%9C%D7%9B%D7%A1+%D7%91%D7%9F+%D7%90%D7%A8%D7%99.jpeg',
    name: 'אלכס בן ארי',
  },
  {
    icon: 'https://make-creators.s3.us-east-2.amazonaws.com/%D7%90%D7%95%D7%A8+%D7%91%D7%95%D7%98%D7%91%D7%95%D7%9C.jpeg',
    name: 'אור בוטבול',
  },
  {
    icon: 'https://make-creators.s3.us-east-2.amazonaws.com/%D7%90%D7%94%D7%93+%D7%90%D7%95%D7%A8%D7%95%D7%9F.jpeg',
    name: 'אוהד',
  },
];
