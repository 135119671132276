import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';

export const Title = styled.p`
  font-size: 2rem;
  font-family: ${primaryFont.bold};
`;
export const TitleContainer = styled.div`
  display: flex;
  margin: 2rem 0 1rem 0;
  width: 50%;
  align-items: center;
  justify-content: space-between;
`;
export const AddButton = styled.button`
  font-family: ${primaryFont.bold};
  border-radius: 1rem;
  width: 50%;
  text-align: center;
  background: var(--color-charcoal);
  color: white;
  cursor: pointer;
  font-size: 3rem;
`;

export const Container = styled.div`
  width: 100%;
`;

export const AlertMessage = styled.p`
  border-radius: 3px;
  width: 52rem;
  margin: 1rem 0;
  border: 1px solid rgb(180, 180, 180);
  background-color: rgb(252, 248, 227);
  color: rgb(185, 74, 72);
  display: block;
  font-size: 1.8rem;
  padding: 1rem 0.5rem;
  font-family: ${primaryFont.bold};
`;
