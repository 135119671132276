import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const Container = styled.div`
  display: flex;
  color: var(--color-charcoal-grey);
  justify-content: unset;
  margin-bottom: 1.5rem;
  padding-right: 2rem;

  @media ${device.tablet} {
    justify-content: center;
    margin-bottom: 3.5rem;
  }
`;
