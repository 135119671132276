import { editTournamentById, getAllTournaments } from 'apis/CMS/tournaments';
import { useTournamentContext } from 'pages/CMSPage/contexts/tournamentContext';
import { useErrorHandler } from 'react-error-boundary';
import { customErrorMsg } from 'utils/helpers';

const useEditTournament = () => {
  const handleError = useErrorHandler();
  const { setTournaments, setIsLoading, setCurrentTournament, activeAlert } =
    useTournamentContext();
  const editTournament = async (tournamentId, data) => {
    try {
      const { data: updatedTournament } = await editTournamentById(tournamentId, data);
      setIsLoading(true);
      const { data: allTournaments } = await getAllTournaments();
      setTournaments(allTournaments);
      setCurrentTournament(updatedTournament);
      activeAlert({ title: 'הצלחנו', text: `<p>הטורניר עודכן בהצלחה</p>` });
      setIsLoading(false);
    } catch (error) {
      return handleError(customErrorMsg('editTournament', error));
    }
  };
  return { editTournament };
};

export default useEditTournament;
