import styled from 'styled-components';
import { DefaultLabelStyle } from '../../GlobalStyle/Global.style';

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 1rem;
`;

export const FileContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  position: relative;
`;

export const DeleteIcon = styled.div`
  width: 2.6rem;
  height: 2.6rem;
  background-color: ${({ color }) => color};
  display: flex;
  color: var(--color-red);
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
`;
export const SwitchIcon = styled.div`
  width: 2.6rem;
  height: 2.6rem;
  background-color: ${({ color }) => color};
  display: flex;
  padding: 0.5rem;
  color: var(--color-blue);
  border-radius: 0.5rem;
  cursor: pointer;
`;

export const ChangeFileContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 1rem;
  right: 1rem;
  height: 2.6rem;
  width: 5.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ImageUploadIcon = styled.img`
  margin: 0 auto;
`;
export const ImageUploadText = styled.p`
  margin: 1rem auto;
`;

export const FileWrapper = styled.button`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 10rem;
  color: var(--color-grey);
  background-color: white;
  border: 0.1rem solid;
  border-color: var(----color-mid-grey);
  border-radius: 0.5rem;
`;

export const hiddenFileStyle = {
  display: 'none',
};

export const Label = styled(DefaultLabelStyle)``;
