import useQuery from 'hooks/useQuery';
import Circle from '../Circle/Circle';
import * as S from './NavigationButtons.style';
import leaderboard from 'assets/icons/leaderboard.svg';
import myMakes from 'assets/icons/my_makes.svg';

const NavigationButtons = () => {
  const { getQuery, mutateQuery } = useQuery();
  const mode = getQuery('mode');

  return (
    <S.Container>
      <Circle
        icon={myMakes}
        handleOnClick={() => mutateQuery('mode', 'editor')}
        isSelected={mode !== 'tournament'}
      />
      <Circle
        icon={leaderboard}
        handleOnClick={() => mutateQuery('mode', 'tournament')}
        isSelected={mode === 'tournament'}
      />
    </S.Container>
  );
};

export default NavigationButtons;
