import CustomCheckbox from 'pages/CMSPage/components/CustomCheckbox/CustomCheckbox';
import { useTournamentContext } from 'pages/CMSPage/contexts/tournamentContext';
import * as S from './AccordionItems.style';
import arrow from 'assets/icons/selectArrowDark.svg';
const AccordionItems = ({ openIndexes, handleItemClick }) => {
  const { currentTournament, setCurrentTournament, facilitatorMakes } =
    useTournamentContext();

  const checkboxStyle = {
    height: '1rem',
    width: '1rem',
  };
  const handleMakeClick = (event, make) => {
    event.preventDefault();
    const updatedTournament = { ...currentTournament };
    const makeIndex = updatedTournament.makes.findIndex((m) => m === make._id);
    if (makeIndex !== -1) {
      updatedTournament.makes.splice(makeIndex, 1);
      setCurrentTournament(updatedTournament);
    } else {
      updatedTournament.makes.push(make._id);
      setCurrentTournament(updatedTournament);
    }
  };
  const renderMakes = () => {
    return facilitatorMakes.map((make) => {
      return (
        <S.Make onClick={(event) => handleMakeClick(event, make)}>
          {make.makeName}
          <CustomCheckbox
            customStyle={checkboxStyle}
            checked={currentTournament.makes?.find((m) => m === make._id)}
          />
        </S.Make>
      );
    });
  };

  const items = [{ title: 'בחר תוכן', contents: renderMakes() }];

  const renderContent = () => {
    return (
      <S.AccordionWrapper>
        {items.map((item, index) => (
          <S.AccordionItem key={item.title}>
            <S.AccordionButton
              isOpen={openIndexes.includes(index)}
              onClick={(event) => {
                event.preventDefault();
                handleItemClick(index);
              }}
            >
              {item.title}
              <S.Arrow src={arrow} isOpen={openIndexes.includes(index)} />
            </S.AccordionButton>
            {openIndexes.includes(index) && (
              <S.AccordionContents>{item.contents}</S.AccordionContents>
            )}
          </S.AccordionItem>
        ))}
      </S.AccordionWrapper>
    );
  };
  return <>{renderContent()}</>;
};

export default AccordionItems;
