import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEffect, useState } from 'react';

const useComponentHandler = (componentTypes) => {
  const { handleData, setMode, currentSelectedData } = useCmsContext();
  const [currentComponent, setCurrentComponent] = useState();
  //when changing component type reset data object
  useEffect(() => {
    if (currentComponent?._id !== currentSelectedData?._id) {
      setCurrentComponent(currentSelectedData);
    }
    if (
      currentComponent?._id === currentSelectedData?._id &&
      currentSelectedData?.type !== currentComponent?.type
    ) {
      handleData('data', {});
      setCurrentComponent(currentSelectedData);
    }
  }, [currentSelectedData, currentComponent]);

  const changeType = (selectedVal) => {
    Object.entries(componentTypes).forEach((entry) => {
      const [key] = entry;
      if (key === currentSelectedData.type) return;
      if (key === selectedVal) {
        handleData('type', key);
        setMode('component');
      }
    });
  };

  return { changeType };
};

export default useComponentHandler;
