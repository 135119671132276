import useImageUpload from 'pages/CMSPage/hooks/useImageUpload';
import useQuestionsObjHandler from './useQuestionsObjHandler';

const useFeedbackHandler = () => {
  const { handeUpdatesInQuestionsObj } = useQuestionsObjHandler();
  const { uploadImage } = useImageUpload();
  const handleCorrectFeedbackChange = (newValue, question) => {
    question.correctFeedback = newValue;
    handeUpdatesInQuestionsObj(question);
  };
  const handleFeedbackTextChange = (newValue, index, question) => {
    question.answers[index].feedback.text = newValue;
    handeUpdatesInQuestionsObj(question);
  };
  const handleWrongFeedbackTitleChange = (newValue, index, question) => {
    question.wrongFeedback[index].title = newValue;
    handeUpdatesInQuestionsObj(question);
  };
  const handleWrongFeedbackSubTitleChange = (newValue, index, question) => {
    question.wrongFeedback[index].subTitle = newValue;
    handeUpdatesInQuestionsObj(question);
  };
  const handleFeedbackImageChange = async (event, index, question) => {
    const url = await uploadImage(event);
    question.answers[index].feedback.imageUrl = url;
    handeUpdatesInQuestionsObj(question);
  };

  return {
    handleWrongFeedbackSubTitleChange,
    handleWrongFeedbackTitleChange,
    handleCorrectFeedbackChange,
    handleFeedbackTextChange,
    handleFeedbackImageChange,
  };
};

export default useFeedbackHandler;
