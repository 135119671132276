import { updateComponentRequest } from 'apis/CMS/editor';
import { getFacilitatorComponents } from 'apis/CMS/facilitator';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useFacilitatorContext } from '../../../context/useFacilitatorContext';

const useUpdateComponent = () => {
  const { choice, setComponents } = useFacilitatorContext();
  const { make } = choice;
  const makeId = make?._id;
  const { currentSelectedData } = useCmsContext();
  const { _id: componentId } = currentSelectedData;

  const updateComponent = async () => {
    const { data: updatedComponent } = await updateComponentRequest(
      componentId,
      currentSelectedData
    );
    const { data: fetchComponents } = await getFacilitatorComponents(makeId);
    setComponents(fetchComponents);
    return updatedComponent;
  };
  return { updateComponent };
};

export default useUpdateComponent;
