import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserDetails, updateUser } from 'apis/users';

const initialState = {
  isLoggedIn: false,
  user: null,
  currentRequestId: '',
  loading: 'fin',
  error: '',
  signUpWith: '',
  cameFromURL: false,
};
export const fetchDiscordToken = createAsyncThunk(
  'auth/fetchDiscordToken',
  async (data, { rejectWithValue }) => {
    const { code } = data;
    try {
      const oauthResult = await fetch('https://discord.com/api/oauth2/token', {
        method: 'POST',
        body: new URLSearchParams({
          client_id: process.env.REACT_APP_DISCORD_CLIENT_ID,
          client_secret: process.env.REACT_APP_DISCORD_CLIENT_SECRET,
          code,
          grant_type: 'authorization_code',
          redirect_uri: process.env.REACT_APP_REDIRECT_URL,
          scope: 'identify email',
        }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      const { access_token } = await oauthResult.json();
      return { access_token };
    } catch (error) {
      return rejectWithValue({}, error);
    }
  }
);
export const fetchUserDataDiscord = createAsyncThunk(
  'auth/fetchUserDiscord',
  async (token, { rejectWithValue, getState }) => {
    try {
      const response = await fetch('https://discord.com/api/users/@me', {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      const {
        id: discordId,
        avatar: userAvatar,
        banner,
        banner_color: bannerColor,
        username: userName,
        email,
      } = await response.json();
      return { discordId, userAvatar, banner, bannerColor, userName, email, token };
    } catch (error) {
      return rejectWithValue({}, error);
    }
  }
);
export const fetchUserDataGoogle = createAsyncThunk(
  'auth/fetchUserGoogle',
  async (token, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${token}`
      );
      const {
        id: googleId,
        picture: userAvatar,
        name: userName,
        email,
      } = await response.json();
      return { googleId, userAvatar, userName, email, token };
    } catch (error) {
      return rejectWithValue({}, error);
    }
  }
);
export const getUser = createAsyncThunk(
  'auth/getUserDetails',
  async (userId, { rejectWithValue }) => {
    try {
      const res = await getUserDetails(userId);

      return res?.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);
export const updateUserDb = createAsyncThunk(
  'auth/updateUser',
  async (_, { rejectWithValue, getState }) => {
    const { userName, userAvatar, email, discordId, googleId, token } =
      getState().auth.user;
    const anonymousId = localStorage.getItem('makes_anonymous_id');

    let tokens = [];
    tokens.push(token);
    try {
      if (discordId) {
        const res = await updateUser(
          { discordId },
          { userName, userAvatar, email, tokens }
        );
        return res?.data;
      } else if (googleId) {
        const res = await updateUser(
          { googleId },
          { userName, userAvatar, email, tokens }
        );
        return res?.data;
      } else if (anonymousId) {
        const res = await updateUser({ anonymousId }, {});
        return res?.data;
      }
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },

    logout: (state) => {
      return (state = { ...initialState });
    },
    updateUserStudentId: (state, action) => {
      state.user.metaStudentId = action.payload;
    },
    updateXp: (state, { payload }) => {
      state.user.xp.currentXp = payload.currentXp;
      state.user.xp.previousXp = payload.previousXp;
    },
    updateRank: (state, { payload }) => {
      state.user.rank = payload;
    },
    updatePoints: (state, { payload }) => {
      state.user.points.currentPoints = payload.currentPoints;
      state.user.points.previousPoints = payload.previousPoints;
    },
    updateUserProp: (state, { payload }) => {
      state.user.gender = payload.gender;
      state.user.city = payload.infoLocation;
      state.user.grade = payload.grade;
      state.user.mathUnits = payload.mathUnits;
      state.user.userName = payload.userName;
      state.user.tournaments = payload.tournaments;
    },
    updateUserPreferences: (state, action) => {
      state.user.preferences = action.payload;
    },
    updateUserActiveTournament: (state, { payload }) => {
      state.user.activeTournament = payload.activeTournament;
    },
    fetchToken: (state, { payload }) => {
      state.token = payload.access_token;
      state.tokenType = 'Bearer';
      state.loading = 'fin';
      state.currentRequestId = '';
    },
    setSignUpWith: (state, { payload }) => {
      state.signUpWith = payload;
    },
    fetchUserDataAnonymous: (state, { payload }) => {
      state.user = { anonymousId: payload };
    },
    setCameFromURL: (state, { payload }) => {
      state.cameFromURL = payload;
    },
  },
  extraReducers: {
    [getUser.fulfilled]: (state, { meta, payload }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        state.user = {
          ...payload,
          xp: { currentXp: payload.xp, previousXp: 0 },
          points: { currentPoints: payload.points, previousPoints: 0 },
          rank: '',
        };
        state.isLoggedIn = true;
        state.loading = 'fin';
        state.currentRequestId = '';
      }
    },
    [getUser.pending]: (state, { meta }) => {
      state.currentRequestId = meta;
      state.loading = 'pending';
    },
    [getUser.rejected]: (state, { meta, error }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        state.currentRequestId = meta;
        state.loading = 'fin';
        state.error = error;
        localStorage.removeItem('makes_userId');
      }
    },
    [fetchDiscordToken.fulfilled]: (state, { meta, payload }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        const { access_token } = payload;
        window.localStorage.setItem('discord_token', access_token);
        state.token = access_token;
        state.tokenType = 'Bearer';
        state.loading = 'fin';
        state.currentRequestId = '';
      }
    },
    [fetchDiscordToken.pending]: (state, { meta }) => {
      state.currentRequestId = meta;
      state.loading = 'pending';
    },
    [fetchDiscordToken.rejected]: (state, { meta, error }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        state.currentRequestId = meta;
        state.loading = 'fin';
        state.error = error;
      }
    },
    [fetchUserDataDiscord.fulfilled]: (state, { meta, payload }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        state.user = payload;
        state.isLoggedIn = true;
        state.loading = 'fin';
        state.currentRequestId = '';
      }
    },
    [fetchUserDataDiscord.pending]: (state, { meta }) => {
      state.currentRequestId = meta;
      state.loading = 'pending';
    },
    [fetchUserDataDiscord.rejected]: (state, { meta, error }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        state.currentRequestId = meta;
        state.loading = 'fin';
        state.error = error;
        state.isLoggedIn = false;
        state.user = null;
      }
    },
    [fetchUserDataGoogle.fulfilled]: (state, { meta, payload }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        state.user = payload;
        state.isLoggedIn = true;
        state.loading = 'fin';
        state.currentRequestId = '';
      }
    },
    [fetchUserDataGoogle.pending]: (state, { meta }) => {
      state.currentRequestId = meta;
      state.loading = 'pending';
    },
    [fetchUserDataGoogle.rejected]: (state, { meta, error }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        state.currentRequestId = meta;
        state.loading = 'fin';
        state.error = error;
        state.isLoggedIn = false;
        state.user = null;
      }
    },
    [updateUserDb.fulfilled]: (state, { meta, payload }) => {
      if (meta.requestId === state.currentRequestId.requestId && payload) {
        state.user = {
          ...payload,
          xp: { currentXp: payload.xp, previousXp: 0 },
          points: { currentPoints: payload.points, previousPoints: 0 },
          rank: '',
        };
        const anonymousId = payload?.anonymousId;
        state.isLoggedIn = anonymousId ? false : true;
        state.loading = 'fin';
        state.currentRequestId = '';
      }
    },
    [updateUserDb.pending]: (state, { meta }) => {
      state.currentRequestId = meta;
      state.loading = 'pending';
    },
    [updateUserDb.rejected]: (state, { meta, error }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        state.currentRequestId = meta;
        state.loading = 'fin';
        state.error = error;
        state.isLoggedIn = false;
        state.user = null;
      }
    },
  },
});

export const {
  login,
  logout,
  updateUserStudentId,
  updateUserProp,
  updateUserPreferences,
  fetchToken,
  fetchUserDataAnonymous,
  setSignUpWith,
  updateXp,
  updateUserActiveTournament,
  updateRank,
  setCameFromURL,
  updatePoints,
} = authSlice.actions;

export default authSlice.reducer;
