import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  column-gap: 3rem;
`;
export const Section = styled.div`
  width: 100%;
  row-gap: 2rem;
  display: flex;
  flex-direction: column;
`;
