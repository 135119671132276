import { lazy, Suspense } from 'react';

import Spinner from 'components/Spinner';
import { useParams, Route, Switch } from 'react-router-dom';

import PrivateRoute from './auth/PrivateRoute';
import {
  FACILITATOR_DASHBOARD,
  FACILITATOR_TOURNAMENT,
  LOGIN,
  ON_BOARDING,
  TOURNAMENT_LEADER_BOARD,
  FACILITATOR_TOURNAMENT_CLASS,
} from './CONSTANTS';
import { NotFound } from './NotFound';
import CMSPage from 'pages/CMSPage/CMSPage';
import DiscoveryPage from 'pages/DiscoveryPage/DiscoveryPage';
import FacilitatorRoute from './auth/FacilitatorRoute';
import AdminRoute from './auth/AdminRoute';

const OnBoardingPageContainer = lazy(() => import('pages/onBoardingPage'));
const OnBoardingPage = lazy(() => import('pages/onBoarding/OnBoarding'));
const ComponentPageContainer = lazy(() => import('pages/ComponentPage'));
const OfficialPageContainer = lazy(() => import('pages/OfficialPage'));
const MetaSigUpContainer = lazy(() => import('components/auth/meta'));
const InfoPageContainer = lazy(() => import('pages/InfoPage'));
const TournamentLeaderBoard = lazy(() => import('pages/TournamentLeaderBoardPage'));
const PrivacyPage = lazy(() =>
  import('pages/PrivacyAndTermsPage/components/PrivacyPage')
);
const TermsPage = lazy(() => import('pages/PrivacyAndTermsPage/components/TermsPage'));
const LoginPageContainer = lazy(() => import('components/auth'));
const ProfilePageContainer = lazy(() => import('pages/ProfilePage'));
const safari = lazy(() => import('pages/Safari'));
const FacilitatorMake = lazy(() => import('pages/FacilitatorMake/FacilitatorMake'));
const FacilitatorDashboard = lazy(() =>
  import('pages/FacilitatorDashboard/FacilitatorDashBoard')
);
const FacilitatorTournament = lazy(() =>
  import('pages/FacilitatorDashboard/features/Tournament/Tournament')
);
const FacilitatorTournamentClassRoom = lazy(() =>
  import(
    'pages/FacilitatorDashboard/features/Tournament/pages/ClassroomPage/ClassroomPage'
  )
);
const RouterConfig = (props) => {
  return (
    <Suspense fallback={<Spinner />}>
      {/* List all public routes here */}
      <Switch>
        <Route path="/onBoarding" component={OnBoardingPage} />
        <Route
          path="/track/:trackRoute/topic/:topicRoute/:makeRoute/quizMake/component/:componentId?"
          render={(routeProps) => <ComponentPageContainer {...props} {...routeProps} />}
        />
        <Route
          path="/track/:trackRoute/topic/:topicRoute/:makeRoute/component/:componentId?"
          render={(routeProps) => {
            // need to rerender component after summary page that will go to next make for the state to be reset. Need to change the key for resetting
            const { makeRoute } = routeProps.match.params;
            return <ComponentPageContainer key={makeRoute} {...props} {...routeProps} />;
          }}
        />

        <Route
          exact
          path={['/', '/track/:trackRoute/', '/track/:trackRoute/topic/:topicRoute']}
          render={(routeProps) => <DiscoveryPage {...props} {...routeProps} />}
        />
        <Route path="/facilitator/:makeRoute/:makeId" component={FacilitatorMake} />
        <Route
          path={ON_BOARDING}
          render={(routeProps) => <OnBoardingPageContainer {...props} {...routeProps} />}
        />
        <Route path="/metaSignup" component={MetaSigUpContainer} />
        <Route path="/info" component={InfoPageContainer} />
        <Route path="/safari" component={safari} />
        <Route path="/privacy" component={PrivacyPage} />
        <Route path="/terms" component={TermsPage} />
        <Route path="/about" component={OfficialPageContainer} />
        <Route path={LOGIN}>
          <LoginPageContainer />
        </Route>
        {/* List all admin routes here */}
        <AdminRoute path={['/CMS']}>
          <CMSPage />
        </AdminRoute>
        {/* List all facilitator routes here */}
        <FacilitatorRoute exact path={FACILITATOR_DASHBOARD}>
          <FacilitatorDashboard />
        </FacilitatorRoute>
        <FacilitatorRoute exact path={`${FACILITATOR_TOURNAMENT}`}>
          <FacilitatorTournament />
        </FacilitatorRoute>
        <FacilitatorRoute exact path={`${FACILITATOR_TOURNAMENT_CLASS}:classId`}>
          <FacilitatorTournamentClassRoom />
        </FacilitatorRoute>
        {/* List all private routes here */}
        <PrivateRoute exact path={'/profile/:profileOption'}>
          <ProfilePageContainer />
        </PrivateRoute>
        <PrivateRoute path={TOURNAMENT_LEADER_BOARD}>
          <TournamentLeaderBoard />
        </PrivateRoute>
        {/* List a generic 404-Not Found route here */}
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default RouterConfig;
