import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import useImageUpload from 'pages/CMSPage/hooks/useImageUpload';
import { useState } from 'react';
import topicSchema from '../../validation/schemas/topicSchema';

const useTopicEditing = () => {
  const [errors, setErrors] = useState({ topicName: '', topicRoute: '' });
  const { uploadImage } = useImageUpload();
  const { handleData } = useCmsContext();

  const onTextChange = (field, event) => {
    const value = event.target.value.toLowerCase();
    if (errors[field] !== undefined) validateField(field, value);
    handleData(field, value);
  };

  const onImageUpload = async (imageFile, field) => {
    const url = await uploadImage(imageFile);
    handleData(field, url);
  };

  const validateField = (field, value) => {
    const { error } = topicSchema.extract(field).validate(value);
    if (error) setErrors((prev) => ({ ...prev, [field]: error.message }));
    else setErrors((prev) => ({ ...prev, [field]: '' }));
  };
  return { errors, onTextChange, onImageUpload };
};

export default useTopicEditing;
