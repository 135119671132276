import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toggleHamburger } from 'services/redux/ui.slice';

import * as S from './hamburger.styles';

const Hamburger = ({ children }) => {
  const isHamburgerOpen = useSelector((state) => state.ui.toggleHamburger);
  const isMobile = useSelector((state) => state.ui.isMobile);
  const left = useSelector((state) => state.ui?.hamburgerIconsWrapperPosition?.left);
  const right = useSelector((state) => state.ui?.hamburgerIconsWrapperPosition?.right);

  const dispatch = useDispatch();

  const [showAnimation, setAnimation] = useState(false);

  const handleHamburgerClick = () => {
    if (!showAnimation) {
      setAnimation(true);
    }
    dispatch(toggleHamburger());
  };

  return (
    <>
      <S.HamburgerContainer
        left={left}
        right={right}
        isMobile={isMobile}
        key={isHamburgerOpen}
        isOpen={isHamburgerOpen}
        showAnimation={showAnimation}
        onClick={handleHamburgerClick}
      >
        <S.HandleBars
          isOpen={isHamburgerOpen}
          showAnimation={showAnimation}
        ></S.HandleBars>
      </S.HamburgerContainer>
      {isHamburgerOpen && <S.BackGroundOpacity isOpen={isHamburgerOpen} />}

      <S.NavigationContainer isOpen={isHamburgerOpen}>
        {children}
        <S.ClickArea
          isOpen={isHamburgerOpen}
          isMobile={isMobile}
          onClick={handleHamburgerClick}
        />
      </S.NavigationContainer>
    </>
  );
};

export default Hamburger;
