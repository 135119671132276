import Accordion from 'components/Accordion/Accordion';
import Selector from 'pages/CMSPage/components/inputTypes/Selector/Selector';
import { QUESTION_TYPE } from 'pages/CMSPage/Constants/component';
import React, { useState } from 'react';
import useQuestionHandler from '../../../../hooks/useQuestionHandler';
import Answers from './components/Answers/Answers';
import CorrectFeedback from './components/CorrectFeedback/CorrectFeedback';
import Feedback from './components/Feedback/Feedback';
import Question from './components/Question/Question';
import Story from './components/Story/Story';
import WrongFeedback from './components/WrongFeedback/WrongFeedback';
import * as S from './QuestionEditMode.style';
import arrow from 'assets/icons/selectArrow.svg';
//TODO refactor question type
//types now :
// text = 4 text answers
// image = 4 image answers
// twoOptions = 2 text answers

const QuestionEditMode = ({ question, editorType }) => {
  const { type } = question;
  const { handleChangeQuestionType } = useQuestionHandler();
  const accordion = [
    {
      contents: <Question question={question} />,
      title: 'השאלה',
    },
    { contents: <Answers question={question} />, title: 'תשובות' },
    { contents: <Feedback question={question} />, title: 'משוב' },
    {
      contents: <CorrectFeedback question={question} />,
      title: 'כותרת לתשובה נכונה',
    },
    {
      contents: <WrongFeedback question={question} />,
      title: 'לכותרות לתשובות שגויות',
    },
  ];
  const renderComponentsWithTopics = () => {
    if (editorType === 'guru') {
      accordion.unshift({
        contents: <Story question={question} />,
        title: 'הסיפור',
      });
    }
    return (
      <S.AccordionWrapper>
        <Accordion openIndexes={[]}>
          {({ openIndexes, handleItemClick }) => (
            <S.AccordionItemsWrapper>
              {accordion.map((item, index) => (
                <S.AccordionItem key={item.title}>
                  <S.AccordionButton
                    isOpen={openIndexes.includes(index)}
                    onClick={(event) => {
                      event.preventDefault();
                      handleItemClick(index);
                    }}
                  >
                    {item.title}
                    <S.Arrow src={arrow} isOpen={openIndexes.includes(index)} />
                  </S.AccordionButton>
                  {openIndexes.includes(index) && (
                    <S.AccordionContents>{item.contents}</S.AccordionContents>
                  )}
                </S.AccordionItem>
              ))}
            </S.AccordionItemsWrapper>
          )}
        </Accordion>
      </S.AccordionWrapper>
    );
  };

  return (
    <>
      <Selector
        options={[...Object.keys(QUESTION_TYPE).map((type) => QUESTION_TYPE[type])]}
        values={[...Object.keys(QUESTION_TYPE).map((type) => type)]}
        defaultOption={'בחר סוג שאלה'}
        value={type}
        label={'סוג השאלה'}
        onChange={(newType) => handleChangeQuestionType(newType, question)}
        whiteSpace={'break-spaces'}
      />
      {renderComponentsWithTopics()}
    </>
  );
};

export default QuestionEditMode;
