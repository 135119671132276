import { PERMISSIONS } from 'config/constants/constants';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { ROOT } from 'routes/CONSTANTS';

const AdminRoute = ({ children, ...rest }) => {
  const permission = useSelector((state) => state.auth.user.permission);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return permission === PERMISSIONS.ADMIN ? (
          children
        ) : (
          <Redirect to={{ pathname: ROOT, from: location }} />
        );
      }}
    />
  );
};

export default AdminRoute;
