import useScrollToSides from 'hooks/useScrollToSides';
import { useSelector } from 'react-redux';
import { NavLink, Link, useParams, useHistory } from 'react-router-dom';
import { getTopicRoute } from 'routes/helper';
import * as S from './Topic.style';

const Topic = ({ topic, topics, scrollToFunc }) => {
  const params = useParams();
  const history = useHistory();
  const { _id: topicId } = topic;
  const currentTopicId = useSelector((state) => state.app.currentTopic?._id);
  const trackRoute = useSelector((state) => state.app?.currentTrack?.trackRoute);
  const isMobile = useSelector((state) => state.ui.isMobile);
  const currentTopicIndex = topics?.findIndex((topic) => topic._id === currentTopicId);
  const index = topics?.findIndex((topic) => topic._id === topicId);
  const { ref } = useScrollToSides(
    currentTopicIndex,
    index,
    topics?.length,
    scrollToFunc
  );

  return (
    <Link
      ref={ref}
      to={{
        pathname: getTopicRoute(trackRoute, topic?.topicRoute),
        hash: !isMobile && '#' + topic._id,
      }}
      key={topic._id}
    >
      <S.Item selected={topic?.topicRoute === params.topicRoute}>
        <S.Text>{topic.topicName}</S.Text>
      </S.Item>
    </Link>
  );
};

export default Topic;
