import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GET_USER_ACTIVITIES } from 'apis/CONSTANTS';
import { RECOMMENDATION_ID } from 'config/constants/constants';
import { BASE_URL, API_VERSION } from '../CONSTANTS';
import {
  GET_AVATAR_USERS,
  GET_ALL_SCHOOLS,
  GET_RECOMMENDATION_TRACK,
  GET_CLASSES_BY_SCHOOL_NAME,
  GET_ALL_TRACKS,
  GET_ALL_TOPICS_BY_TRACK_ID,
  GET_ALL_MAKES_BY_TOPIC_ID,
  GET_USER_POINTS,
  DELETE_COMMENT,
  GET_USER_PROGRESS_BY_TOPIC_ID,
  GET_MAKE_BY_ID,
  GET_LEADER_BOARD,
  GET_MY_LEADERBOARD_POSITION,
  GET_PROGRESS_OF_USER_BY_USER_ID,
  UPDATE_PROGRESS_OF_USER,
  GET_USER_PROGRESS_BY_TRACK,
  GET_ALL_COMPONENTS_BY_MAKE_ID,
  GET_ALL_USER_MAKES,
  GET_MAKES_IN_PROGRESS,
  GET_POPULAR_MAKES,
  GET_NEW_MAKES,
  UPLOAD_MAKE_TO_DB,
  GET_RECOMMENDED_MAKES,
  UPDATE_USER_PROPERITES,
  GET_XP_LEVELS,
  UPDATE_USER_ACTIVITY,
  UPDATE_USER_XP,
  UPDATE_USER_POINTS,
  GET_USER_XP,
  GET_SCHOOL_BY_CLASS_ID,
  GET_ALL_COMMENTS,
  ADD_COMMENTS,
  GET_USER_PROGRESS_BY_USER_AND_MAKE_ID,
  GET_ALL_QUIZ_MAKE_COMPONENTS_BY_MAKE_ID,
  UPDATE_MAKE_STATUS_BY_CLASS_ID,
  GET_ALL_TOURNAMENTS,
  GET_ACTIVE_TOURNAMENTS,
  GET_TOURNAMENT_BY_ID,
  ADD_USER_TO_TOURNAMENT,
} from './constants';

export const makesApi = createApi({
  reducerPath: 'makesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}${API_VERSION}`,
    prepareHeaders: (headers) => {
      headers.set(
        'authorization',
        `Basic ${btoa('secret:' + process.env.REACT_APP_BASIC_AUTH_TOKEN)}`
      );
      return headers;
    },
  }),
  keepUnusedDataFor: 60 * 60,
  tagTypes: [
    'UserProgress',
    'xp',
    'points',
    'comments',
    'uploadMake',
    'userActivity',
    'tournament',
  ],
  endpoints: (builder) => ({
    getActivity: builder.query({
      query: (userId) => ({
        url: GET_USER_ACTIVITIES(userId),
        transformResponse: (response) => response.data,
      }),
      providesTags: ['userActivity', 'comments'],
    }),
    getAvatarUsersWithUserId: builder.query({
      query: ({ userId, trackId }) => ({
        url: GET_AVATAR_USERS(trackId),
        method: 'POST',
        body: userId,
      }),
      transformResponse: (response) => response.data,
    }),
    getMakeById: builder.query({
      query: ({ makeId, userId }) => GET_MAKE_BY_ID(makeId, userId),
      transformResponse: (response) => response.data,
    }),
    getAvatarUsers: builder.query({
      query: (trackId) => ({
        url: GET_AVATAR_USERS(trackId),
        method: 'POST',
        transform: (response) => response.data,
      }),
      transformResponse: (response) => response.data,
    }),
    getAllSchools: builder.query({
      query: (infoLocation) => GET_ALL_SCHOOLS(infoLocation),
      transformResponse: (response) => response.data,
    }),
    getClassesBySchoolName: builder.query({
      query: (schoolName) => GET_CLASSES_BY_SCHOOL_NAME(schoolName),
      transformResponse: (response) => response.data,
    }),
    getSchoolByClassId: builder.query({
      query: (infoLocation) => GET_SCHOOL_BY_CLASS_ID(infoLocation),
      transformResponse: (response) => response.data,
    }),
    getTournamentById: builder.query({
      query: (tournamentId) => GET_TOURNAMENT_BY_ID(tournamentId),
      transformResponse: (response) => response.data,
      providesTags: ['tournament'],
    }),
    getAllTracks: builder.query({
      query: (userId) => GET_ALL_TRACKS(userId),
      transformResponse: (response) => response.data,
    }),
    getAllTopicsByTrack: builder.query({
      query: ({ trackId, userId }) => GET_ALL_TOPICS_BY_TRACK_ID(trackId, userId),
      transformResponse: (response) => response.data,
    }),
    getAllMakesByTopic: builder.query({
      query: ({ topicId, userId }) => GET_ALL_MAKES_BY_TOPIC_ID(topicId, userId),
      transformResponse: (response) => response.data,
    }),

    getAllComments: builder.query({
      query: ({ componentId }) => GET_ALL_COMMENTS(componentId),
      providesTags: ['comments'],
      transformResponse: (response) => response.data,
    }),

    addComment: builder.mutation({
      query: ({ userId, componentId, makeId, comment, userAvatar, userName }) => ({
        url: ADD_COMMENTS(userId, componentId),
        method: 'POST',
        body: { makeId, comment, userAvatar, userName },
      }),
      invalidatesTags: ['comments'],
      transformResponse: (response) => response.data,
    }),
    deleteComment: builder.mutation({
      query: ({ userId, componentId, commentId }) => ({
        url: DELETE_COMMENT(userId, componentId, commentId),
        method: 'DELETE',
      }),
      invalidatesTags: ['comments'],
      transformResponse: (response) => response.data,
    }),
    getProgressByTrack: builder.query({
      query: ({ userId, trackId }) => GET_USER_PROGRESS_BY_TRACK(userId, trackId),
      providesTags: ['UserProgress'],
      transformResponse: (response) => response.data,
    }),
    getAllComponentsByMakeId: builder.query({
      //HERE
      query: ({ quizMake, makeId, userId }) =>
        quizMake
          ? GET_ALL_QUIZ_MAKE_COMPONENTS_BY_MAKE_ID(makeId, userId)
          : GET_ALL_COMPONENTS_BY_MAKE_ID(makeId, userId),
      transformResponse: (response) => {
        return response.data;
      },
    }),
    getAllUserMakes: builder.query({
      query: (userId) => GET_ALL_USER_MAKES(userId),
      transformResponse: (response) => response.data,
      providesTags: ['uploadMake'],
    }),
    getAllTournaments: builder.query({
      query: () => GET_ALL_TOURNAMENTS(),
      transformResponse: (response) => response.data,
      providesTags: ['tournament'],
    }),
    getActiveTournaments: builder.query({
      query: () => GET_ACTIVE_TOURNAMENTS(),
      transformResponse: (response) => response.data,
      providesTags: ['tournament'],
    }),
    getPopularMakes: builder.query({
      query: (userId) => GET_POPULAR_MAKES(userId),
      transformResponse: (response) => response.data,
    }),

    getRecommendedMakes: builder.query({
      query: (userId) => GET_RECOMMENDED_MAKES(userId),
      transformResponse: (response) => response.data,
    }),

    getNewMakes: builder.query({
      query: (userId) => GET_NEW_MAKES(userId),
      transformResponse: (response) => response.data,
    }),
    getMakesInProgress: builder.query({
      query: (userId) => GET_MAKES_IN_PROGRESS(userId),
      transformResponse: (response) => response.data,
    }),
    getAllRecommendationMakes: builder.query({
      async queryFn({ userId }, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const recommendations = await fetchWithBQ(GET_RECOMMENDATION_TRACK(userId));
          const trackId = recommendations.data.data._id;
          const topics = recommendations.data.data.topics;

          const recommendedMakes = await fetchWithBQ(GET_RECOMMENDED_MAKES(userId));
          const newMakes = await fetchWithBQ(GET_NEW_MAKES(userId));
          const popularMakes = await fetchWithBQ(GET_POPULAR_MAKES(userId));
          const makesInProgress = await fetchWithBQ(GET_MAKES_IN_PROGRESS(userId));
          const makes = [
            popularMakes.data.data,
            newMakes.data.data,
            makesInProgress.data.data,
            recommendedMakes.data.data,
          ];
          const modifyMakes = makes.map((makeCategory, i) => {
            return makeCategory.map((make) => {
              return {
                ...make,
                swiperTrackId: trackId,
                swiperTopicId: topics[i]._id,
              };
            });
          });

          return {
            data: {
              makes: modifyMakes.flat(),
              topicsData: topics,
            },
          };
        } catch (e) {
          return { error: e };
        }
      },
    }),
    getUserProgressByUserId: builder.query({
      query: ({ userId }) => GET_PROGRESS_OF_USER_BY_USER_ID(userId),
      providesTags: ['UserProgress'],
      transformResponse: (response) => response.data,
    }),
    getUserProgressByTopic: builder.query({
      query: ({ userId, topicId }) => GET_USER_PROGRESS_BY_TOPIC_ID(userId, topicId),
      providesTags: ['UserProgress'],
      transformResponse: (response) => response.data,
    }),
    getUserProgressByMake: builder.query({
      query: ({ userId, makeId }) =>
        GET_USER_PROGRESS_BY_USER_AND_MAKE_ID(userId, makeId),
      providesTags: ['UserProgress'],
      transformResponse: (response) => response.data,
    }),
    getUserPoints: builder.query({
      query: (userId) => GET_USER_POINTS(userId),
      providesTags: ['points'],
      transformResponse: (response) => response.data,
    }),
    getUserXP: builder.query({
      query: (userId) => GET_USER_XP(userId),
      providesTags: ['xp'],
      transformResponse: (response) => response.data,
    }),
    getUserXPLevels: builder.query({
      query: (userId) => GET_USER_XP(userId),
      providesTags: ['xp'],
      transformResponse: (response) => response.data,
    }),
    getLeaderBoardUsers: builder.query({
      query: ({ selectedOption, offset, isTournament, tournamentId, classId }) =>
        GET_LEADER_BOARD(selectedOption, offset, isTournament, tournamentId, classId),

      transformResponse: (response) => {
        return {
          data: response.data,
          pages: response.pages,
        };
      },
    }),
    getLeaderBoardPosition: builder.query({
      query: ({ userId, selectedOption, tournamentId, classId, isTournament }) =>
        GET_MY_LEADERBOARD_POSITION(
          userId,
          selectedOption,
          tournamentId,
          classId,
          isTournament
        ),
      transformResponse: (response) => {
        return response.data;
      },
    }),
    updateUserProgressByStar: builder.mutation({
      query: ({ userId, makeId, numOfStars }) => ({
        url: UPDATE_PROGRESS_OF_USER(userId, makeId),
        method: 'PATCH',
        body: { addToStar: numOfStars },
      }),
      invalidatesTags: ['UserProgress'],
      transformResponse: (response) => response.data,
    }),
    addUserToTournament: builder.mutation({
      query: ({ tournamentId, userId, classId, userTournaments }) => ({
        url: ADD_USER_TO_TOURNAMENT(tournamentId, classId, userId),
        method: 'PUT',
        invalidatesTags: ['tournament'],

        body: { userTournaments },
      }),
      transformResponse: (response) => response.data,
    }),
    uploadMake: builder.mutation({
      query: ({ fileData, userId, makeId }) => ({
        url: UPLOAD_MAKE_TO_DB(userId, makeId),
        method: 'POST',
        body: fileData,
        params: { userId, makeId },
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['uploadMake'],
    }),

    updateUserPoints: builder.mutation({
      query: ({ userId, body, trackId }) => ({
        url: UPDATE_USER_POINTS(userId, trackId),
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['points'],
      transformResponse: (response) => response.data,
    }),

    updateUserXp: builder.mutation({
      query: ({ userId, actionName }) => ({
        url: UPDATE_USER_XP(userId),
        method: 'PUT',
        body: { actionName },
      }),
      invalidatesTags: ['xp'],
      transformResponse: (response) => response.data,
    }),
    updateUserActivity: builder.mutation({
      query: ({ userId, data }) => ({
        url: UPDATE_USER_ACTIVITY(userId),
        method: 'PUT',
        body: { data },
      }),
      invalidatesTags: ['userActivity'],
      transformResponse: (response) => response.data,
    }),
    updateMakeStatusByClassId: builder.mutation({
      query: ({ tournamentId, classId, makeId }) => ({
        url: UPDATE_MAKE_STATUS_BY_CLASS_ID(tournamentId, classId, makeId),
        method: 'PUT',
      }),
      invalidatesTags: ['tournament'],

      transformResponse: (response) => response.data,
    }),

    updateUserProperties: builder.mutation({
      query: ({ userId, tournaments, userName }) => ({
        url: UPDATE_USER_PROPERITES(userId),
        method: 'PUT',
        body: {
          tournaments,
          userName,
        },
      }),
      transformResponse: (response) => response.data,
    }),
    updateUserProgressByComponent: builder.mutation({
      query: ({
        userId,
        makeId,
        trackId,
        completed,
        lastComponent,
        currentComponent,
        questionId,
        topicId,
        questionsIds,
        attempts,
        questionDone,
      }) => ({
        url: UPDATE_PROGRESS_OF_USER(userId, makeId),
        method: 'PATCH',
        body: {
          makeId,
          trackId,
          topicId,
          completed,
          lastComponent,
          questionId,
          questionsIds,
          questionDone,
          attempts,
          currentComponent,
        },
      }),
      invalidatesTags: ['UserProgress'],
      transformResponse: (response) => response.data,
    }),
    getAllTopics: builder.query({
      queryFn(tracks, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const topics = Promise.all(
            tracks.map(async (track) => {
              return await fetchWithBQ(GET_ALL_TOPICS_BY_TRACK_ID(track._id));
            })
          );
          return { data: topics.data };
        } catch (error) {
          return { error };
        }
      },
    }),
    getAllMakesByTrackId: builder.query({
      async queryFn({ userId, trackId }, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          if (trackId === RECOMMENDATION_ID) {
            const recommendations = await fetchWithBQ(GET_RECOMMENDATION_TRACK(userId));
            const trackId = recommendations.data.data._id;
            const topics = recommendations.data.data.topics;

            const recommendedMakes = await fetchWithBQ(GET_RECOMMENDED_MAKES(userId));
            const newMakes = await fetchWithBQ(GET_NEW_MAKES(userId));
            const popularMakes = await fetchWithBQ(GET_POPULAR_MAKES(userId));
            const makesInProgress = await fetchWithBQ(GET_MAKES_IN_PROGRESS(userId));
            const makes = [
              popularMakes.data.data,
              newMakes.data.data,
              makesInProgress.data.data,
              recommendedMakes.data.data,
            ];

            const modifyMakes = makes.map((makeCategory, i) => {
              return makeCategory.map((makeObj, makeIndex) => {
                const make = {
                  ...makeObj,
                  isFirst: makeIndex === 0,
                  topicNameFromRecommendation: topics[i].topicName,
                };
                return {
                  ...make,
                  swiperTrackId: trackId,
                  swiperTopicId: topics[i]._id,
                };
              });
            });

            return {
              data: {
                makes: modifyMakes.flat(),
                topicsData: topics,
              },
            };
          } else {
            const topics = await fetchWithBQ(GET_ALL_TOPICS_BY_TRACK_ID(trackId, userId));
            const topicIds = topics.data.data.map((topic) => topic._id);
            const makes = Promise.all(
              topicIds.map(async (topicId) => {
                return await fetchWithBQ(GET_ALL_MAKES_BY_TOPIC_ID(topicId, userId));
              })
            );

            const result = await makes;

            // const modifyMakes = makes.map((makeCategory, i) => {
            //   return makeCategory.map((makeObj) => {
            //     const make = {
            //       ...makeObj,
            //       topicNameFromRecommendation: topics[i].topicName,
            //     };
            //     return {
            //       make,
            //       swiperTrackId: trackId,
            //       swiperTopicId: topics[i]._id,
            //     };
            //   });
            // });
            const formatData = [
              ...result.map((item, i) => {
                return item.data.data.map((makeObj, makeIndex) => {
                  const make = { ...makeObj, isFirst: makeIndex === 0 };
                  return {
                    ...make,
                    swiperTrackId: trackId,
                    swiperTopicId: topics.data.data[i]._id,
                  };
                });
              }),
            ].flat();

            const topicsData = topics.data.data;

            return { data: { makes: formatData, topicsData } };
          }
        } catch (e) {
          return { error: e };
        }
      },
    }),
    getXpLevels: builder.query({
      query: () => GET_XP_LEVELS,
      transformResponse: (response) => response.data,
    }),

    getRecommendationTrack: builder.query({
      providesTags: ['UserProgress'],
      query: ({ userId }) => GET_RECOMMENDATION_TRACK(userId),
      transformResponse: (response) => response.data,
    }),

    getNextMake: builder.query({
      async queryFn(_, { getState }, _extraOptions, fetchWithBQ) {
        const state = getState();
        const user = state.auth.user;
        const trackId = state.app.currentTrack._id;
        const currentMake = state.app.currentMake;
        const currentTopic = state.app.currentTopic;
        // let makesSize = currentTopic.makesId.length;

        try {
          const { data: topicsData } = await fetchWithBQ(
            GET_ALL_TOPICS_BY_TRACK_ID(trackId, user?._id)
          );
          const topics = topicsData;
          const topicIndex = topics.data.findIndex((t) => t._id === currentTopic._id);
          const { data: makes } = await fetchWithBQ(
            GET_ALL_MAKES_BY_TOPIC_ID(currentTopic?._id, user?._id)
          );

          if (currentMake.isCampaign === true) {
            const filteredMakes = makes.data.filter((make) => {
              return (
                make._id === currentMake._id ||
                make.isCampaign === false ||
                make.isCampaign === undefined
              );
            });
            const makesSize = filteredMakes.length;
            const currentMakeIndex = filteredMakes.findIndex(
              (make) => make._id === currentMake._id
            );

            if (makesSize - 1 > currentMakeIndex) {
              const nextMake = filteredMakes[currentMakeIndex + 1];
              return { data: { nextMake, nextTopic: currentTopic } };
            }
          }

          if (currentMake.isCampaign === false) {
            const filteredMakes = makes.data.filter((make) => {
              return make.isCampaign === false || make.isCampaign === undefined;
            });
            const makesSize = filteredMakes.length;

            const currentMakeIndex = filteredMakes.findIndex((make) => {
              return make._id === currentMake._id;
            });
            if (makesSize - 1 > currentMakeIndex) {
              const nextMake = filteredMakes[currentMakeIndex + 1];
              return { data: { nextMake, nextTopic: currentTopic } };
            }
          }

          if (topics?.data.length - 1 > topicIndex) {
            const nextTopic = topics.data[topicIndex + 1];
            const { data: makes } = await fetchWithBQ(
              GET_ALL_MAKES_BY_TOPIC_ID(nextTopic._id, user?._id)
            );
            const nextMake = makes.data[0];

            return { data: { nextMake, nextTopic } };
          }
          if (topics.data.length - 1 === topicIndex) {
            const nextTopic = topics.data[0];
            const { data: makes } = await fetchWithBQ(
              GET_ALL_MAKES_BY_TOPIC_ID(nextTopic._id, user?._id)
            );

            const nextMake = makes.data[0];
            return { data: { nextMake, nextTopic } };
          }
        } catch (e) {
          return { error: e };
        }
      },
    }),
  }),
});

export const {
  useGetAvatarUsersWithUserIdQuery,
  useGetAvatarUsersQuery,
  useGetAllSchoolsQuery,
  useGetClassesBySchoolNameQuery,
  useGetAllTracksQuery,
  useGetAllTopicsByTrackQuery,
  useGetAllTopicsQuery,
  useGetAllMakesByTopicQuery,
  useGetAllUserMakesQuery,
  useGetAllCommentsQuery,
  useAddCommentMutation,
  useDeleteCommentMutation,
  useGetNewMakesQuery,
  useGetXpLevelsQuery,
  useGetPopularMakesQuery,
  useGetLeaderBoardUsersQuery,
  useGetLeaderBoardPositionQuery,
  useGetAllComponentsByMakeIdQuery,
  useUpdateUserProgressByComponentMutation,
  useUpdateUserProgressByStarMutation,
  useSetupInitialComponentQuery,
  useGetMakeByIdQuery,
  useGetMakesInProgressQuery,
  useGetNextMakeQuery,
  useGetUserMakesByTrackQuery,
  useGetUserProgressByUserIdQuery,
  useGetProgressByTrackQuery,
  useUploadMakeMutation,
  useUpdateUserPropertiesMutation,
  usePrefetch,
  useUpdateUserXpMutation,
  useUpdateUserActivityMutation,
  useGetUserXPQuery,
  useGetActivityQuery,
  useUpdateUserPointsMutation,
  useGetUserProgressByTopicQuery,
  useGetUserPointsQuery,
  useGetSchoolByClassIdQuery,
  useGetUserProgressByMakeQuery,
  useGetAllTournamentsQuery,
  useGetRecommendationTrackQuery,
  useGetAllMakesByTrackIdQuery,
  useGetAllRecommendationMakesQuery,
  useUpdateMakeStatusByClassIdMutation,
  useGetActiveTournamentsQuery,
  useGetTournamentByIdQuery,
  useAddUserToTournamentMutation,
} = makesApi;
