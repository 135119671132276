import useImage from 'hooks/useImage';
import { AvatarWithoutImg } from 'components/AvatarBar/components/AvatarBarList/avatarBarList.styles';
import PropTypes from 'prop-types';
import { getRandomColor, getUserNameAcronyms } from 'utils/helpers';

import * as S from './Avatar.styles';
const Avatar = ({ src, alt, size, type, id, color }) => {
  const [setImageRef, hasError] = useImage();
  const avatarWithoutImage = () => {
    const username = getUserNameAcronyms(alt);
    const style = { backgroundColor: color || getRandomColor() };
    return (
      <AvatarWithoutImg key={id} style={style}>
        {username}
      </AvatarWithoutImg>
    );
  };

  return hasError ? (
    avatarWithoutImage()
  ) : (
    <S.Avatar ref={setImageRef} src={src} alt={alt} size={size} type={type} />
  );
};

export default Avatar;
