import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import Image from 'pages/CMSPage/components/inputTypes/Image/Image';
import React from 'react';
import useFeedbackHandler from '../../../../../../hooks/useFeedbackHandler';
import * as S from './Feedback.style.js';
const Feedback = ({ question }) => {
  const { answers } = question;
  const { handleFeedbackTextChange, handleFeedbackImageChange } = useFeedbackHandler();
  const renderFeedback = () => {
    return answers.map((answer, index) => {
      const { feedback } = answer;
      return (
        <>
          <S.TextInputWrapper>
            <Input
              type={'text'}
              label={`משוב לתשובה ${index + 1}`}
              value={feedback?.text}
              onChange={(event) =>
                handleFeedbackTextChange(event.target.value, index, question)
              }
            />
          </S.TextInputWrapper>
          <Image
            value={feedback?.imageUrl}
            onChange={(imageFile) =>
              handleFeedbackImageChange(imageFile, index, question)
            }
          />
        </>
      );
    });
  };
  return <S.Container>{renderFeedback()}</S.Container>;
};

export default Feedback;
