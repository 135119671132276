import Joi from 'joi';
import objectId from 'joi-objectid';
import { safeCharactersRegex } from 'utils/helpers/regexes';
import { trackNameErrorMessages, trackRouteErrorMessages } from '../errorMessages/track';

Joi.objectId = objectId(Joi);

const trackSchema = Joi.object({
  trackName: Joi.string()
    .required()
    .messages({ ...trackNameErrorMessages }),
  trackRoute: Joi.string()
    .regex(safeCharactersRegex)
    .required()
    .trim()
    .messages({ ...trackRouteErrorMessages }),
  publish: Joi.boolean(),
  topicsId: Joi.array(),
  _id: Joi.objectId(),
  index: Joi.number(),
  __v: Joi.number(),
});

export default trackSchema;
