import { TRACK_ID_OF_META, META_S3_URL } from 'config/constants/constants';

export const convertMetaStruct = (metaUserTree) => {
  const topics = [];
  Object.keys(metaUserTree).forEach((key, index1) => {
    const makesIdArr = Object.keys(metaUserTree[key]);
    const obj = {
      _id: key,
      publish: true,
      trackId: TRACK_ID_OF_META,
      topicName: key,
      makesId: [
        stringSerialization(`info${index1}`),
        ...makesIdArr.map((make) => stringSerialization(make)),
      ],
    };
    topics.push(obj);
  });
  return topics;
};

export const convertMetaMakes = (metaUserTree) => {
  const makesByTopic = {};
  Object.keys(metaUserTree).forEach((key1) => {
    const makes = metaUserTree[key1];
    const makesArray = [];
    Object.keys(makes).forEach((key2) => {
      const currMake = makes[key2];
      const obj = {
        _id: stringSerialization(key2),
        topicId: key1,
        trackId: TRACK_ID_OF_META,
        makeName: currMake.title,
        teaser: `${META_S3_URL}${currMake.img}.png`,
        makeDescription: currMake.description,
        publish: true,
        componentsId: currMake.components.map((component) =>
          stringSerialization(component.component_id)
        ),
        level: currMake.difficulty || 'easy',
        timeFrame: currMake.time || 30,
        info: [
          [
            "חשבון ופעולות מתמטיות ברמת כיתה י' (מחזקות ועד נעלמים)",
            'עדיף לסיים את המייקים הקודמים למייק הזה',
            'האמת שזהו! אנחנו זמינים בדיסקורד לכל השאלות',
          ],
          ['הכנה לבגרות במתמטיקה בדרך של מייקס!'],
        ],
      };
      makesArray.push(obj);
    });
    makesByTopic[key1] = makesArray;
  });
  return makesByTopic;
};

export const convertMetaComponents = (metaUserTree, topicId, makeId) => {
  const components = metaUserTree[topicId][stringSerializationRevert(makeId)].components;

  const convertArr = [];
  components.map((component) => {
    const obj = {
      _id: stringSerialization(component.component_id),
      makeId: makeId,
      isBonus: component.component_category === 'bonus' ? true : false,
      type: 'meta',
      points: 100,
      data: {
        url: component.component_url,
      },
      lock: true,
      publish: true,
    };
    convertArr.push(obj);
  });
  const summaryComponent = {
    _id: stringSerialization(`s${makeId}${topicId}`),
    makeId: makeId,
    type: 'summary',
    points: 0,
    lock: false,
    data: {},
  };
  convertArr.push(summaryComponent);
  return convertArr;
};

const stringSerialization = (str) => {
  let newStr = '';
  if (str) {
    for (let i = 0; i < str.length; i++) {
      newStr = newStr + str.charCodeAt(i).toString(16);
    }
    for (let i = newStr.length; i < 24; i++) newStr = newStr + '1';
  }
  return newStr.substr(0, 24);
};

const stringSerializationRevert = (str) => {
  let reverseStr = str.split('').reverse().join('');
  while (reverseStr.substr(0, 2) === '11') reverseStr = reverseStr.substr(2);
  let hexStr = reverseStr.split('').reverse().join('');
  var newStr = '';
  for (let n = 0; n < hexStr.length; n += 2) {
    newStr += String.fromCharCode(parseInt(hexStr.substr(n, 2), 16));
  }
  return newStr;
};

const getTeaserAndDescription = (topicName) => {
  switch (topicName) {
    case 'בעיות קיצון':
      return {
        teaser: 'beaiotKitzon',
        description:
          'אחד התחומים העתיקים והפופולריים במתמטיקה. עונות על השאלה כיצד לבחור מבין האפשרויות את האפשרות האופטימלית.',
      };
    case 'חשבון אינטגרלי':
      return {
        teaser: 'heshbonIntegrali',
        description:
          'ענף במתמטיקה שחוקר שינוי. מבוסס רובו ככולו על המושג גבול, מה שמאפשר לעסוק בתוצאה של תהליכים אינסופיים.',
      };
    case 'פונקציות טריגונומטריות':
      return {
        teaser: 'trigonomerticFunction',
        description:
          'יש שיגידו פונקציות של זווית. משמשות בעיקר להבין את הקשר בין הזוויות במשולש לאורכי צלעותיו. הפונקציות הטריגונומטריות המוכרות ביותר הן סינוס, קוסינוס וטנגנס.',
      };
    case 'מושגי יסוד בפונקציות - חזרה':
      return {
        teaser: 'basicDefinitions',
        description: 'מתחילים מיסודות',
      };
    case 'פונקציית מנה':
      return {
        teaser: 'manaFunction',
        description: 'סוג של פונקציה שיש בה מונה ומכנה',
      };
    case 'פונקציית מנה (רציונלית)':
      return {
        teaser: 'manaFunction',
        description: 'סוג של פונקציה שיש בה מונה ומכנה',
      };
    case 'פונקציית פולינום':
      return {
        teaser: 'polynomFunction',
        description:
          'סוג של פונקציה שיש בה כמה מונומים. כלומר איברים שיש בהם מספר קבוע ומשתנה בחזקה כלשהי.',
      };
    case 'פונקציית שורש':
      return {
        teaser: 'squareFunction',
        description: 'סוג של פונקציה שיש בה שורש',
      };
    case 'קדם אנליזה':
      return {
        teaser: 'KdamAnaliza',
        description: 'היכולת לנתח גרפים ופונקציות עוד לפני שצוללים לחקירה מעמיקה ומקיפה.',
      };
    default:
      return {
        teaser: 'basicDefinitions',
        description: '',
      };
  }
};
