import { useSelector } from 'react-redux';
import starSkeleton_dark from 'assets/icons/starSkeleton_dark.svg';
import starSkeleton_light from 'assets/icons/starSkeleton_light.svg';
import * as S from './MakeSkeleton.style';
import { useEffect, useState } from 'react';
const MakeSkeleton = () => {
  const isMobile = useSelector((state) => state.ui.isMobile);
  const [animate, setAnimation] = useState(false);
  useEffect(() => {
    let timer;
    timer = setInterval(() => {
      setAnimation(!animate);
    }, [1000]);

    return () => clearInterval(timer);
  }, [animate]);
  return (
    <>
      <S.MakeCard>
        <S.BackgroundWrapper />
        <S.MakeDetails>
          <S.TitleContainer>
            <S.MakeName></S.MakeName>
            <S.MakeSubTitle></S.MakeSubTitle>
          </S.TitleContainer>
          <S.DescriptionContainer>
            <S.IconsWrapper></S.IconsWrapper>
            <S.StarContainer>
              <S.StarImg
                src={animate ? starSkeleton_dark : starSkeleton_light}
                alt={'star skeleton'}
              />
              <S.StarImg
                src={animate ? starSkeleton_dark : starSkeleton_light}
                alt={'star skeleton'}
              />
              <S.StarImg
                src={animate ? starSkeleton_dark : starSkeleton_light}
                alt={'star skeleton'}
              />
            </S.StarContainer>
          </S.DescriptionContainer>
        </S.MakeDetails>
      </S.MakeCard>
    </>
  );
};

export default MakeSkeleton;
