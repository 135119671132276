import DraggableItems from 'pages/CMSPage/components/DraggableItems/DraggableItems';
import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import PreviewMode from 'pages/CMSPage/components/PreviewMode/PreviewMode';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import useDraggableItems from '../../hooks/dragNdrop/useDraggableItems';
import EditModeLayout from '../../../../layouts/EditModeLayout/EditModeLayout';
import * as S from './TrackEditor.style';
import { useEditorContext } from '../../context/editorContext';
import cmsTrackExample from 'assets/icons/cmsTrackExample.png';
import useUpdateTrack from '../../hooks/CRUD/update/useUpdateTrack';
import usePublishTrack from '../../hooks/publish/usePublishTrack';
import { isObjectPropertiesFalsy } from 'pages/CMSPage/utils/helpers';
import useTrackEditing from './useTrackEditing';
import ActionButton from 'pages/CMSPage/components/ActionButton/ActionButton';

const TrackEditor = () => {
  const { updateTrack } = useUpdateTrack();
  const { currentSelectedData } = useCmsContext();
  const { publish } = currentSelectedData;
  const { draggableList } = useEditorContext();
  const { trackName, trackRoute } = currentSelectedData;
  const { onDragEnd, onItemClick } = useDraggableItems();
  const { publishTrack, unPublishTrack } = usePublishTrack();
  const { onTextChange, errors } = useTrackEditing();

  const draggableItemsProps = {
    draggableList: draggableList,
    onDragEnd: onDragEnd,
    onItemClick: onItemClick,
    shape: 'square',
    direction: 'vertical',
  };

  const trackNameProps = {
    type: 'text',
    value: trackName,
    onChange: (event) => onTextChange('trackName', event),
    label: 'שם של הטראק',
    alert: errors?.trackName,
  };
  const trackRouteProps = {
    type: 'text',
    value: trackRoute,
    onChange: (event) => onTextChange('trackRoute', event),
    label: 'שם של ה route',
    alert: errors?.trackRoute,
  };
  const updateButtonProps = {
    disabled: isObjectPropertiesFalsy(errors),
    background: 'green',
    handleOnClick: updateTrack,
    value: 'עדכן',
  };
  const publishButtonProps = {
    background: 'orange',
    handleOnClick: publish ? unPublishTrack : publishTrack,
    value: publish ? 'הסר פרסום' : 'פרסם',
  };

  return (
    <EditModeLayout>
      {currentSelectedData && draggableList && (
        <S.Container>
          <S.DraggableItemsContainer>
            <DraggableItems {...draggableItemsProps} />
          </S.DraggableItemsContainer>
          <S.EditContent>
            <Input {...trackNameProps} />
            <Input {...trackRouteProps} />
          </S.EditContent>
          <S.PreviewContainer>
            <PreviewMode image={cmsTrackExample}>
              <ActionButton {...updateButtonProps} />
              <ActionButton {...publishButtonProps} />
            </PreviewMode>
          </S.PreviewContainer>
        </S.Container>
      )}
    </EditModeLayout>
  );
};

export default TrackEditor;
