import Selector from 'pages/CMSPage/components/inputTypes/Selector/Selector';
import useHandleComponentData from 'pages/CMSPage/hooks/useHandleComponentData';
import * as S from './Lock.style';
const Lock = ({ component }) => {
  const lockString = 'כן';
  const unlockString = 'לא';
  const { lock } = component;
  const { handleComponentDataObj } = useHandleComponentData();
  return (
    <S.Container>
      <S.Title>נעול?</S.Title>
      <Selector
        value={lock}
        options={[lockString, unlockString]}
        values={[lockString, unlockString]}
        defaultOption={''}
        background={'white'}
        onChange={(value) => handleComponentDataObj('lock', value)}
        color={'var(--color-charcoal)'}
      />
    </S.Container>
  );
};

export default Lock;
