import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getTrackRoute } from 'routes/helper';
import useGetTracks from 'hooks/queries/useGetTracks';
import { toggleHamburger } from 'services/redux/ui.slice';
import { customErrorMsg } from 'utils/helpers';
import { metaModalClickedEvent } from 'services/segmentIO/categories/Navigation';

import * as S from './HamburgerNavBar.styles';

const NavBar = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.ui.isMobile);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);

  const { tracks } = useGetTracks();

  const NavLinks = (
    <>
      {tracks &&
        tracks.map((track) => {
          const { _id: trackId, trackName, trackRoute } = track;
          let path = getTrackRoute(trackRoute);
          if (trackRoute === 'meta') {
            if (!isLoggedIn) path = '/login';
            else {
              if (!user?.metaStudentId) {
                path = '/metaSignup';
              } else path = path = getTrackRoute(trackRoute);
            }
          } else path = getTrackRoute(trackRoute);
          return (
            <NavLink
              exact
              to={{
                pathname: path,
              }}
              activeClassName="selected"
              key={trackId}
              onClick={() => dispatch(toggleHamburger())}
            >
              <S.TrackNav>{trackName}</S.TrackNav>
            </NavLink>
          );
        })}
    </>
  );

  return (
    <S.NavBarContainer isMobile={isMobile}>
      <S.NavBarWrapper isMobile={isMobile}>{NavLinks}</S.NavBarWrapper>
    </S.NavBarContainer>
  );
};

export default NavBar;
