import styled from 'styled-components';

export const GoBackButtonContainer = styled.div``;
export const GoBackButton = styled.button`
  width: 15rem;
  height: 4rem;
  background-color: orange;
  color: white;
  border-radius: 0.5rem;
  cursor: pointer;
  opacity: ${({ opacity }) => opacity};
`;
