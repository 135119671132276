import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';

import Topic from '../Topic/Topic';
import * as S from './TopicList.style';
const TopicList = ({ topics }) => {
  const params = useParams();
  const { trackRoute } = params;
  const ref = useRef(null);
  const scrollToFunc = (offSet) => {
    ref.current.scrollTo({
      top: 0,
      left: offSet,
      behavior: 'smooth',
    });
  };
  return (
    <S.Container currentTrackRoute={trackRoute} ref={ref}>
      {topics?.map((topic) => (
        <Topic
          key={topic._id}
          topic={topic}
          topics={topics}
          scrollToFunc={scrollToFunc}
        />
      ))}
    </S.Container>
  );
};

export default TopicList;
