import useHandleComponentData from 'pages/CMSPage/hooks/useHandleComponentData';
import useImageUpload from 'pages/CMSPage/hooks/useImageUpload';
import React from 'react';

const useDiscussonHandler = (content) => {
  const { handleComponentDataObj } = useHandleComponentData();
  const { uploadImage } = useImageUpload();

  const handleContentType = (val) => {
    handleComponentDataObj('content', { ...content, type: val, data: '' });
  };
  const handleVideoType = (event) => {
    handleComponentDataObj('content', {
      ...content,
      type: 'video',
      data: event.target.value,
    });
  };

  const handleImageType = async (event) => {
    const url = await uploadImage(event);
    handleComponentDataObj('content', {
      ...content,
      type: 'image',
      data: url,
    });
  };

  return { handleVideoType, handleImageType, handleContentType };
};

export default useDiscussonHandler;
