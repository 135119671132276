import { GOALS, COMPLETION_UNITS } from 'apis/CONSTANTS';
import close_component from 'assets/icons/close_component.svg';
import Header from 'layouts/Header';

import MetaFeaturesIFrame from '../MetaFeaturesIFrame/MetaFeaturesIframe';
import * as S from './displayMetaFeatures.styles';

const DisplayMetaFeatures = ({ type, setShowMetaIframe }) => {
  const renderMetaFeature = () => {
    switch (type) {
      case 'goals':
        return <MetaFeaturesIFrame data={{ url: GOALS }} />;
      case 'completionUnits':
        return <MetaFeaturesIFrame noButtons data={{ url: COMPLETION_UNITS }} />;
      default:
        return;
    }
  };
  return (
    <>
      <S.HeaderWrapper>
        <Header type="component">
          <S.RIconsWrapper>
            <S.CloseWrapper>
              <img
                alt="close"
                src={close_component}
                onClick={() => setShowMetaIframe(null)}
              />
              <S.TextHeader>סגור</S.TextHeader>
            </S.CloseWrapper>
          </S.RIconsWrapper>
        </Header>
      </S.HeaderWrapper>
      <S.ContainerMetaIframe>{renderMetaFeature()}</S.ContainerMetaIframe>
    </>
  );
};
export default DisplayMetaFeatures;
