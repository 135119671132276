import styled, { css } from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const Wrapper = styled.header`
  direction: rtl;
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  background-color: var(--color-background);
  @media ${device.tablet} {
    padding: 2.5rem 0rem;
    width: 100%;
  }
`;
export const Logo = styled.img`
  width: 4.3rem;
  height: 3.6rem;
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media ${device.tablet} {
    width: 6rem;
    height: 5rem;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  @media ${device.tablet} {
    column-gap: 2rem;
    width: unset;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  justify-content: flex-end;
  @media ${device.tablet} {
    column-gap: 2rem;
  }
`;
