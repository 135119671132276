import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 50%;
  justify-content: space-around;
`;
export const EditContent = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
