import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import EditModeLayout from 'pages/CMSPage/layouts/EditModeLayout/EditModeLayout';
import useUpdateHandler from '../../hooks/handlers/useUpdateHandler';
import * as S from './MakeEditor.style';
import PreviewMode from 'pages/CMSPage/components/PreviewMode/PreviewMode';
import cmsMakeExample from 'assets/icons/cmsMakeExample.png';
import useMakeEditing from './useMakeEditing';
import { isObjectPropertiesFalsy } from 'pages/CMSPage/utils/helpers';
import ActionButton from 'pages/CMSPage/components/ActionButton/ActionButton';
import useGetAllTracks from '../../hooks/useGetAllTracks';
import Selector from 'pages/CMSPage/components/inputTypes/Selector/Selector';
import { useEffect, useState } from 'react';
import useGetTopicsByTrackId from '../../hooks/useGetTopicsByTrackId';
import useGetMakesByTopicId from '../../hooks/useGetMakesByTopicId';
import Thumbnail from 'pages/CMSPage/components/inputTypes/Thumbnail/Thumbnail';
const MakeEditor = () => {
  const [tracks, setTracks] = useState([]);
  const [topics, setTopics] = useState([]);
  const [makes, setMakes] = useState([]);

  const { getAllTracks } = useGetAllTracks();
  const { getTopicsByTrackId } = useGetTopicsByTrackId();
  const { getMakesByTopicId } = useGetMakesByTopicId();
  const { currentSelectedData, handleData } = useCmsContext();
  const { updateItem } = useUpdateHandler();
  const { onTextChange, errors } = useMakeEditing();
  const { makeName, makeRoute, _id: makeId, quizMake, thumbnail } = currentSelectedData;
  const [track, setTrack] = useState();
  const [topic, setTopic] = useState();
  const url = `${process.env.REACT_APP_BASE_URL}/facilitator/${makeRoute}/${makeId}`;
  const urlProps = { href: url, target: '_blank' };

  useEffect(() => {
    getAllTracks().then((data) => setTracks(data));
  }, []);

  useEffect(() => {
    if (track) getTopicsByTrackId(track).then((data) => setTopics(data));
  }, [track]);

  useEffect(() => {
    if (topic) getMakesByTopicId(topic).then((data) => setMakes(data));
  }, [topic]);

  useEffect(() => {
    if (quizMake?.trackId) setTrack(quizMake?.trackId);
    if (quizMake?.topicId) setTopic(quizMake?.topicId);
  }, [quizMake]);

  const makeNameProps = {
    type: 'text',
    label: 'שם ה MAKE',
    value: makeName,
    onChange: (event) => onTextChange('makeName', event),
    alert: errors?.makeName,
  };
  const makeRouteProps = {
    type: 'text',
    label: 'שם ה ROUTE',
    value: makeRoute,
    onChange: (event) => onTextChange('makeRoute', event),
    alert: errors?.makeRoute,
  };

  const updateButtonProps = {
    disabled: isObjectPropertiesFalsy(errors),
    background: 'green',
    handleOnClick: updateItem,
    value: 'עדכן',
  };
  const trackSelectorProps = {
    options: [...tracks?.map(({ trackName }) => trackName)],
    values: [...tracks?.map(({ _id }) => _id)],
    defaultOption: 'בחר',
    onChange: (newValue) => setTrack(newValue),
    value: track,
    label: 'בחר טראק',
    showLabel: true,
  };
  const thumbnailProps = {
    label: 'תמונה ',
    handleData: (url) => handleData('thumbnail', url),
    value: thumbnail || '',
  };
  const topicSelectorProps = {
    options: [...topics?.map(({ topicName }) => topicName)],
    values: [...topics?.map(({ _id }) => _id)],
    defaultOption: 'בחר',
    onChange: (newValue) => setTopic(newValue),
    value: topic,
    label: 'בחר טופיק',
    showLabel: true,
  };
  const makeSelectorProps = {
    options: [
      ...makes?.filter(({ quizMake }) => quizMake).map(({ makeName }) => makeName),
    ],
    values: [
      ...makes?.filter(({ quizMake }) => quizMake).map(({ quizMake }) => quizMake._id),
    ],
    defaultOption: 'בחר',
    onChange: (newValue) => handleData('quizMake', newValue),
    value: quizMake || quizMake?._id,
    label: 'בחר מייק',
    showLabel: true,
  };

  return (
    <EditModeLayout>
      {currentSelectedData && (
        <>
          <S.Container>
            <S.EditContent>
              <Input {...makeNameProps} />
              <Input {...makeRouteProps} />
              <Thumbnail {...thumbnailProps} />
              <S.QuizContainer>
                <S.Title>מייק שאלון</S.Title>
                <Selector {...trackSelectorProps} />
                <Selector {...topicSelectorProps} />
                <Selector {...makeSelectorProps} />
              </S.QuizContainer>
            </S.EditContent>
            <S.PreviewContainer>
              <PreviewMode image={cmsMakeExample}>
                <ActionButton {...updateButtonProps} />
              </PreviewMode>
              <S.Link {...urlProps}>{'קישור למייק'}</S.Link>
            </S.PreviewContainer>
          </S.Container>
        </>
      )}
    </EditModeLayout>
  );
};

export default MakeEditor;
