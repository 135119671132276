import { useEffect, useRef } from 'react';

const useScrollToSides = (currentIndex, index, length, scrollToFunc) => {
  const ref = useRef();

  useEffect(() => {
    if (!ref?.current) return;
    if (currentIndex === index && currentIndex !== length - 1) {
      const nextSibling = ref.current.nextSibling;
      let offSet;
      offSet = nextSibling.offsetLeft;
      if (currentIndex + 1 === length - 1) {
        offSet = nextSibling.offsetLeft - 30;
      }
      scrollToFunc(offSet);
    }
    if (currentIndex === index && currentIndex === length - 1) {
      scrollToFunc(ref.current.offsetLeft);
    }
  }, [currentIndex, ref]);

  return { ref };
};

export default useScrollToSides;
