import { uploadFile } from 'utils/helpers/aws-s3';

const useImageUpload = () => {
  const uploadImage = async (imageFile) => {
    //this condition to handle deleteing imageFile
    if (!imageFile) return '';
    const url = await uploadFile(imageFile);
    return url;
  };

  return { uploadImage };
};

export default useImageUpload;
