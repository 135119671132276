import styled from 'styled-components/macro';

export const Iframe = styled.iframe`
  margin: 0 auto;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  display: inline-block;
  border: 0;
  overflow: hidden;
  overflow-y: scroll;
`;
