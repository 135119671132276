import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import { useTournamentContext } from 'pages/CMSPage/contexts/tournamentContext';
import * as S from './FacilitatorField.style';
import useFacilitatorFields from '../../hooks/useFacilitatorFields';
import closeIcon from 'assets/icons/close_component.svg';
import {
  validateFacilitatorEmail,
  validateFacilitatorName,
} from '../../../../validation/validation';

const FacilitatorFields = () => {
  const { currentTournament } = useTournamentContext();
  const {
    createFacilitator,
    removeFacilitator,
    handleFacilitatorEmailChange,
    handleFacilitatorNameChange,
  } = useFacilitatorFields();

  console.log(currentTournament?.facilitators);
  return (
    <S.Container>
      {currentTournament.facilitators.length < 1 && <S.Label>{'רשימת מנחים'}</S.Label>}
      {currentTournament.facilitators.map((facilitator, index) => {
        return (
          <S.Wrapper key={facilitator._id}>
            <S.InputWrapper>
              <Input
                type={'text'}
                label={'שם המנחה / דוא״ל'}
                placeholder={'שם המנחה'}
                value={facilitator.userName}
                onChange={(event) => handleFacilitatorNameChange(event, index)}
                required={true}
                handleOnInvalid={validateFacilitatorName}
              />
            </S.InputWrapper>
            <S.InputWrapper alignSelf={'flex-end'}>
              <Input
                type={'email'}
                showLabel={false}
                value={facilitator.email}
                placeholder={'איימיל'}
                onChange={(event) => handleFacilitatorEmailChange(event, index)}
                required={true}
                handleOnInvalid={validateFacilitatorEmail}
              />
            </S.InputWrapper>
            <S.CloseIcon
              src={closeIcon}
              onClick={() => removeFacilitator(facilitator._id, facilitator.email)}
              alt="closeIcon"
            />
          </S.Wrapper>
        );
      })}
      <S.PlusButton onClick={(event) => createFacilitator(event)} />;
    </S.Container>
  );
};

export default FacilitatorFields;
