import Text from 'pages/CMSPage/components/ComponentTypes/Text/Text';
import Checkbox from 'pages/CMSPage/components/ComponentTypes/CheckBox/Checkbox';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import Youtube from 'pages/CMSPage/components/ComponentTypes/Youtube/Youtube';
import ChoiceQuestion from './components/ChoiceQuestion/ChoiceQuestion';
import Submission from './components/Submission/Submission';
import Google from 'pages/CMSPage/components/ComponentTypes/Google/Google';
import Guru from './components/Guru/Guru';
import Discussion from './components/Discussion/Discussion';
import Iframe from './components/Iframe/Iframe';
import Selector from 'pages/CMSPage/components/inputTypes/Selector/Selector';
import * as S from './ComponentEditor.style';
import EditModeLayout from '../../../../layouts/EditModeLayout/EditModeLayout';
import DraggableItems from 'pages/CMSPage/components/DraggableItems/DraggableItems';
import useDraggableItems from '../../hooks/dragNdrop/useDraggableItems';
import PreviewMode from 'pages/CMSPage/components/PreviewMode/PreviewMode';
import { useEditorContext } from '../../context/editorContext';
import useComponentHandler from 'pages/CMSPage/hooks/useComponentHandler';
import useUpdateComponent from '../../hooks/CRUD/update/useUpdateComponent';
import usePublishComponent from '../../hooks/publish/usePublishComponent';
import { EDITOR_COMPONENT_TYPES } from 'pages/CMSPage/Constants/component';
import useValidateQuestionEditor from 'pages/CMSPage/hooks/useValidateQuestionEditor';
import { capitalizeFirstLetter } from 'pages/CMSPage/utils/helpers';
import MiniGames from 'pages/CMSPage/components/ComponentTypes/MiniGames/MiniGames';
import ActionButton from 'pages/CMSPage/components/ActionButton/ActionButton';

const ComponentEditor = () => {
  const { currentSelectedData } = useCmsContext();
  const component = currentSelectedData;
  const { type, publish } = component;
  const { changeType } = useComponentHandler(EDITOR_COMPONENT_TYPES);
  const { isQuestionInvalid } = useValidateQuestionEditor();
  const { onDragEnd, onItemClick } = useDraggableItems();
  const { draggableList, choice } = useEditorContext();
  const { updateComponent } = useUpdateComponent();
  const { publishComponent, unPublishComponent } = usePublishComponent();
  const { track, topic, make } = choice;
  const url = `${process.env.REACT_APP_BASE_URL}/track/${track?.trackRoute}/${topic?.topicRoute}/${make?.makeRoute}`;
  const urlProps = { href: url, target: '_blank' };
  const currentComponentIndex = draggableList.list.findIndex(
    (item) => item._id === draggableList.currentItem._id
  );

  const renderComponentImage = () => {
    const capitlizedType = capitalizeFirstLetter(type);
    const image = require(`assets/icons/cms${capitlizedType}Example.png`).default;
    return image;
  };

  const renderComponentEditor = () => {
    switch (type) {
      case 'youtube':
        return <Youtube component={component} />;
      case 'text':
        return <Text component={component} />;
      case 'choiceQuestion':
        return <ChoiceQuestion component={component} />;
      case 'checkbox':
        return <Checkbox component={component} />;
      case 'upload':
        return <Submission component={component} />;
      case 'google':
        return <Google component={component} />;
      case 'guru':
        return <Guru component={component} />;
      case 'discussion':
        return <Discussion component={component} />;
      case 'iframe':
        return <Iframe component={component} />;
      case 'miniGame':
        return <MiniGames component={component} />;
      default:
        return null;
    }
  };
  const draggableItemsProps = {
    draggableList: draggableList,
    onDragEnd: onDragEnd,
    onItemClick: onItemClick,
    shape: 'circle',
    direction: 'horizontal',
  };

  const componentsSelectorProps = {
    options: [
      ...Object.keys(EDITOR_COMPONENT_TYPES).map(
        (item) => `קומפוננטה - ${EDITOR_COMPONENT_TYPES[item]}`
      ),
    ],
    values: [...Object.keys(EDITOR_COMPONENT_TYPES).map((item) => item)],
    defaultOption: '',
    background: 'white',
    color: 'var(--color-charcoal)',
    disabled: type === 'summary',
    value: type,
    onChange: (selectedVal) => changeType(selectedVal),
  };

  const updateButtonProps = {
    disabled: isQuestionInvalid,
    background: 'green',
    handleOnClick: updateComponent,
    value: 'עדכן',
  };
  const publishButtonProps = {
    background: 'orange',
    handleOnClick: publish ? unPublishComponent : publishComponent,
    value: publish ? 'הסר פרסום' : 'פרסם',
  };
  return (
    <EditModeLayout>
      {currentSelectedData && draggableList && (
        <>
          <S.DraggableItemsContainer>
            <DraggableItems {...draggableItemsProps} />
          </S.DraggableItemsContainer>
          <S.Container>
            <S.EditContent>
              <S.ComponentHeadingWrapper>
                <S.ComponentTitle>{`${EDITOR_COMPONENT_TYPES[type]} - קומפננטה ${
                  currentComponentIndex + 1
                }`}</S.ComponentTitle>
                <Selector {...componentsSelectorProps} />
              </S.ComponentHeadingWrapper>
              {renderComponentEditor()}
            </S.EditContent>
            <S.PreviewContainer>
              <PreviewMode image={type && renderComponentImage()}>
                <ActionButton {...updateButtonProps} />
                <ActionButton {...publishButtonProps} />
              </PreviewMode>
              <S.Link {...urlProps}>{'קישור למייק'}</S.Link>
            </S.PreviewContainer>
          </S.Container>
        </>
      )}
    </EditModeLayout>
  );
};

export default ComponentEditor;
