import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Item from './Item/Item';
const DraggableItem = ({ item, index, currentItem, callback, itemKey, shape }) => {
  return (
    <Draggable key={item._id} draggableId={item._id} index={index}>
      {(provided) => (
        <Item
          provided={provided}
          callback={callback}
          itemKey={itemKey}
          shape={shape}
          currentItem={currentItem}
          index={index}
          item={item}
        />
      )}
    </Draggable>
  );
};

export default DraggableItem;
