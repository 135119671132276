import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';

export const MetaIconsWrapper = styled.div`
  margin-left: 2.85rem;
  display: flex;
`;
export const IconText = styled.h5`
  font-family: ${primaryFont.bold};
  letter-spacing: 10;
  font-size: 0.7rem;
  color: ${({ from }) =>
    from === 'component' ? 'var(--color-charcoal-grey)' : 'var(--color-white)'};
  margin-top: 0.4rem;
`;
export const GoalsIconWrapper = styled.div`
  display: flex;
  margin-right: 1rem;
  flex-direction: column;
  align-items: center;
`;

export const GoalsIcon = styled.img`
  filter: ${({ from }) => from === 'component' && 'invert(1)'};
  max-width: 15.05px;
  max-height: 15.05px;
`;

export const CompletionUnitsIcon = styled(GoalsIcon)`
  max-width: 11.25px;
  max-height: 15px;
`;
export const DoneUnitIconWrapper = styled(GoalsIconWrapper)`
  margin-right: 0;
`;
