import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { useGetActivityQuery } from 'services/api/makesApi/makesApi';
import { customErrorMsg } from 'utils/helpers';

const useGetUserActivity = () => {
  const handleError = useErrorHandler();
  const userId = useSelector((state) => state.auth?.user?._id);
  const { data: userActivity, error: userActivityError } = useGetActivityQuery(
    userId || localStorage.getItem('makes_userId'),
    { skip: !userId && !localStorage.getItem('makes_userId') }
  );
  if (userActivityError) {
    return handleError(customErrorMsg('updateUserActivity', userActivityError));
  }
  return { userActivity };
};
export default useGetUserActivity;
