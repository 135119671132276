import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';
import { device } from 'utils/helpers/deviceScreen';

export const Container = styled.div`
  height: 100%;
  padding: 5rem 2.5rem 0 0;
  width: 80%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  background: var(--color-white);
  @media ${device.tablet} {
    padding: 2.5rem 5rem 0 0;
    width: 40rem;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1.25rem 0 2.5rem 0;

  @media ${device.tablet} {
    padding: 2rem 0 4rem 0;
  }
`;

export const NavContainer = styled.div`
  padding-bottom: 2.5rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SubTitleWrapper = styled.div``;

export const Title = styled.div`
  font-family: ${primaryFont.bold};
  font-size: 1.8rem;
  color: var(--color-charcoal-grey);
  display: flex;
`;

export const SubTitle = styled.div`
  font-family: ${primaryFont.regular};
  cursor: pointer;
  font-size: 1.4rem;
  direction: rtl;
  color: ${({ active }) => (active ? 'var(--color-red)' : 'var(--color-charcoal-grey)')};
  margin-top: 0.575rem;
  @media ${device.tablet} {
    font-size: 1.8rem;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Logo = styled.div`
  text-align: center;
  justify-self: center;
  cursor: pointer;
  padding-left: 1rem;
`;
export const MakesIcon = styled.img`
  height: 3.1rem;
  width: 12.6rem;

  @media ${device.tablet} {
    width: 20rem;
    height: 4.97rem;
  }
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseIcon = styled.img`
  padding-left: 2.5rem;
  cursor: pointer;
`;
