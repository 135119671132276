import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';
import { device } from 'utils/helpers/deviceScreen';

export const ItemContainer = styled.li`
  display: flex;
  flex-direction: column;
  text-align: center;
  list-style: none;
  justify-content: center;
  align-items: center;
`;

export const ItemName = styled.span`
  font-family: ${primaryFont.bold};
  color: black;
  white-space: nowrap;
  letter-spacing: -0.11px;
  font-size: 1.1rem;
  margin-top: 0.2rem;
  @media ${device.tablet} {
    font-size: 1.4rem;
    margin-top: 1rem;
  }
`;
export const ItemIcon = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  @media ${device.tablet} {
    width: 3.2rem;
  }
`;
export const ItemIconWrapper = styled.div`
  border: 2px solid black;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  @media ${device.tablet} {
    width: 7rem;
    height: 7rem;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ selected }) => selected && 'rgb(92, 189, 137)'};
`;
