import cmsDefaultMenuImg from 'assets/icons/cmsDefaultMenuImg.png';
import { PERMISSIONS } from 'config/constants/constants';
import { useSelector } from 'react-redux';

import * as S from './DefaultMenu.style';
const DefaultMenu = () => {
  const permission = useSelector((state) => state.auth?.user?.permission);
  return (
    <S.Container>
      {!permission === PERMISSIONS.ADMIN ? <S.Text>התחבר על מנת לערוך</S.Text> : null}
      <S.Image alt="menuImage" src={cmsDefaultMenuImg} />
    </S.Container>
  );
};

export default DefaultMenu;
