import { DefaultLabelStyle } from 'pages/CMSPage/components/GlobalStyle/Global.style';
import styled from 'styled-components/macro';

export const AccordionWrapper = styled.div`
  display: flex;
`;

export const AccordionItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const AccordionContents = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const AccordionButton = styled.button`
  background-color: var(--color-white);
  padding: 6.5px 10px;
  cursor: pointer;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  width: 100%;
  border-radius: 6px;
`;

export const Arrow = styled.img`
  ${({ isOpen }) => isOpen && `transform:${isOpen && 'rotate(180deg)'}`}
`;
export const SelectionWrapper = styled.div`
  display: flex;
`;

export const Make = styled.button`
  background-color: var(--color-white);
  padding: 6.5px 10px;
  cursor: pointer;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  width: 90%;
  margin-right: auto;
  border-radius: 6px;
`;
