import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  padding: 5rem 0;
  flex-direction: column;
`;
export const SectionContainer = styled.div`
  display: grid;
  padding: 5rem 0;
  column-gap: 10rem;
  grid-template-columns: 1fr 1.5fr 1fr;
`;
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
`;

export const DatesContainer = styled.div`
  display: flex;
  width: 25%;
  column-gap: 2rem;
  align-items: center;
`;
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;
