import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 85%;
  margin: 0 auto;
  padding-top: 5rem;
`;
export const EditContent = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin: 1rem;
`;
export const DraggableItemsContainer = styled.div``;
export const PreviewContainer = styled.div`
  margin: 1rem 0;
  overflow: hidden;
`;

export const Link = styled.a`
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  font-size: 1.8rem;
  font-family: ${primaryFont.bold};
  margin: 2rem auto;
  display: block;
  text-align: center;
  overflow: auto;
`;
export const CloseIcon = styled.img`
  cursor: pointer;
  position: absolute;
  left: 2%;
  top: 25%;
`;
export const TextInputWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;
export const QuizContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
`;
