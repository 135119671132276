import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { validKeys, validValues } from 'services/validation/whiteListQueries';
import locationQueriesValidation from 'services/validation/locationQueriesValidation';
const useQuery = () => {
  const history = useHistory();
  const location = useLocation();
  const [queries, setQueries] = useState();
  const errorHandler = (key, value) => {
    if (key) {
      if (!validKeys.includes(key)) {
        throw new Error(`${key} is not a valid key`);
      }
    }
    if (value) {
      if (!validValues.includes(value)) {
        throw new Error(`${value} is not a valid value`);
      }
    }
  };

  useEffect(() => {
    const query = queryString.parse(location.search, {
      sort: false,
      parseBooleans: true,
    });
    setQueries(query);
    //TODO need to use JOI for validation because groupLinkId is dynamic
    // const queryValidation =
    //   location.pathname === '/'
    //     ? true
    //     : locationQueriesValidation(location.pathname, query);
    // return queryValidation ? history.push(queryValidation) : setQueries(query);
  }, [location]);

  const getQuery = (key) => {
    if (!queries) return null;
    //TODO need to use JOI for validation because groupLinkId is dynamic
    // errorHandler(key);
    return queries[key];
  };

  const mutateQuery = (key, value) => {
    if (!queries) return null;
    location.pathname !== '/';
    const newQueries = { ...queries };
    newQueries[key] = value;
    setQueries(newQueries);
    const stringifyQuery = queryString.stringify(newQueries, {
      sort: false,
      parseBooleans: true,
    });

    history.push({
      pathname: location.pathname,
      search: stringifyQuery,
    });
  };

  return { queries, getQuery, mutateQuery };
};
export default useQuery;
