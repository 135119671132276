import { PERMISSIONS } from 'config/constants/constants';
import { useTournamentContext } from 'pages/CMSPage/contexts/tournamentContext';
import useEditTournament from '../../hooks/useEditTournament';
import usePendingInvitations from '../../hooks/useUpdatePendingInvitations';
import EditButtons from './components/EditButtons/EditButtons';
import EditingFields from './components/EditingFields/EditingFields';
import * as S from './EditMode.style';

const EditMode = () => {
  const { editTournament } = useEditTournament();
  const { updatePendingInvitation } = usePendingInvitations();
  const { currentTournament } = useTournamentContext();
  const tournamentId = currentTournament?._id;

  const handleOnSubmit = (event) => {
    event.preventDefault();
    editTournament(tournamentId, currentTournament);
    updatePendingInvitation({
      data: currentTournament.facilitators,
      tournamentId,
      permission: PERMISSIONS.FACILITATOR,
    });
  };

  return (
    <S.Container onSubmit={handleOnSubmit}>
      <EditButtons />
      <EditingFields />
    </S.Container>
  );
};

export default EditMode;
