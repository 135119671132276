import { useSelector } from 'react-redux';
import ContentCreator from '../ContentCreator/ContentCreator.js';
import MakeStatus from '../MakeStatus/MakeStatus.js';
import * as S from './Details.styles.js';
const Details = ({ topicName, contentCreator, makeStatus, make, handleOnClick }) => {
  const isMobile = useSelector((state) => state.ui.isMobile);
  return (
    <>
      {!isMobile && <S.TopicName>{'#' + topicName}</S.TopicName>}
      <S.Wrapper>
        {make?.quizMake ? (
          <S.QuizWrapper
            backgroundColor="var(--color-white)"
            onClick={(e) => handleOnClick(e, make, true)}
          >
            חידון
          </S.QuizWrapper>
        ) : (
          <div />
        )}
        <S.BottomWrapper>
          {contentCreator && <ContentCreator contentCreator={contentCreator} />}
          {makeStatus && <MakeStatus status={makeStatus} />}
        </S.BottomWrapper>
      </S.Wrapper>
    </>
  );
};
export default Details;
