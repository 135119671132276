import { updateXp } from 'components/auth/auth.slice';
import { updateVisitedTracks } from 'components/auth/userActivity.slice';
import { FIRST_TIME_TRACK } from 'config/constants/xpConstants';
import useUpdateUserActivity from 'hooks/userActivity/useUpdateUserActivity';
import useUpdateRank from 'hooks/useUpdateRank';
import useUpdateUserXp from 'hooks/xp/useUpdateUserXp';
import { useDispatch, useSelector } from 'react-redux';
import { isElementInArray } from 'utils/helpers';

const useUpdateTrackXp = () => {
  const dispatch = useDispatch();
  const visitedTracks = useSelector((state) => state?.userActivity?.visitedTracks);
  const userId = useSelector((state) => state?.auth?.user?._id);

  const { rankUpdate } = useUpdateRank();
  const { updateUserXp } = useUpdateUserXp();
  const { updateUserActivity } = useUpdateUserActivity();

  const updateTrackXP = async (trackId) => {
    const body = {
      action: 'visitedTracks',
      payload: trackId,
    };
    if (!isElementInArray(visitedTracks, trackId)) {
      dispatch(updateVisitedTracks(trackId));
      await updateUserActivity({ userId, data: body });
      const { data } = await updateUserXp({ userId, actionName: FIRST_TIME_TRACK });
      dispatch(updateXp({ currentXp: data.newPoints, previousXp: data.oldPoints }));
      if (data.newLevel) {
        rankUpdate(userId, data);
      }
    }
  };
  return { updateTrackXP };
};

export default useUpdateTrackXp;
