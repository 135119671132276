import NavBar from 'layouts/NavBar';
import Header from 'layouts/Header';
import Statistics from 'layouts/Statistics/Statistics';
import SubNav from 'layouts/SubNav/SubNav';
import { useSelector } from 'react-redux';
import Footer from 'layouts/Footer/Footer.js';
import NavbarSkeleton from 'components/SkeletonLoader/SkeletonDiscovery/NavbarSkeleton/NavbarSkeleton.js';
import SubnavSkeleton from 'components/SkeletonLoader/SkeletonDiscovery/SubnavSkeleton/SubnavSkeleton.js';
import * as S from './DiscoveryLayout.style.js';

const DiscoveryLayout = ({ children, tracks, topics }) => {
  const isMobile = useSelector((state) => state.ui.isMobile);
  const isHamburgerOpen = useSelector((state) => state.ui.toggleHamburger);

  return (
    <S.Container isHamburgerOpen={isHamburgerOpen} isMobile={isMobile}>
      <S.DiscoveryPageHeader isMobile={isMobile}>
        <Header type="discovery">
          <Statistics />
        </Header>
        {!tracks ? <NavbarSkeleton /> : <NavBar tracks={tracks} />}
        {!topics ? <SubnavSkeleton /> : <SubNav topics={topics} />}
      </S.DiscoveryPageHeader>
      {!children && <S.ChildrenWrapper></S.ChildrenWrapper>}
      {children}
      {!isMobile && <Footer />}
    </S.Container>
  );
};

export default DiscoveryLayout;
