import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { LOGIN } from 'routes/CONSTANTS';

const PrivateRoute = ({ children, ...rest }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isLoggedIn ? (
          children
        ) : (
          <Redirect to={{ pathname: LOGIN, from: location }} />
        );
      }}
    />
  );
};

export default PrivateRoute;
