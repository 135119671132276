import { updateComponentRequest } from 'apis/CMS/editor';
import { getQuizMakeComponents } from 'apis/CMS/quizMake';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';

const useUpdateQuizComponent = () => {
  const { activeAlert } = useCmsContext();
  const { choice, setQuizComponents } = useEditorContext();
  const { make } = choice;
  const makeId = make?.quizMake?._id;
  const { currentSelectedData } = useCmsContext();
  const { _id: componentId } = currentSelectedData;

  const updateQuizComponent = async () => {
    const { data: updatedComponent } = await updateComponentRequest(
      componentId,
      currentSelectedData
    );
    const { data: fetchComponents } = await getQuizMakeComponents(makeId);
    setQuizComponents(fetchComponents);
    activeAlert({ title: 'הצלחנו', text: `<p>הקומפננטה התעדכנה בהצלחה</p>` });

    return updatedComponent;
  };
  return { updateQuizComponent };
};

export default useUpdateQuizComponent;
