import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';

export const Question = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  align-items: center;
  border: 1px solid black;
  border-radius: 0.25rem;
  margin: 1rem 0;
`;

export const ActionsContainer = styled.div`
  display: flex;
  width: 10%;
  justify-content: space-between;
`;

export const Title = styled.span`
  font-size: 1.5rem;
  font-family: ${primaryFont.bold};
`;

export const TrashIcon = styled.img`
  display: block;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
`;
export const EditIcon = styled.img`
  display: block;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
`;
