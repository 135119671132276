import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import Image from 'pages/CMSPage/components/inputTypes/Image/Image';

import * as S from './Answers.style';
import useAnswersHandler from '../../../../../../hooks/useAnswersHandler';
import CustomCheckbox from 'pages/CMSPage/components/CustomCheckbox/CustomCheckbox';

const Answers = ({ question }) => {
  const { handleChangeInTextAnswer, handleChangeInImageAnswer, handleCorrectAnswer } =
    useAnswersHandler();
  const { answers, type, correctIndexAnswer } = question;
  const checkboxStyle = {
    position: 'absolute',
    left: ' 0',
    top: '60%',
  };
  const renderAnswers = () => {
    return answers.map((answer, index) => {
      const { content } = answer;
      if (type === 'image')
        return (
          <S.Answer>
            <S.Wrapper>
              <Image
                key={index}
                label={`תשובה ${index + 1}`}
                value={content}
                onChange={(imageFile) =>
                  handleChangeInImageAnswer(imageFile, index, question)
                }
              />
              <S.CheckboxWrapper>
                <CustomCheckbox
                  customStyle={checkboxStyle}
                  handleOnClick={() => handleCorrectAnswer(index, question)}
                  checked={index === correctIndexAnswer}
                />
              </S.CheckboxWrapper>
            </S.Wrapper>
          </S.Answer>
        );
      return (
        <S.Answer>
          <S.Wrapper>
            <Input
              type={'text'}
              key={index}
              label={`תשובה ${index + 1}`}
              value={content}
              onChange={(event) =>
                handleChangeInTextAnswer(event.target.value, index, question)
              }
            />
            <S.CheckboxWrapper>
              <CustomCheckbox
                customStyle={checkboxStyle}
                handleOnClick={() => handleCorrectAnswer(index, question)}
                checked={index === correctIndexAnswer}
              />
            </S.CheckboxWrapper>
          </S.Wrapper>
        </S.Answer>
      );
    });
  };
  return <S.Container>{renderAnswers()}</S.Container>;
};

export default Answers;
