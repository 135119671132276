import motivationLoveEmoji from 'assets/icons/motivationLoveEmoji.svg';
import motivationYellowEmoji from 'assets/icons/motivationYellowEmoji.svg';
import MotivationEmoji from './components/MotivationEmoji';
import * as S from './MotivationLayer.style';
const MotivationLayer = ({ handleData }) => {
  const renderMotivationEmojies = () => {
    const motivationEmojies = [
      {
        text: 'BLANK',
        type: null,
      },
      {
        type: 'yellow_emoji',
        image: motivationYellowEmoji,
      },
      {
        type: 'pink_love',
        image: motivationLoveEmoji,
      },
    ];
    return motivationEmojies.map((emoji) => (
      <MotivationEmoji
        key={emoji.type}
        callback={(newValue) => handleData('motivationLayer', newValue)}
        {...emoji}
      />
    ));
  };

  return (
    <>
      <S.Title>הוספת מוטיבציה</S.Title>
      <S.RowContainer>{renderMotivationEmojies()}</S.RowContainer>{' '}
    </>
  );
};

export default MotivationLayer;
