import LvlUpModal from 'components/LvlUpModal';
import RootModal from 'components/RootModal/RootModal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { getSpecificObjFromArrayOfObjects } from 'utils/helpers';
import { useGetAllMakesByTrackIdQuery } from 'services/api/makesApi/makesApi';
import useGetTracks from 'hooks/queries/useGetTracks';
import useUpdateTrackXp from './hooks/useUpdateTrackXp';
import { setCurrentTopic, setCurrentTrack } from 'services/redux/app.slice';
import { RECOMMENDATIONS } from 'routes/CONSTANTS';
import { getTopicRoute } from 'routes/helper';
import { useEffect } from 'react';
import DiscoveryLayout from 'layouts/DiscoveryLayout/DiscoveryLayout';
import MobileContent from './components/Content/MobileContent/MobileContent';
import DesktopContent from './components/Content/DesktopContent/DesktopContent';
import { usePrefetch } from 'services/api/makesApi/makesApi';
import SkeletonContent from './components/Content/SkeletonContent';
const DiscoveryPage = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.user._id);
  const showLvlUpModal = useSelector((state) => state.ui.showLvlUpModal);
  const currentTrack = useSelector((state) => state.app?.currentTrack);
  const isMobile = useSelector((state) => state.ui.isMobile);
  const trackId = currentTrack?._id;
  const { tracks } = useGetTracks();

  const prefetchAllMakesByTrackId = usePrefetch('getAllMakesByTrackId');
  const { updateTrackXP } = useUpdateTrackXp();

  const { trackRoute, topicRoute } = params;

  const { data: makesData, isFetching } = useGetAllMakesByTrackIdQuery(
    { userId, trackId },
    {
      skip: !trackId,
    }
  );

  const isAnythingLoading = useSelector((state) => {
    const requests = Object.values(state.makesApi.queries);
    return requests.some((entry) => entry.status === 'pending');
  });

  useEffect(() => {
    //prefetching
    if (!isAnythingLoading && tracks) {
      Promise.all(
        tracks.map((track) => {
          const trackId = track._id;
          prefetchAllMakesByTrackId({ userId, trackId });
        })
      );
    }
  }, [isAnythingLoading]);

  //Non Value Returned From Functions/Hooks
  // void useTopicHandler();

  /* Disable Modal what i do here */

  // useEffect(() => {
  //   let timeout;
  //   if (!isLoggedIn && window.sessionStorage.getItem('firstTimeUser') === 'true') {
  //     timeout = setTimeout(() => {
  //       dispatch(setShowWhatIDoHere(true));
  //     }, 3000);
  //   }
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, []);

  useEffect(() => {
    if (!tracks?.length) return;
    let currentTrack = getSpecificObjFromArrayOfObjects(tracks, 'trackRoute', trackRoute);
    const trackId = currentTrack?._id;
    if (trackId) updateTrackXP(trackId);
    if (!trackId) return history.push(RECOMMENDATIONS);
    dispatch(setCurrentTrack(currentTrack));

    if (makesData) {
      const currentTopic = makesData.topicsData.find(
        (topic) => topic.topicRoute === topicRoute
      );

      if (!currentTopic || !topicRoute) {
        console.log('IM HERE');
        return history.push(
          getTopicRoute(trackRoute, makesData.topicsData[0].topicRoute)
        );
      }
      dispatch(setCurrentTopic(currentTopic));
    }
  }, [makesData, tracks, trackRoute, topicRoute]);

  return (
    <>
      <RootModal visible={showLvlUpModal} isScrollable={false} isOpacity>
        <LvlUpModal textColor={'white'} background={'black'} bgImageOpacity={'0.5'} />
      </RootModal>
      <DiscoveryLayout tracks={tracks} topics={makesData?.topicsData}>
        {!isFetching && tracks && isMobile && <MobileContent />}
        {!isFetching && tracks && !isMobile && <DesktopContent makesData={makesData} />}
        {isFetching && <SkeletonContent />}
      </DiscoveryLayout>
    </>
  );
};

export default DiscoveryPage;
