import UploadWidget from '../../UploadWidget/UploadWidget';
import switchIcon from 'assets/icons/switchIcon.svg';
import trashIcon from 'assets/icons/trashIcon.svg';
import { useRef } from 'react';
import { CLOUD_NAME, UPLOAD_PRESET_FOR_THUMBNAILS } from 'services/cloudinary/constants';
import * as S from './Thumbnail.styles.js';
const Thumbnail = (props) => {
  const { value, handleData, label } = props;
  const settings = {
    cloudName: CLOUD_NAME,
    uploadPreset: UPLOAD_PRESET_FOR_THUMBNAILS,
    multiple: false,
    maxFiles: 1,
    folder: 'teaser_thumbnails',

    clientAllowedFormats: ['png', 'jpg', 'jpeg', 'gif', 'webp'],
  };

  const uploadRef = useRef(null);

  const renderUploadedImageEditor = () => (
    <S.Container>
      <>
        <S.Image alt={'image'} src={value} />
        <S.ChangeFileContainer>
          <S.DeleteIcon onClick={() => handleData('')}>
            <img alt="trash" src={trashIcon} />
          </S.DeleteIcon>
          <S.SwitchIcon onClick={() => uploadRef.current.open()}>
            <img alt="switch" src={switchIcon} />
          </S.SwitchIcon>
        </S.ChangeFileContainer>
      </>
    </S.Container>
  );
  return (
    <>
      <S.Label>{label}</S.Label>
      {value && renderUploadedImageEditor()}
      <UploadWidget
        settings={settings}
        label={label}
        handleData={handleData}
        ref={uploadRef}
        value={value}
      />
      {!value && (
        <button
          style={{
            backgroundColor: 'yellow',
            border: '1px solid blue',
            height: '30px',
            width: '100px',
          }}
          onClick={() => uploadRef.current.open()}
        >
          {label}
        </button>
      )}
    </>
  );
};
export default Thumbnail;
