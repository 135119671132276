import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';
import { skeletonLoader } from '../Skeleton.style';

export const Container = styled.div`
  display: flex;
  color: var(--color-charcoal-grey);
  padding-right: 2rem;

  justify-content: unset;
  margin-bottom: 1.5rem;
  @media ${device.tablet} {
    margin-bottom: 3.5rem;
    justify-content: center;
  }
`;
export const TopicList = styled.ul`
  display: flex;
  align-items: center;
  margin-right: ${({ currentTrackRoute }) =>
    currentTrackRoute !== 'recommendation' && '1.5rem'};
  column-gap: 0.5rem;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const TopicItem = styled.li`
  list-style: none;
  border-radius: 30px;
  line-height: 1;
  width: 20rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2rem;
  animation: ${skeletonLoader} 1s ease-in-out infinite;
`;
