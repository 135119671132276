import * as S from './IconWithText.style';
const IconWithText = ({ icon, children, alt, handleOnClick }) => {
  return (
    <S.Container onClick={handleOnClick}>
      <S.IconWrapper>
        <S.Icon src={icon} alt={alt} />
      </S.IconWrapper>
      <S.Value>{children}</S.Value>
    </S.Container>
  );
};

export default IconWithText;
