import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';
export const Container = styled.div`
  color: white;
  position: absolute;
  height: 120vh;
  background: orange;
`;

export const HeaderWrapper = styled.div`
  background: white;
`;

export const ContainerMetaIframe = styled.div`
  background: white;
  height: 100%;
`;

export const RIconsWrapper = styled.div`
  display: flex;
  z-index: 1;
`;

export const CloseWrapper = styled.div`
  img {
    height: 1.5rem;
    width: 1.5rem;
  }
`;
export const TextHeader = styled.h1`
  color: var(--color-charcoal-grey);
  font-size: 0.7rem;
  font-family: ${primaryFont.bold};
  margin: 0;
  margin-top: 2px;
  text-align: center;
`;
export const IFrameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
`;
