import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import { useTournamentContext } from 'pages/CMSPage/contexts/tournamentContext';
import * as S from './ClassFields.style';
import closeIcon from 'assets/icons/close_component.svg';
import useClassFields from '../../hooks/useClassFields';
import { validateClassName } from '../../../../validation/validation';

const ClassFields = () => {
  const { currentTournament } = useTournamentContext();
  const { createClass, handleClassNameChange, removeClass } = useClassFields();

  return (
    <>
      {currentTournament.classrooms.length < 1 && <S.Label>{'רשימת כיתות'}</S.Label>}
      {currentTournament.classrooms.map((classRoom, index) => (
        <S.Wrapper key={classRoom._id}>
          <S.InputWrapper>
            <Input
              type={'text'}
              label={'שם הכיתה'}
              value={classRoom.className}
              onChange={(event) => handleClassNameChange(event, index)}
              required={true}
              placeholder={'שם הכיתה'}
              handleOnInvalid={validateClassName}
            />
          </S.InputWrapper>
          <S.CloseIcon
            src={closeIcon}
            onClick={() => removeClass(classRoom._id)}
            alt="closeIcon"
          />
        </S.Wrapper>
      ))}
      <S.PlusButton onClick={(event) => createClass(event)} />;
    </>
  );
};

export default ClassFields;
