import { useSelector } from 'react-redux';
import * as S from './AvatarSkeleton.style';
const AvatarSkeleton = () => {
  const isMobile = useSelector((state) => state.ui.isMobile);
  return (
    <>
      <S.AvatarBar isMobile={isMobile}>
        <S.Avatar></S.Avatar>
        <S.Avatar></S.Avatar>
        <S.Avatar></S.Avatar>
        <S.Avatar></S.Avatar>
      </S.AvatarBar>
    </>
  );
};

export default AvatarSkeleton;
