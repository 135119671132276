import star from 'assets/icons/star.svg';
import IconWithText from 'components/IconWithText/IconWithText';
import xpPath from 'assets/icons/xpPath.svg';
import points from 'assets/icons/pointsIcon.svg';
import { useSelector } from 'react-redux';
import useGetXpLevels from 'hooks/xp/useGetXpLevels';
import { calculateUserAchivedStart, getCurrentLevel } from 'utils/helpers';
import useGetUserProgressByUserId from 'components/XpBanner/hooks/useGetUserProgressByUserId';
import { TOTAL_STARS_IN_MAKE } from 'components/XpBanner/CONSTANT';
import { useHistory } from 'react-router-dom';
import { getProfileOptionRoute } from 'routes/helper';
import { LEADER_BOARD, XP } from 'routes/CONSTANTS';
import useCounter from 'hooks/useCounter';
const Statistics = () => {
  const history = useHistory();
  const userXp = useSelector((state) => state.auth?.user?.xp?.currentXp);
  const userPoints = useSelector((state) => state.auth?.user?.points?.currentPoints);
  const { xpLevels } = useGetXpLevels();
  const { userProgresses } = useGetUserProgressByUserId();
  const { counter: pointsCounter } = useCounter(userPoints, 100);
  const stars = calculateUserAchivedStart(userProgresses);
  const { counter: starsCounter } = useCounter(stars, 300);
  const { counter: xpCounter } = useCounter(
    xpLevels && userXp && getCurrentLevel(xpLevels, userXp)?.currentLevel?.level,
    300
  );

  return userProgresses ? (
    <>
      <IconWithText
        handleOnClick={() => history.push(getProfileOptionRoute(XP))}
        icon={xpPath}
      >
        {xpCounter}
      </IconWithText>
      <IconWithText
        handleOnClick={() => history.push(getProfileOptionRoute(XP))}
        icon={star}
      >
        {starsCounter + '/' + userProgresses.length * TOTAL_STARS_IN_MAKE}
      </IconWithText>
      <IconWithText
        handleOnClick={() => history.push(getProfileOptionRoute(LEADER_BOARD))}
        icon={points}
      >
        {pointsCounter}
      </IconWithText>
    </>
  ) : (
    <></>
  );
};

export default Statistics;
