import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import useHandleComponentData from 'pages/CMSPage/hooks/useHandleComponentData';
import { useEffect } from 'react';
import { convertStringToHTML, dataURItoFile } from 'utils/helpers';
import { uploadFile } from 'utils/helpers/aws-s3';
import { dataURIregex } from 'utils/helpers/regexes';

const useTextEditor = (text) => {
  const { handleComponentDataObj } = useHandleComponentData();
  const { setLoading } = useCmsContext();

  useEffect(() => {
    if (text) {
      uploadImagesToS3();
    }
  }, [text]);

  const uploadImagesToS3 = async () => {
    const html = convertStringToHTML(text);
    const images = html.querySelectorAll('img');
    await Promise.all(
      Array.from(images).map(async (node) => {
        if (node instanceof Image && dataURIregex.test(node.src)) {
          const image = dataURItoFile(node.src);
          setLoading(true);
          const url = await uploadFile(image);
          setLoading(false);
          node.setAttribute('src', url);
        }
        return node;
      })
    );
    handleComponentDataObj('text', html.innerHTML);
  };
};

export default useTextEditor;
