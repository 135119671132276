import { publishTrackRequest, unPublishTopicsByTrackId } from 'apis/CMS/CMS';
import { getTracks } from 'apis/tracks';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

const usePublishTrack = () => {
  const userId = useSelector((state) => state.auth?.user?._id);
  const { activeAlert } = useCmsContext();
  const handleError = useErrorHandler();
  const { choice, setTracks, handleChoiceChange, setTopics, setMakes, setComponents } =
    useEditorContext();
  const { track } = choice;
  const trackId = track?._id;

  const publishTrack = async () => {
    try {
      const { data: publishedTrack } = await publishTrackRequest(trackId);
      handleChoiceChange('track', publishedTrack);
      const { data: fetchTracks } = await getTracks(userId);
      setTracks(fetchTracks);
      activeAlert({ title: 'הצלחנו', text: `<p>הטראק פורסם בהצלחה</p>` });
    } catch (error) {
      handleError(error);
    }
  };
  const unPublishTrack = async () => {
    try {
      const {
        data: { unPublishedTrack, topics, makes, components },
      } = await unPublishTopicsByTrackId(trackId);
      handleChoiceChange('track', unPublishedTrack);
      setTopics(topics);
      setMakes(makes);
      setComponents(components);
      activeAlert({ title: 'הצלחנו', text: `<p>הפרסום הוסר לטראק</p>` });
    } catch (error) {
      handleError(error);
    }
  };
  return { publishTrack, unPublishTrack };
};

export default usePublishTrack;
