import Checkbox from 'pages/CMSPage/components/ComponentTypes/CheckBox/Checkbox';
import Google from 'pages/CMSPage/components/ComponentTypes/Google/Google';
import Text from 'pages/CMSPage/components/ComponentTypes/Text/Text';
import Youtube from 'pages/CMSPage/components/ComponentTypes/Youtube/Youtube';
import DraggableItems from 'pages/CMSPage/components/DraggableItems/DraggableItems';
import Selector from 'pages/CMSPage/components/inputTypes/Selector/Selector';
import PreviewMode from 'pages/CMSPage/components/PreviewMode/PreviewMode';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import EditModeLayout from 'pages/CMSPage/layouts/EditModeLayout/EditModeLayout';
import useDraggableItems from '../../hooks/dragNdrop.js/useDraggableItems';
import useUpdateHandler from '../../hooks/handlers/useUpdateHandler';
import * as S from './ComponentEditor.style';
import useComponentHandler from 'pages/CMSPage/hooks/useComponentHandler';
import Discussion from './components/Discussion/Discussion';
import { FACILITATOR_COMPONENT_TYPES } from 'pages/CMSPage/Constants/component.js';
import { capitalizeFirstLetter } from 'pages/CMSPage/utils/helpers';
import ActionButton from 'pages/CMSPage/components/ActionButton/ActionButton';
import { useFacilitatorContext } from '../../context/useFacilitatorContext';

const ComponentEditor = () => {
  const { currentSelectedData } = useCmsContext();
  const component = currentSelectedData;
  const { type } = component;
  const { changeType } = useComponentHandler(FACILITATOR_COMPONENT_TYPES);
  const { updateItem } = useUpdateHandler();
  const { onDragEnd, onItemClick } = useDraggableItems();
  const { draggableList, choice } = useFacilitatorContext();
  const { make } = choice;
  const { _id: makeId } = make;
  const url = `${process.env.REACT_APP_BASE_URL}/facilitator/${makeId}`;
  const urlProps = { href: url, target: '_blank' };

  const renderComponentImage = () => {
    const capitlizedType = capitalizeFirstLetter(type);
    const image = require(`assets/icons/cms${capitlizedType}Example.png`).default;
    return image;
  };
  const renderComponentEditor = () => {
    switch (type) {
      case 'youtube':
        return <Youtube component={component} />;
      case 'text':
        return <Text component={component} />;
      case 'checkbox':
        return <Checkbox component={component} />;
      case 'google':
        return <Google component={component} />;
      case 'discussion':
        return <Discussion component={component} />;
      default:
        return null;
    }
  };

  const draggableItemsProps = {
    draggableList: draggableList,
    onDragEnd: onDragEnd,
    onItemClick: onItemClick,
    shape: 'circle',
    direction: 'horizontal',
  };

  const componentsSelectorProps = {
    background: 'white',
    color: 'var(--color-charcoal)',
    options: [
      ...Object.keys(FACILITATOR_COMPONENT_TYPES).map(
        (item) => `קומפוננטה - ${FACILITATOR_COMPONENT_TYPES[item]}`
      ),
    ],
    values: [...Object.keys(FACILITATOR_COMPONENT_TYPES).map((item) => item)],
    defaultOption: '',
    value: type,
    onChange: (selectedVal) => changeType(selectedVal),
  };

  const updateButtonProps = {
    background: 'green',
    handleOnClick: updateItem,
    value: 'עדכן',
  };

  return (
    <EditModeLayout>
      {currentSelectedData && draggableList && (
        <>
          <S.DraggableItemsContainer>
            <DraggableItems {...draggableItemsProps} />
          </S.DraggableItemsContainer>
          <S.Container>
            <S.EditContent>
              <Selector {...componentsSelectorProps} />
              {renderComponentEditor()}
            </S.EditContent>
            <S.PreviewContainer>
              <PreviewMode image={type && renderComponentImage()}>
                <ActionButton {...updateButtonProps} />
              </PreviewMode>
              <S.Link {...urlProps}>{'קישור למייק'}</S.Link>
            </S.PreviewContainer>
          </S.Container>
        </>
      )}
    </EditModeLayout>
  );
};

export default ComponentEditor;
