import AlertContainer from 'components/Alert';
import Spinner from 'components/Spinner';
import CMSHeader from '../components/CMSHeader/CMSHeader';
import { useTournamentContext } from '../contexts/tournamentContext';
import EditMode from './components/EditMode/EditMode';
import Menu from './components/Menu/Menu';

const TournamentEditor = () => {
  const { isEditing, alert, isLoading } = useTournamentContext();

  return (
    <>
      <AlertContainer alert={alert} />
      {isLoading && <Spinner />}
      <CMSHeader />
      <Menu />
      {isEditing && <EditMode />}
    </>
  );
};

export default TournamentEditor;
