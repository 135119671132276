import Avatar from 'components/Avatar';
import { formulaToCalculateUsers, saveStringAsHtmlEntity } from 'utils/helpers';

import * as S from './avatarBarList.styles';
const AvatarBarList = ({ userDetails: { users, length } }) => {
  const avatarBarList = users.map((user) => {
    const { userAvatar, userName, _id, discordId } = user;
    if (userAvatar) {
      return (
        <Avatar
          key={_id}
          src={
            discordId
              ? `https://cdn.discordapp.com/avatars/${discordId}/${userAvatar}.png`
              : userAvatar
          }
          alt={userName}
          id={_id}
          type={'avatarBar'}
          size={'s'}
        />
      );
    }
  });
  const extraAvatar = (
    <S.AvatarWithoutImg extraAvatar>
      {formulaToCalculateUsers(length)}
      {saveStringAsHtmlEntity('+')}
    </S.AvatarWithoutImg>
  );
  return (
    users.length > 0 && (
      <>
        {avatarBarList}
        {extraAvatar}
      </>
    )
  );
};

export default AvatarBarList;
