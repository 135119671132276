import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';
import { device } from 'utils/helpers/deviceScreen';

export const Item = styled.div`
  list-style: none;
  border: 1px solid black;
  border-radius: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2rem;
  line-height: 1;
  background-color: ${({ selected }) => selected && 'var(--color-green)'};
`;

export const Text = styled.p`
  color: black;
  padding: 0.5rem 1rem 0.4rem;
  white-space: nowrap;
  font-family: ${primaryFont.bold};
  font-size: 1.2rem;

  ::before {
    content: '';
  }
  @media ${device.tablet} {
    ::before {
      content: '#';
    }
  }
`;
