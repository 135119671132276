import Joi from 'joi';

const metaFormSchema = Joi.object({
  appid: Joi.string().length(5).required(),
  appsecret: Joi.string().required().length(27),
  class_id: Joi.string()
    .pattern(/^[0-9]+$/)
    .required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  fname: Joi.string().required(),
  lname: Joi.string().required(),
  gender: Joi.string().length(1).required(),
  phone: Joi.string()
    .length(10)
    .pattern(/^[0-9]+$/)
    .required(),
  school_id: Joi.string()
    .required()
    .pattern(/^[0-9]+$/),
});
export default metaFormSchema;
