import { createTrack, getTrackById } from 'apis/CMS/editor';
import { getTracks } from 'apis/tracks';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import useCreateTopic from './useCreateTopic';

const useCreateTrack = () => {
  const handleError = useErrorHandler();
  const { createNewTopic } = useCreateTopic();
  const userId = useSelector((state) => state.auth?.user?._id);
  const { tracks, setTracks } = useEditorContext();
  const createNewTrack = async () => {
    try {
      const intialTrackData = {
        trackName: `טראק חדש ${tracks.length + 1}`,
        trackRoute: `newtrack${tracks.length + 1}`,
      };
      const { data: newTrack } = await createTrack(intialTrackData);
      const { _id: newTrackId } = newTrack;
      const intialTopicData = {
        topicName: `טופיק חדש 1`,
        topicRoute: `newtopic1`,
      };
      await createNewTopic(newTrackId, intialTopicData);
      const { data } = await getTracks(userId);
      setTracks(data);
      const { data: updatedTrack } = await getTrackById(newTrackId);
      return updatedTrack;
    } catch (error) {
      handleError(error);
    }
  };
  return { createNewTrack };
};

export default useCreateTrack;
