import ComponentOptions from 'pages/CMSPage/components/ComponentOptions/ComponentOptions';
import Selector from 'pages/CMSPage/components/inputTypes/Selector/Selector';
import useHandleComponentData from 'pages/CMSPage/hooks/useHandleComponentData';
import { useState } from 'react';
import { QuestionEditorProvider } from '../../context/questionEditor/questionEditorContext';
import QuestionEditor from '../QuestionEditor/QuestionEditor';
import * as S from './Guru.style';
const Guru = ({ component }) => {
  const { handleComponentDataObj } = useHandleComponentData();
  const { data, type } = component;
  const { questions, level = 'easy' } = data;
  const LEVELS_TYPE_DIC = {
    easy: 'קל',
    moderate: 'בינוני',
    hard: 'קשה',
    extraHard: 'קשה מאוד',
  };

  const [currentLevel, setCurrentLevel] = useState(LEVELS_TYPE_DIC[level] || '');

  const handleChangeLevel = (val) => {
    setCurrentLevel(val);
    const lvl = Object.keys(LEVELS_TYPE_DIC).find(
      (type) => LEVELS_TYPE_DIC[type] === val
    );
    handleComponentDataObj('level', lvl);
  };

  const renderLevelDifficulty = () => {
    return (
      <Selector
        options={[...Object.keys(LEVELS_TYPE_DIC).map((type) => LEVELS_TYPE_DIC[type])]}
        values={[...Object.keys(LEVELS_TYPE_DIC).map((type) => LEVELS_TYPE_DIC[type])]}
        label="בחר רמת קושי"
        value={level ? LEVELS_TYPE_DIC[level] : currentLevel}
        onChange={(val) => handleChangeLevel(val)}
      />
    );
  };
  return (
    <S.Container>
      <S.Section>
        <ComponentOptions component={component} />
        {renderLevelDifficulty()}
      </S.Section>
      <S.Section>
        <QuestionEditorProvider questions={questions}>
          <QuestionEditor type={type} />
        </QuestionEditorProvider>
      </S.Section>
    </S.Container>
  );
};

export default Guru;
