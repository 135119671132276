import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useFacilitatorContext } from '../../../context/useFacilitatorContext';
import useCreateHandler from '../../../hooks/handlers/useCreateHandler';
import useDeleteHandler from '../../../hooks/handlers/useDeleteHandler';

const useMenuHandler = () => {
  const { handleChoiceChange, choice, components, makes } = useFacilitatorContext();
  const { currentSelectedData, mode, setMode } = useCmsContext();
  const { make } = choice;
  const { createItem } = useCreateHandler();
  const { deleteItem } = useDeleteHandler();

  const handlePreviousMode = () => {
    switch (mode) {
      case 'make': {
        handleChoiceChange('make', null);
        setMode(null);
        break;
      }
      case 'component': {
        handleChoiceChange('make', make);
        setMode('make');
        break;
      }
    }
  };

  const handleSequenceComponentsButton = () => {
    if (mode === 'component') return;
    const firstComponentID = currentSelectedData?.componentsId[0]?.componentId;
    const firstComponent = components.find((comp) => comp._id === firstComponentID);
    handleChoiceChange('component', firstComponent);
    setMode('component');
  };

  const handleSelectorChange = (key, value) => {
    let item;
    switch (key) {
      case 'make': {
        item = makes?.find((make) => make._id === value);
        break;
      }
      default:
        break;
    }
    handleChoiceChange(key, item);
    setMode(key);
  };

  return {
    deleteItem,
    createItem,
    handlePreviousMode,
    handleSequenceComponentsButton,
    handleSelectorChange,
  };
};

export default useMenuHandler;
