import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Title = styled.p`
  padding-right: 1rem;
  font-size: 2rem;
  color: var(--color-charcoal-grey);
  font-family: ${primaryFont.bold};
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
