import styled from 'styled-components';

// export const ActionButtonsContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 100%;
// `;

export const Button = styled.button`
  background: ${({ background }) => background};
  border-radius: 1rem;
  cursor: pointer;
  color: white;
  text-align: center;
  font-size: 2rem;
  font-family: 'OpenSansHebrew Bold', serif;
  height: 4rem;
  padding: 1rem 3rem;
  opacity: ${({ opacity }) => opacity};
`;
