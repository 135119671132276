import styled from 'styled-components/macro';

export const Container = styled.form`
  width: 85%;
  margin: 0 auto;
  padding: 4rem 0;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  column-gap: 1rem;
  justify-content: flex-end;
`;
