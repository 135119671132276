import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import Image from 'pages/CMSPage/components/inputTypes/Image/Image';
import * as S from './Checkbox.style';
import ComponentOptions from '../../ComponentOptions/ComponentOptions';
import useImageUpload from 'pages/CMSPage/hooks/useImageUpload';
import useHandleComponentData from 'pages/CMSPage/hooks/useHandleComponentData';

const Checkbox = ({ component }) => {
  const { data } = component;
  const { checkboxTitle, imgUrl, text, title, cta } = data;
  const { handleComponentDataObj } = useHandleComponentData({ component });
  const { uploadImage } = useImageUpload();
  const titleProps = {
    label: 'כותרת מעל התמונה',
    value: title,
    onChange: (event) => handleComponentDataObj('title', event.target.value),
  };
  const imageProps = {
    value: imgUrl,
    label: 'תמונה',
    onChange: async (imageFile) => {
      const url = await uploadImage('imgUrl', imageFile);
      handleComponentDataObj('imgUrl', url);
    },
  };
  //TODO this name should be change to call for action instead of cta
  const ctaProps = {
    label: 'קריאה לפעולה',
    value: cta,
    onChange: (event) => handleComponentDataObj('cta', event.target.value),
  };
  const guidanceProps = {
    label: 'הנחיות',
    value: text,
    onChange: (event) => handleComponentDataObj('text', event.target.value),
  };
  const checkboxTitleProps = {
    label: 'טקסט של הצ׳ק בוקס',
    value: checkboxTitle,
    onChange: (event) => handleComponentDataObj('checkboxTitle', event.target.value),
  };

  return (
    <S.Container>
      <ComponentOptions component={component} />
      <S.Section>
        <S.Wrapper>
          <Input {...titleProps} />
        </S.Wrapper>
        <S.Wrapper>
          <Image {...imageProps} />
        </S.Wrapper>
        <S.Wrapper>
          <Input {...ctaProps} />
        </S.Wrapper>
        <S.Wrapper>
          <Input {...guidanceProps} />
        </S.Wrapper>
        <S.Wrapper>
          <Input {...checkboxTitleProps} />
        </S.Wrapper>
      </S.Section>
    </S.Container>
  );
};

export default Checkbox;
