import axios from 'axios';

import { BASE_URL } from './CONSTANTS';
const getData = (res) => res.data;
let api;

function init({
  token = window.localStorage.getItem('token'),

  baseURL = BASE_URL,
  axiosOptions = { headers: {} },
} = {}) {
  api = axios.create({
    baseURL,
    ...axiosOptions,
    headers: {
      ...axiosOptions.headers,
    },
    auth: {
      username: 'secret',
      password: process.env.REACT_APP_BASIC_AUTH_TOKEN,
    },
  });
}

export { init, api, getData };
