import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  column-gap: 3rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 1rem;
`;

export const Section = styled.div`
  width: 100%;
  row-gap: 2rem;
  display: flex;
  flex-direction: column;
`;
