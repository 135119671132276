import { forwardRef, useEffect, useState } from 'react';
import * as S from './Input.style';
const Input = forwardRef((props, ref) => {
  const [text, setText] = useState();
  const {
    min,
    placeholder,
    onChange,
    value,
    type,
    label,
    children,
    display,
    alert,
    alignSelf,
    required,
    handleOnInvalid,
  } = props;
  useEffect(() => {
    setText(value);
  }, [value]);
  return (
    <>
      <S.Label htmlFor={label}>{label}</S.Label>
      <S.InputWrapper>
        <S.Input
          id={label}
          alignSelf={alignSelf}
          min={min}
          ref={ref}
          placeholder={placeholder}
          value={text}
          onChange={(event) => onChange(event)}
          type={type}
          display={display}
          required={required}
          onInvalid={handleOnInvalid}
        >
          {children}
        </S.Input>
        {alert && <S.AlertMessage>{alert}</S.AlertMessage>}
      </S.InputWrapper>
    </>
  );
});

export default Input;
