import { INFO_FIRST_TITLE, INFO_SECOND_TITLE } from 'config/constants/constants';
import Selector from 'pages/CMSPage/components/inputTypes/Selector/Selector.js';
import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import TextList from 'pages/CMSPage/components/inputTypes/TextList/TextList';
import Image from 'pages/CMSPage/components/inputTypes/Image/Image';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import useDraggableItems from '../../hooks/dragNdrop/useDraggableItems';
import DraggableItems from 'pages/CMSPage/components/DraggableItems/DraggableItems';
import PreviewMode from 'pages/CMSPage/components/PreviewMode/PreviewMode';
import * as S from './MakeEditor.style';
import EditModeLayout from '../../../../layouts/EditModeLayout/EditModeLayout';
import { useEditorContext } from '../../context/editorContext';
import cmsMakeExample from 'assets/icons/cmsMakeExample.png';
import useUpdateMake from '../../hooks/CRUD/update/useUpdateMake';
import usePublishMake from '../../hooks/publish/usePublishMake';
import { isObjectPropertiesFalsy } from 'pages/CMSPage/utils/helpers';
import useMakeEditing from './useMakeEditing';
import closeIcon from 'assets/icons/close_component.svg';
import ActionButton from 'pages/CMSPage/components/ActionButton/ActionButton';
import { CONTENT_CREATORS } from 'pages/CMSPage/Constants/make';
import Teaser from 'pages/CMSPage/components/inputTypes/Teaser/Teaser';
import Thumbnail from 'pages/CMSPage/components/inputTypes/Thumbnail/Thumbnail';
const Make = () => {
  const { handleData, currentSelectedData } = useCmsContext();
  const { onDragEnd, onItemClick } = useDraggableItems();
  const { updateMake } = useUpdateMake();
  const { draggableList, choice } = useEditorContext();
  const { publishMake, unPublishMake } = usePublishMake();
  const {
    onTextChange,
    errors,
    handleQuizMakeCreation,
    handleQuizMakeEdit,
    handleQuizMakeDelete,
  } = useMakeEditing();
  const { track, topic } = choice;
  const {
    makeName,
    makeRoute,
    makeDescription,
    teaser,
    thumbnail,
    info,
    tags = [],
    isCampaign,
    contentCreator,
    publish,
    quizMake,
  } = currentSelectedData;
  const url = `${process.env.REACT_APP_BASE_URL}/track/${track?.trackRoute}/${topic?.topicRoute}/${makeRoute}`;
  const urlProps = { href: url, target: '_blank' };
  const campaginOptions = {
    true: 'כן',
    false: 'לא',
  };

  const draggableItemsProps = {
    draggableList: draggableList,
    onDragEnd: onDragEnd,
    onItemClick: onItemClick,
    shape: 'square',
    direction: 'vertical',
  };

  const makeNameProps = {
    type: 'text',
    label: 'שם ה MAKE',
    value: makeName,
    onChange: (event) => onTextChange('makeName', event),
    alert: errors?.makeName,
  };

  const makeRouteProps = {
    type: 'text',
    label: 'שם ה route',
    value: makeRoute,
    onChange: (event) => onTextChange('makeRoute', event),
    alert: errors?.makeRoute,
  };

  const makeDescriptionProps = {
    type: 'text',
    label: 'כותרת משנה',
    value: makeDescription,
    onChange: (event) => onTextChange('makeDescription', event),
  };
  const teaserProps = {
    label: 'תמונה ראשית',
    handleData: (url) => handleData('teaser', url),
    value: teaser,
  };
  const thumbnailProps = {
    label: 'תמונה ממוזערת',
    handleData: (url) => handleData('thumbnail', url),
    value: thumbnail || '',
  };

  const campaignSelectorProps = {
    options: [...Object.keys(campaginOptions).map((option) => campaginOptions[option])],
    values: [...Object.keys(campaginOptions).map((option) => campaginOptions[option])],
    defaultOption: 'בחר',
    onChange: (newValue) => handleData('isCampaign', newValue === 'כן'),
    label: 'קמפיין',
    value: campaginOptions[isCampaign],
    background: 'var(--color-white)',
    showLabel: true,
    color: 'var(--color-charcoal)',
  };
  const contentCreatorProps = {
    options: [...CONTENT_CREATORS.map((option) => option.name)],
    values: [...CONTENT_CREATORS.map((option) => JSON.stringify(option))],
    defaultOption: 'בחר',
    onChange: (newValue) => handleData('contentCreator', JSON.parse(newValue)),
    label: 'עורך תוכן',
    showLabel: true,
    value: contentCreator && JSON.stringify(contentCreator),
  };

  const firstInfoProps = {
    label: INFO_FIRST_TITLE,
    handleAddItem: () => {
      const updatedList = [...info[0], ''];
      handleData('info', updatedList);
    },
  };

  const secondInfoProps = {
    label: INFO_SECOND_TITLE,
    handleAddItem: () => {
      const updatedList = [...info[1], ''];
      handleData('info', updatedList);
    },
  };

  const tagsProps = {
    label: 'תגיות',
    handleAddItem: () => {
      const updatedList = [...tags, ''];
      handleData('tags', updatedList);
    },
  };

  const updateButtonProps = {
    disabled: isObjectPropertiesFalsy(errors),
    background: 'green',
    handleOnClick: updateMake,
    value: 'עדכן',
  };
  const publishButtonProps = {
    background: 'orange',
    handleOnClick: publish ? unPublishMake : publishMake,
    value: publish ? 'הסר פרסום' : 'פרסם',
  };
  return (
    <EditModeLayout>
      {currentSelectedData && draggableList && (
        <S.Container>
          <S.DraggableItemsContainer>
            <DraggableItems {...draggableItemsProps} />
          </S.DraggableItemsContainer>
          <S.QuizContainer>
            {quizMake ? (
              <>
                <ActionButton
                  value={'מחק מייק שאלון'}
                  background={'var(--color-red)'}
                  handleOnClick={handleQuizMakeDelete}
                />
                <ActionButton
                  value={'ערוך מייק שאלון'}
                  background={'var(--color-blue)'}
                  handleOnClick={handleQuizMakeEdit}
                />
              </>
            ) : (
              <ActionButton
                value={'הוסף מייק שאלון'}
                background={'var(--color-green)'}
                handleOnClick={handleQuizMakeCreation}
              />
            )}
          </S.QuizContainer>
          <S.EditContent>
            <Input {...makeNameProps} />
            <Input {...makeRouteProps} />
            <Input {...makeDescriptionProps} />
            <Selector {...contentCreatorProps} />

            <Teaser {...teaserProps} />
            <Thumbnail {...thumbnailProps} />

            <>
              <Selector {...campaignSelectorProps} />

              {info && (
                <>
                  <TextList {...firstInfoProps}>
                    {info[0]?.map((keyword, index) => (
                      <S.TextInputWrapper>
                        <Input
                          type={'text'}
                          value={keyword}
                          onChange={(event) => {
                            info[0][index] = event.target.value;
                            handleData('info', info);
                          }}
                        />
                        <S.CloseIcon
                          src={closeIcon}
                          onClick={() => {
                            info[0].splice(index, 1);
                            handleData('info', info);
                          }}
                          alt="closeIcon"
                        />
                      </S.TextInputWrapper>
                    ))}
                  </TextList>
                  <TextList {...secondInfoProps}>
                    {info[1]?.map((keyword, index) => (
                      <S.TextInputWrapper>
                        <Input
                          type={'text'}
                          value={keyword}
                          onChange={(event) => {
                            info[1][index] = event.target.value;
                            handleData('info', info);
                          }}
                        />
                        <S.CloseIcon
                          src={closeIcon}
                          onClick={() => {
                            info[1].splice(index, 1);
                            handleData('info', info);
                          }}
                          alt="closeIcon"
                        />
                      </S.TextInputWrapper>
                    ))}
                  </TextList>
                </>
              )}
              <TextList {...tagsProps}>
                {tags?.map((keyword, index) => (
                  <S.TextInputWrapper>
                    <Input
                      type={'text'}
                      value={keyword}
                      onChange={(event) => {
                        tags[index] = event.taget.value;
                        handleData('tags', tags);
                      }}
                    />
                    <S.CloseIcon
                      src={closeIcon}
                      onClick={() => {
                        tags.splice(index, 1);
                        handleData('tags', tags);
                      }}
                      alt="closeIcon"
                    />
                  </S.TextInputWrapper>
                ))}
              </TextList>
            </>
          </S.EditContent>
          <S.PreviewContainer>
            <PreviewMode image={cmsMakeExample}>
              <ActionButton {...updateButtonProps} />
              <ActionButton {...publishButtonProps} />
            </PreviewMode>
            <S.Link {...urlProps}>{'קישור למייק'}</S.Link>
          </S.PreviewContainer>
        </S.Container>
      )}
    </EditModeLayout>
  );
};

export default Make;
