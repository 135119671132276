import useShowAlert from 'hooks/useShowAlert';
import { createContext, useContext, useState } from 'react';

const CmsContext = createContext();

const useCmsContext = () => {
  const context = useContext(CmsContext);
  if (!context) {
    throw new Error('CmsContext must be used within a CMSProvider');
  }
  return context;
};

const CmsProvider = (props) => {
  const alert = useShowAlert();
  const { activeAlert } = alert;
  const [currentSelectedData, setCurrentSelectedData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPreviousBtn, setShowPreviousBtn] = useState(false);
  const [mode, setMode] = useState(null);

  const handleData = (field, newValue) => {
    const newData = {
      ...currentSelectedData,
      [field]: newValue,
    };
    setCurrentSelectedData(newData);
  };

  const value = {
    setMode,
    setCurrentSelectedData,
    setLoading,
    showPreviousBtn,
    setShowPreviousBtn,
    loading,
    handleData,
    currentSelectedData,
    mode,
    alert,
    activeAlert,
  };
  return <CmsContext.Provider value={value} {...props} />;
};
export { CmsProvider, useCmsContext };
