import { createMake } from 'apis/CMS/editor';
import { getMake, getMakesByTopic } from 'apis/makes';
import { getTopics } from 'apis/topics';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import useCreateComponent from './useCreateComponent';

const useCreateMake = () => {
  const handleError = useErrorHandler();
  const { createNewComponent } = useCreateComponent();
  const userId = useSelector((state) => state.auth?.user?._id);
  const { makes, setTopics, setMakes, choice } = useEditorContext();
  const { topic, track } = choice;
  const trackId = track?._id;
  const topicId = topic?._id;
  const createNewMake = async (newTopicId, intialData) => {
    try {
      const intialMakeData = intialData || {
        makeName: `מייק חדש ${makes.length + 1}`,
        makeRoute: `newmake${makes.length + 1}`,
      };
      const topicIdToUpdate = newTopicId || topicId;
      const { data: makeData } = await createMake(
        trackId,
        topicIdToUpdate,
        intialMakeData
      );
      const { make: newMake } = makeData;
      const { _id: newMakeId } = newMake;

      const { data } = await getMakesByTopic(topicIdToUpdate, userId);
      setMakes(data);
      const { data: topicsData } = await getTopics(trackId, userId);
      setTopics(topicsData);
      const intialComponentData = {
        type: 'summary',
        lock: false,
        points: 0,
        publish: true,
        data: {},
      };
      await createNewComponent(topicIdToUpdate, newMakeId, intialComponentData);
      const { data: updatedMake } = await getMake(userId, newMakeId);
      return updatedMake;
    } catch (error) {
      handleError(error);
    }
  };
  return { createNewMake };
};

export default useCreateMake;
