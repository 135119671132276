import { createQuizMake, deleteQuizMake } from 'apis/CMS/quizMake';
import { getMake, getMakesByTopic } from 'apis/makes';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import useImageUpload from 'pages/CMSPage/hooks/useImageUpload';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEditorContext } from '../../context/editorContext';
import useCreateQuizMake from '../QuizEditor/hooks/CRUD/useCreateQuizMake';
import useDeleteQuizMake from '../QuizEditor/hooks/CRUD/useDeleteQuizMake';
import makeSchema from '../../validation/schemas/makeSchema';

const useMakeEditing = () => {
  const userId = useSelector((state) => state.auth?.user?._id);
  const [errors, setErrors] = useState({ makeName: '', makeRoute: '' });
  const { handleData, currentSelectedData, setMode } = useCmsContext();
  const { handleChoiceChange, quizComponents, choice, setMakes, setComponents } =
    useEditorContext();
  const { createNewQuizMake } = useCreateQuizMake();
  const { deleteQuiz } = useDeleteQuizMake();
  const { uploadImage } = useImageUpload();
  const { topic } = choice;
  const topicId = topic?._id;

  const onTextChange = (field, event) => {
    const value = event.target.value.toLowerCase();
    if (errors[field] !== undefined) validateField(field, value);
    validateField(field, value);
    handleData(field, value);
  };
  const onImageUpload = async (imageFile, field) => {
    const url = await uploadImage(imageFile);
    handleData(field, url);
  };

  const validateField = (field, value) => {
    const { error } = makeSchema.extract(field).validate(value);
    if (error) setErrors((prev) => ({ ...prev, [field]: error.message }));
    else setErrors((prev) => ({ ...prev, [field]: '' }));
  };
  const handleQuizMakeCreation = async () => {
    const originalMake = await createNewQuizMake(
      currentSelectedData?._id,
      currentSelectedData.topicId,
      currentSelectedData.trackId
    );
    handleChoiceChange('make', originalMake);
  };
  const handleQuizMakeEdit = () => {
    const firstComponentID = currentSelectedData?.quizMake?.componentsId[0];
    const firstComponent = quizComponents?.find((comp) => comp._id === firstComponentID);
    handleChoiceChange('quizComponent', firstComponent);
    setMode('quizComponent');
  };
  const handleQuizMakeDelete = async () => {
    await deleteQuiz(currentSelectedData?.quizMake?._id, currentSelectedData._id);
    const { data: makeData } = await getMakesByTopic(topicId, userId);
    setMakes(makeData);
    setComponents([]);
    const { data: originalMake } = await getMake(userId, currentSelectedData?._id);
    handleChoiceChange('make', originalMake);
    setMode('make');
  };
  return {
    errors,
    onTextChange,
    onImageUpload,
    handleQuizMakeCreation,
    handleQuizMakeEdit,
    handleQuizMakeDelete,
  };
};

export default useMakeEditing;
