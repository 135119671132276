import { getDiscussionComponentsByMakeId } from 'apis/CMS/editor';
import { getAllDiscussionMakes } from 'apis/CMS/facilitator';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEffect, useState } from 'react';

const useDiscussion = (component) => {
  const { data } = component;
  const { handleData } = useCmsContext();
  const [makes, setMakes] = useState([]);
  const [currentMakeId, setCurrentMakeId] = useState(data?.discussionMakeId);
  const [currentComponentId, setCurrentComponentId] = useState(data?.componentId);
  const [discussionComponents, setDiscussionComponents] = useState([]);
  useEffect(() => {
    const fetchDiscussionMakes = async () => {
      const discussionMakes = await getAllDiscussionMakes();
      setMakes(discussionMakes.data);
    };
    fetchDiscussionMakes();
  }, []);

  useEffect(() => {
    const fetchDiscussionComponents = async () => {
      const { data: componentsData } = await getDiscussionComponentsByMakeId(
        currentMakeId
      );
      setDiscussionComponents(componentsData);
    };
    if (currentMakeId) {
      fetchDiscussionComponents();
    }
  }, [currentMakeId]);

  const handleMakeChange = (value) => {
    setCurrentMakeId(value);
  };
  const handleComponentChange = (value) => {
    const {
      data: discussionData,
      _id: componentId,
      makeId: discussionMakeId,
    } = discussionComponents.find(({ _id }) => _id === value);
    handleData('data', {
      ...data,
      ...discussionData,
      componentId,
      discussionMakeId,
    });
    setCurrentComponentId(value);
  };

  return {
    makes,
    discussionComponents,
    handleMakeChange,
    handleComponentChange,
    currentComponentId,
    currentMakeId,
  };
};

export default useDiscussion;
