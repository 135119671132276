import { API_VERSION } from 'apis/CONSTANTS';
import { api, getData } from 'apis/index';

//makes
export const getAllDiscussionMakes = () =>
  api.get(`${API_VERSION}/makes/discussion/makes`).then(getData);

export const getFacilitatorMakes = () =>
  api.get(`${API_VERSION}/facilitator/getAllMakes`).then(getData);

export const getFacilitatorMake = (makeId) =>
  api.get(`${API_VERSION}/facilitator/makes/${makeId}`).then(getData);

export const deleteFacilitatorMake = (makeId) =>
  api.delete(`${API_VERSION}/facilitator/make/${makeId}/delete`).then(getData);
export const updateFacilitatorMake = (makeId, data) =>
  api.put(`${API_VERSION}/facilitator/make/${makeId}/update`, data).then(getData);

//components

export const getFacilitatorComponents = (makeId) =>
  api.get(`${API_VERSION}/facilitator/make/${makeId}/components`).then(getData);

export const createFacilitatorComponent = (makeId, data) =>
  api.put(`${API_VERSION}/facilitator/${makeId}/components/create`, data).then(getData);

export const createFacilitatorMake = (data) => {
  return api.post(`${API_VERSION}/facilitator/createNewMake`, data).then(getData);
};

export const updateFacilitatorComponent = (componentId, data) => {
  return api
    .put(`${API_VERSION}/facilitator/components/${componentId}/update`, data)
    .then(getData);
};
export const updateFacilitateComponentIndex = (makeId, componentId, data) =>
  api
    .post(
      `${API_VERSION}/facilitator/make/${makeId}/components/${componentId}/updateIndex`,
      data
    )
    .then(getData);
export const deleteFacilitatorComponent = (makeId, componentId) =>
  api
    .delete(`${API_VERSION}/facilitator/make/${makeId}/component/${componentId}/delete`)
    .then(getData);
