import {
  createFacilitatorComponent,
  getFacilitatorComponents,
  getFacilitatorMakes,
} from 'apis/CMS/facilitator';

import { useFacilitatorContext } from '../../../context/useFacilitatorContext';

const useCreateComponent = () => {
  const { setComponents, setMakes, choice } = useFacilitatorContext();
  const { make } = choice;
  const makeId = make?._id;
  const createNewComponent = async (newMakeId) => {
    const intialComponentData = {
      type: 'text',
      lock: false,
      points: 0,
      publish: true,
      data: {},
    };
    const makeIdToUpdate = newMakeId || makeId;

    const { data: newComponent } = await createFacilitatorComponent(
      makeIdToUpdate,
      intialComponentData
    );
    const { data: componentsData } = await getFacilitatorComponents(makeIdToUpdate);
    setComponents(componentsData);
    const { data: makesData } = await getFacilitatorMakes();
    setMakes(makesData);
    return newComponent;
  };
  return { createNewComponent };
};

export default useCreateComponent;
