import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';

export const AvatarBarWrapper = styled.div`
  display: ${({ isLoading }) => (isLoading ? 'none' : 'flex')};
  direction: rtl;
  align-items: center;
  transition: 0.5s ease-in;
  justify-content: space-between;
`;
export const AvatarWithoutImg = styled.div`
  width: 3rem;
  color: white;
  height: 3rem;
  background-color: ${({ extraAvatar }) => extraAvatar && 'var(--color-dark-grey)'};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  font-family: ${primaryFont.bold};
`;

export const AvatarBarListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
