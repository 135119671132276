export const DEFAULTQUERIES = {
  leaderBoard: '?option=general&isOpen=false',
  myMakes: '?option=grid',
  CMS_EDITOR: '?track=&&topic=&&make=&&component=',
};

export const ROOT = '/track/recommendation/topic/popularMakes';
export const LOGIN = '/login';
export const ABOUT = '/about';
export const META_SIGN_UP = '/metasignup';
export const DASHBOARD = '/dashboard';
export const COMPONENT = '/component';
export const INFO = '/info';
export const SAFARI = '/safari';
export const PRIVACY = '/privacy';
export const TERMS = '/terms';
export const PROFILE = '/profile';
export const XP = `${PROFILE}/xp`;
export const LEADER_BOARD = `${PROFILE}/leaderboard${DEFAULTQUERIES.leaderBoard}`;
export const ACHIEVEMENTS = `${PROFILE}/achievements`;
export const MY_MAKES = `${PROFILE}/myMakes${DEFAULTQUERIES.myMakes}`;
export const RECOMMENDATIONS = ROOT;
export const SIGN_IN_OPEN_FROM = {
  DISCOVERY: 'discovery',
  INSIDE_MAKE: 'inside make',
};
export const ON_BOARDING = '/onBoarding';
export const FACILITATOR_DASHBOARD = '/facilitatorDashboard';
export const FACILITATOR_TOURNAMENT = `${FACILITATOR_DASHBOARD}/tournament`;
export const FACILITATOR_TOURNAMENT_CLASS = `${FACILITATOR_TOURNAMENT}/class/`;
export const TOURNAMENT_LEADER_BOARD = `/tournamentLeaderBoard`;
