import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';

export const Container = styled.div`
  width: 100%;
  height: ${({ show }) => (show ? '15rem' : 0)};
  background-color: white;
  color: black;
  padding: ${({ show }) => (show ? '0.5rem 0' : 0)};
  position: fixed;
  overflow-y: hidden;
  z-index: 999;
  left: 0;
  bottom: 0;
  margin: auto;
  transition: all 1s ease-in-out;
`;

export const Title = styled.h1`
  font-family: ${primaryFont.bold};
  text-align: center;
  font-size: 2.4rem;
`;
export const Text = styled.p`
  font-family: ${primaryFont.regular};
  text-align: center;
  font-size: 1.4rem;
`;
