import { useSelector } from 'react-redux';
import MakeSkeleton from 'components/SkeletonLoader/SkeletonDiscovery/MakeSkeleton/MakeSkeleton';

import * as S from './DesktopContent/DesktopContent.styles';

const SkeletonContent = () => {
  const isMobile = useSelector((state) => state.ui.isMobile);

  const renderSkeletonCardsForDesktop = () => {
    return [...Array(6).keys()].map((num) => {
      return <MakeSkeleton key={num} />;
    });
  };
  if (isMobile) return <MakeSkeleton />;
  if (!isMobile) return <S.Container>{renderSkeletonCardsForDesktop()}</S.Container>;
};
export default SkeletonContent;
