import styled from 'styled-components/macro';

export const Answer = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  row-gap: 2rem;
  padding: 1rem 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  position: relative;
  width: 100%;
`;
export const CheckboxWrapper = styled.div`
  position: absolute;
  left: 2.5%;
  top: 60%;
  cursor: pointer;
`;
