import {
  getAllComponentsByMake,
  updateComponentIndex,
  updateMakeIndex,
  updateTopicIndex,
  updateTrackIndex,
} from 'apis/CMS/editor';
import { getMakesByTopic } from 'apis/makes';
import { getTopics } from 'apis/topics';
import { getTracks } from 'apis/tracks';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

const useDraggableItems = () => {
  const handleError = useErrorHandler();
  const { setTracks, setTopics, setMakes, choice, handleChoiceChange, setComponents } =
    useEditorContext();
  const { mode } = useCmsContext();
  const { track, topic, make, component } = choice;

  const userId = useSelector((state) => state.auth?.user?._id);
  const onDragEnd = async (item, destinationIndex, sourceIndex) => {
    try {
      switch (mode) {
        case 'track': {
          await updateTrackIndex(item, {
            destinationIndex,
            sourceIndex,
          });
          const { data: tracksData } = await getTracks(userId);
          setTracks(tracksData);
          return tracksData;
        }
        case 'topic': {
          await updateTopicIndex(track._id, item, {
            destinationIndex,
            sourceIndex,
          });
          const { data: tracksData } = await getTracks(userId);
          setTracks(tracksData);
          const { data: topicsData } = await getTopics(track._id, userId);
          setTopics(topicsData);
          return topicsData;
        }
        case 'make': {
          await updateMakeIndex(topic._id, item, {
            destinationIndex,
            sourceIndex,
          });
          const { data } = await getTopics(track._id, userId);
          setTopics(data);
          const { data: makesData } = await getMakesByTopic(topic._id, userId);
          setMakes(makesData);
          return makesData;
        }
        case 'component': {
          await updateComponentIndex(make._id, component._id, {
            destinationIndex,
            sourceIndex,
          });
          const { data } = await getMakesByTopic(topic._id, userId);
          setMakes(data);
          const { data: componentsData } = await getAllComponentsByMake(
            make?._id,
            userId
          );
          setComponents(componentsData);
          return componentsData;
        }
        default:
          break;
      }
    } catch (error) {
      handleError(error);
    }
  };
  const onItemClick = (item) => {
    handleChoiceChange(mode, item);
  };
  return { onDragEnd, onItemClick };
};

export default useDraggableItems;
