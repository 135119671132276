import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DraggableItem from './components/DraggableItem/DraggableItem';

import * as S from './DraggableItems.style';
import useReorderItems from './hooks/useReorderItems';

/******
**** Properties that component should get ****

--onDragEnd : required -> callback function to handle drag and drop
--draggableList : required -> array of objects -> [
  {
    currentItem, -> current selected item {_id , ...otherprops}
    title,-> title could be any string
    key,-> object key = property u want to display in object
    list ->[{_id,...otherprops}, {_id,...otherprops}]
  }
]
-onClickItem: required -> callback function to handle clicked item in list
-shape : required -> can be square or circle
-direction:optional -> horizontal or vertical , default is horizontal

******/
const DraggableItems = ({ onDragEnd, onItemClick, shape, direction, draggableList }) => {
  const { currentItem, title, key, list } = draggableList;

  const { reorder, itemList } = useReorderItems(list, onDragEnd);
  const getListStyle = (droppableStyle) => {
    return direction === 'horizontal'
      ? S.horizontalListStyle(droppableStyle)
      : S.verticalListStyle(droppableStyle);
  };
  const renderList = () => {
    return (
      <DragDropContext onDragEnd={reorder}>
        <Droppable droppableId="droppable" direction={direction}>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ ...getListStyle(provided.droppableProps.style) }}
            >
              {itemList.map((item, index) => {
                return (
                  <DraggableItem
                    shape={shape}
                    index={index}
                    item={item}
                    itemKey={key}
                    currentItem={currentItem}
                    callback={onItemClick}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  return (
    <div>
      {title && <S.Title>{title}</S.Title>}
      {renderList()}
    </div>
  );
};

export default DraggableItems;
