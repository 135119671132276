import RichTextParser from 'components/RichTextParser';

import * as S from './Alert.styles';

const AlertView = ({ title, text, show }) => {
  return (
    <S.Container show={show}>
      <S.Title>{title}</S.Title>
      <S.Text>
        <RichTextParser data={text} />
      </S.Text>
    </S.Container>
  );
};
export default AlertView;
