import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';
export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  height: 100%;
  width: 100%;
`;
export const TopicName = styled.span`
  text-shadow: 1px 1px 1px var(--color-black);
  font-size: 1.4rem;
  color: var(--color-white);
  padding: 1rem 1rem 0 0;
  position: absolute;
  right: 0;
  top: 0;
`;
export const QuizWrapper = styled.div`
  font-family: ${primaryFont.bold};
  margin-right: auto;
  margin-left: 1rem;
  margin-top: 1rem;
  font-size: 1.6rem;
  text-align: center;
  font-weight: bold;
  padding: 0.5rem 1rem 0.4rem;
  height: 3rem;
  width: fit-content;
  border-radius: 13.5px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
`;

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
