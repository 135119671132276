import * as S from './PreviewMode.style';
const PreviewMode = ({ children, image }) => {
  return (
    <S.Container>
      <S.ImageConainer>
        <S.Image src={image} alt="example" />
        <S.ImageDescription>{'תמונה להמחשה'}</S.ImageDescription>
      </S.ImageConainer>
      <S.ActionButtonsContainer>{children}</S.ActionButtonsContainer>
    </S.Container>
  );
};

export default PreviewMode;
