import { useEffect, useState } from 'react';
import * as S from './Selector.style';

const Selector = (props) => {
  const {
    showLabel,
    options,
    onChange,
    value,
    label,
    color,
    width,
    margin,
    disabled,
    background,
    values,
    defaultOption,
  } = props;
  const [selectedOption, setSelectedOption] = useState(value);
  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  const renderOptions = () => {
    return options?.reduce(
      (acc, option, index) => {
        acc.push(
          <option key={values[index]} value={values[index]}>
            {option}
          </option>
        );
        return acc;
      },
      [
        <option key={defaultOption} value={'DEFAULT'} disabled>
          {defaultOption}
        </option>,
      ]
    );
  };
  return (
    <>
      <S.Label showLabel={showLabel} htmlFor={label}>
        {label}
      </S.Label>
      <S.Select
        id={label}
        name={label}
        value={selectedOption || 'DEFAULT'}
        onChange={(event) => onChange(event.target.value)}
        disabled={disabled}
        width={width}
        margin={margin}
        key={selectedOption}
        background={background}
        color={color}
      >
        {renderOptions()}
      </S.Select>
    </>
  );
};
export default Selector;
