import useQuery from 'hooks/useQuery';
import { useEffect } from 'react';
import { CmsProvider } from './contexts/cmsContext';
import { TournamentProvider } from './contexts/tournamentContext';
import MainEditor from './EditorType/MainEditor/MainEditor';
import TournamentEditor from './TournamentEditor/TournamentEditor';
import FacilitatorMode from './EditorType/Facilitator/FacilitatorMode';
const CMSPage = () => {
  const { getQuery, mutateQuery } = useQuery();
  const editMode = getQuery('mode');

  useEffect(() => {
    if (editMode !== 'facilitator' && editMode !== 'tournament' && editMode !== 'quiz')
      mutateQuery('mode', 'editor');
  }, [editMode]);

  const renderEditMode = () => {
    switch (editMode) {
      case 'facilitator':
        return <FacilitatorMode />;
      case 'tournament':
        return (
          <TournamentProvider>
            <TournamentEditor />;
          </TournamentProvider>
        );

      default:
        return <MainEditor />;
    }
  };
  return (
    <>
      <CmsProvider>{renderEditMode()}</CmsProvider>
    </>
  );
};

export default CMSPage;
