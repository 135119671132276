import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { useGetAvatarUsersQuery } from 'services/api/makesApi/makesApi';
import { customErrorMsg } from 'utils/helpers';

const useGetAvatarUsers = () => {
  const handleError = useErrorHandler();
  const user = useSelector((state) => state.auth.user);
  const trackId = useSelector((state) => state.app?.currentTrack?._id);

  const { data: userDetailsWithOutId, error } = useGetAvatarUsersQuery(trackId, {
    skip: user?._id,
  });
  if (error) {
    console.log('im here');
    return handleError(customErrorMsg('getAvatarUsers', error));
  }

  return { userDetailsWithOutId };
};

export default useGetAvatarUsers;
