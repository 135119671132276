import MetaFeatures from 'components/MetaFeatures';
import { useSelector } from 'react-redux';

import * as S from './AvatarBar.styles';
import AvatarBarList from './components/AvatarBarList';
const AvatarBarView = ({ userDetails }) => {
  const isMobile = useSelector((state) => state.ui.isMobile);

  return (
    <S.AvatarBarWrapper isMobile={isMobile} id="avatarBar">
      <S.AvatarBarListContainer>
        <AvatarBarList userDetails={userDetails} />
      </S.AvatarBarListContainer>
      <MetaFeatures from={'avatarBar'} />
    </S.AvatarBarWrapper>
  );
};

export default AvatarBarView;
