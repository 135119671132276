import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEffect, useRef, useState, forwardRef } from 'react';

const UploadWidget = forwardRef(({ settings, handleData }, ref) => {
  const { currentSelectedData } = useCmsContext();
  const cloudinaryRef = useRef(null);
  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    ref.current = cloudinaryRef.current.createUploadWidget(
      {
        ...settings,
      },
      (error, result) => {
        if (result.event === 'success') {
          const url = result.info.secure_url;

          handleData(url);
        }
        if (error) {
          console.log(error);
        }
      }
    );
  }, [currentSelectedData]);

  return <></>;
});
export default UploadWidget;
