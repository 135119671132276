import ComponentOptions from 'pages/CMSPage/components/ComponentOptions/ComponentOptions';
import { QuestionEditorProvider } from '../../context/questionEditor/questionEditorContext';
import QuestionEditor from '../QuestionEditor/QuestionEditor';
import * as S from './ChoiceQuestion.style';
const ChoiceQuestion = ({ component }) => {
  const { data, type } = component;
  const { questions } = data;
  return (
    <S.Container>
      <S.Section>
        <ComponentOptions component={component} />
      </S.Section>
      <S.Section>
        <QuestionEditorProvider questions={questions}>
          <QuestionEditor type={type} />
        </QuestionEditorProvider>
      </S.Section>
    </S.Container>
  );
};

export default ChoiceQuestion;
