/* eslint-disable react/display-name */
import { forwardRef } from 'react';

import ReactPlayer from 'react-player/lazy';
import * as S from './VideoPlayer.styles';
// import './player.css';

const VideoPlayer = forwardRef(({ children, ...options }, ref) => {
  const autoCaptionConfig = {
    youtube: {
      playerVars: {
        cc_load_policy: 1,
      },
    },
  };
  return (
    <S.PlayerWrapper>
      {children}
      <ReactPlayer {...options} ref={ref} config={autoCaptionConfig} />
    </S.PlayerWrapper>
  );
});

export default VideoPlayer;
