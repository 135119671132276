import useImageUpload from 'pages/CMSPage/hooks/useImageUpload';
import useQuestionsObjHandler from './useQuestionsObjHandler';

const useAnswersHandler = () => {
  const { handeUpdatesInQuestionsObj } = useQuestionsObjHandler();
  const { uploadImage } = useImageUpload();
  const handleChangeInTextAnswer = (newValue, index, question) => {
    question.answers[index].content = newValue;
    handeUpdatesInQuestionsObj(question);
  };
  const handleChangeInImageAnswer = async (event, index, question) => {
    const url = await uploadImage(event);
    question.answers[index].content = url;
    handeUpdatesInQuestionsObj(question);
  };
  const handleCorrectAnswer = (index, question) => {
    question.correctIndexAnswer = index;
    handeUpdatesInQuestionsObj(question);
  };
  return { handleChangeInTextAnswer, handleChangeInImageAnswer, handleCorrectAnswer };
};

export default useAnswersHandler;
