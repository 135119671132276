import Input from 'pages/CMSPage/components/inputTypes/Input/Input';
import useHandleComponentData from 'pages/CMSPage/hooks/useHandleComponentData';
import * as S from './Iframe.style';
const Iframe = ({ component }) => {
  const { handleComponentDataObj } = useHandleComponentData({ component });
  const { data } = component;
  const { iframeUrl, title, subTitle } = data;
  const iframeUrlProps = {
    type: 'text',
    label: 'לינק לiframe',
    value: iframeUrl,
    onChange: (event) => handleComponentDataObj('iframeUrl', event.target.value),
  };
  const titleProps = {
    type: 'text',
    label: 'כותרת',
    value: title,
    onChange: (event) => handleComponentDataObj('title', event.target.value),
  };
  const subTitleProps = {
    type: 'text',
    label: 'תת כותרת',
    value: subTitle,
    onChange: (event) => handleComponentDataObj('subTitle', event.target.value),
  };
  return (
    <S.Container>
      <Input {...iframeUrlProps} />
      <Input {...titleProps} />
      <Input {...subTitleProps} />
    </S.Container>
  );
};

export default Iframe;
