import { useEffect, useState } from 'react';
import * as S from './TextArea.style';
const TextArea = (props) => {
  const { disabled, value, height, onChange, label } = props;

  const [text, setText] = useState('');
  useEffect(() => {
    setText(value), [value];
  });

  return (
    <>
      <S.Label htmlFor={label}>{label}</S.Label>
      <S.TextArea
        id={label}
        disabled={disabled}
        value={text}
        onChange={(event) => onChange(event)}
        height={height}
      />
    </>
  );
};

export default TextArea;
