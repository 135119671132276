import { createContext, useContext, useEffect, useState } from 'react';

const QuestionEditorContext = createContext();

const useQuestionEditorContext = () => {
  const context = useContext(QuestionEditorContext);
  if (!context) {
    throw new Error('useQuestionEditorContext must be used within a CMSProvider');
  }
  return context;
};

const QuestionEditorProvider = (props) => {
  const [questions, setQuestions] = useState(props.questions);
  useEffect(() => {
    setQuestions(props.questions);
  }, [props.questions]);

  const value = { questions, setQuestions };
  return <QuestionEditorContext.Provider value={value} {...props} />;
};
export { QuestionEditorProvider, useQuestionEditorContext };
