import * as S from './MakeCard.style';
import MakeCardDetails from './components/MakeCardDetails/MakeCardDetails';
import Teaser from './components/Teaser/Teaser';

const MakeCard = ({ icons, id, make, topics, index, isIOS, handleOnClick }) => {
  const teaser = make?.teaser;
  const thumbnail = make?.thumbnail;
  const topicName =
    make?.topicNameFromRecommendation ||
    topics?.find((topic) => topic?.makesId?.find((makeId) => makeId === make._id))
      ?.topicName;
  const contentCreator = make?.contentCreator;
  const makeStatus = make?.status;
  const makeName = make?.makeName;

  const makeDescription = make?.makeDescription;

  return (
    <S.Container onClick={(e) => handleOnClick(e, make)} id={id}>
      <Teaser
        make={make}
        handleOnClick={handleOnClick}
        isIOS={isIOS}
        teaser={teaser}
        thumbnail={thumbnail}
        index={index}
        makeStatus={makeStatus}
        contentCreator={contentCreator}
        topicName={topicName}
      />

      <MakeCardDetails
        makeName={makeName}
        makeDescription={makeDescription}
        icons={icons}
      />
    </S.Container>
  );
};

export default MakeCard;
