import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import useDeleteMake from '../CRUD/delete/useDeleteMake';
import useDeleteComponent from '../CRUD/delete/useDeleteComponent';
import { useErrorHandler } from 'react-error-boundary';
import { useFacilitatorContext } from '../../context/useFacilitatorContext';

const useDeleteHandler = () => {
  const handleError = useErrorHandler();
  const { deleteMake } = useDeleteMake();
  const { deleteComponent } = useDeleteComponent();
  const { mode, setMode, activeAlert, setLoading } = useCmsContext();
  const { handleChoiceChange } = useFacilitatorContext();
  const deleteItem = async () => {
    try {
      setLoading(true);
      switch (mode) {
        case 'make': {
          await deleteMake();
          setMode(null);
          handleChoiceChange('make', null);
          break;
        }
        case 'component': {
          await deleteComponent();
          setMode('make');
          handleChoiceChange('component', null);
          break;
        }
      }
      activeAlert({ title: 'הצלחנו', text: `<p>נמחק בהצלחה ${mode}ה</p>` });
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };
  return { deleteItem };
};
export default useDeleteHandler;
