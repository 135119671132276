import { API_VERSION } from 'apis/CONSTANTS';
import { api, getData } from 'apis/index';

//makes

export const getQuizMakes = () =>
  api.get(`${API_VERSION}/quizmake/getAllMakes`).then(getData);

export const getQuizMake = (makeId) =>
  api.get(`${API_VERSION}/quizmake/make/${makeId}`).then(getData);

export const deleteQuizMake = (makeId, originalMakeId) =>
  api
    .delete(
      `${API_VERSION}/quizmake/make/${makeId}/originalMake/${originalMakeId}/delete`
    )
    .then(getData);
export const updateQuizMake = (makeId, data) =>
  api.put(`${API_VERSION}/quizmake/make/${makeId}/update`, data).then(getData);

//components

export const getQuizMakeComponents = (makeId) =>
  api.get(`${API_VERSION}/quizmake/make/${makeId}/components`).then(getData);

export const createQuizMakeComponent = (makeId) =>
  api.put(`${API_VERSION}/quizmake/${makeId}/components/create`).then(getData);

export const createQuizMake = (makeId, topicId, trackId) => {
  return api
    .post(
      `${API_VERSION}/quizmake/createNewMake/make/${makeId}/topic/${topicId}/track/${trackId}`
    )
    .then(getData);
};

export const updateQuizMakeComponent = (componentId, data) => {
  return api
    .put(`${API_VERSION}/quizmake/components/${componentId}/update`, data)
    .then(getData);
};
export const updateQuizMakeComponentIndex = (makeId, componentId, data) =>
  api
    .post(
      `${API_VERSION}/quizmake/make/${makeId}/components/${componentId}/updateIndex`,
      data
    )
    .then(getData);
export const deleteQuizMakeComponent = (makeId, componentId) =>
  api
    .delete(`${API_VERSION}/quizmake/make/${makeId}/component/${componentId}/delete`)
    .then(getData);
