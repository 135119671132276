import { useCmsContext } from '../contexts/cmsContext';

const useHandleComponentData = () => {
  const { currentSelectedData, handleData } = useCmsContext();
  const component = currentSelectedData;
  const { data } = component;

  const handleComponentDataObj = (fieldName, newValue) => {
    handleData('data', {
      ...data,
      [fieldName]: newValue,
    });
  };

  return { handleComponentDataObj };
};

export default useHandleComponentData;
