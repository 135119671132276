import { unPublishMakesByTopicId, publishTopicRequest } from 'apis/CMS/CMS';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';

const usePublishTopic = () => {
  const { activeAlert } = useCmsContext();
  const handleError = useErrorHandler();
  const { choice, setMakes, handleChoiceChange, setComponents } = useEditorContext();
  const { track, topic } = choice;
  const topicId = topic?._id;
  const publishTopic = async () => {
    try {
      if (track.publish) {
        const { data: publishedTopic } = await publishTopicRequest(topicId);
        handleChoiceChange('topic', publishedTopic);
        activeAlert({ title: 'הצלחנו', text: `<p>הטופיק פורסם בהצלחה</p>` });
      } else {
        activeAlert({
          title: 'לא הצלחנו לפרסם',
          text: `<p>צריך לפרסם את הטראק קודם</p>`,
        });
      }
    } catch (error) {
      handleError(error);
    }
  };
  const unPublishTopic = async () => {
    try {
      const {
        data: { topic, makes, components },
      } = await unPublishMakesByTopicId(topicId);
      handleChoiceChange('topic', topic);
      setMakes(makes);
      setComponents(components);
      activeAlert({ title: 'הצלחנו', text: `<p>הפרסום הוסר לטופיק</p>` });
    } catch (error) {
      handleError(error);
    }
  };
  return { publishTopic, unPublishTopic };
};

export default usePublishTopic;
