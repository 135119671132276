import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import useCreateHandler from 'pages/CMSPage/EditorType/MainEditor/hooks/handlers/useCreateHandler';
import useDeleteHandler from 'pages/CMSPage/EditorType/MainEditor/hooks/handlers/useDeleteHandler';

const useMenuHandler = () => {
  const { handleChoiceChange, choice, components, tracks, topics, makes } =
    useEditorContext();
  const { currentSelectedData, mode, setMode } = useCmsContext();
  const { track, topic, make } = choice;
  const { createItem } = useCreateHandler();
  const { deleteItem } = useDeleteHandler();
  const handlePreviousMode = () => {
    switch (mode) {
      case 'track': {
        handleChoiceChange('track', null);
        setMode(null);
        break;
      }
      case 'topic': {
        handleChoiceChange(
          'track',
          tracks.find((t) => t._id === track._id)
        );
        setMode('track');
        break;
      }
      case 'make': {
        handleChoiceChange(
          'topic',
          topics.find((t) => t._id === topic._id)
        );
        setMode('topic');
        break;
      }
      case 'quizComponent':
      case 'component': {
        handleChoiceChange(
          'make',
          makes.find((m) => m._id === make._id)
        );
        setMode('make');
        break;
      }
    }
  };
  const handleSequenceComponentsButton = () => {
    if (mode === 'component' || mode === 'quizComponent') return;
    const firstComponentID = currentSelectedData?.componentsId[0];
    const firstComponent = components.find((comp) => comp._id === firstComponentID);
    handleChoiceChange('component', firstComponent);
    setMode('component');
  };

  const handleSelectorChange = (key, value) => {
    let item;
    switch (key) {
      case 'track': {
        item = tracks?.find((track) => track._id === value);
        break;
      }
      case 'topic': {
        item = topics?.find((topic) => topic._id === value);
        break;
      }
      case 'make': {
        item = makes?.find((make) => make._id === value);
        break;
      }
      default:
        break;
    }
    handleChoiceChange(key, item);
    setMode(key);
  };

  return {
    deleteItem,
    createItem,
    handlePreviousMode,
    handleSequenceComponentsButton,
    handleSelectorChange,
  };
};

export default useMenuHandler;
