import { publishComponentRequest, unPublishComponentRequest } from 'apis/CMS/CMS';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';

const usePublishQuizComponent = () => {
  const { activeAlert } = useCmsContext();
  const handleError = useErrorHandler();
  const { choice, handleChoiceChange } = useEditorContext();
  const { quizComponent, make } = choice;
  const componentId = quizComponent?._id;

  const publishComponent = async () => {
    try {
      const { data: publishedComponent } = await publishComponentRequest(componentId);
      handleChoiceChange('quizComponent', publishedComponent);
      activeAlert({ title: 'הצלחנו', text: `<p>הקומפננטה פורסמה בהצלחה</p>` });
    } catch (error) {
      handleError(error);
    }
    //TODO validaation should be here
  };
  const unPublishComponent = async () => {
    try {
      const { data: component } = await unPublishComponentRequest(componentId);
      handleChoiceChange('quizComponent', component);
      activeAlert({ title: 'הצלחנו', text: `<p>הפרסום הוסר לקומפננטה</p>` });
    } catch (error) {
      handleError(error);
    }
  };
  return { publishComponent, unPublishComponent };
};

export default usePublishQuizComponent;
