import styled, { css } from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const NavBarContainer = styled.div`
  display: flex;
  direction: rtl;
  width: 100%;
  align-items: center;
  margin: 1rem 0 1rem 0;

  @media ${device.tablet} {
    justify-content: center;
    margin: 4rem 0 2.5rem 0;
  }
`;

export const NavBarWrapper = styled.ul`
  display: flex;
  column-gap: 1rem;
  padding-right: 2rem;
  scroll-behavior: smooth;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    padding-right: 0;
  }
`;
