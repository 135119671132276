import useShowAlert from 'hooks/useShowAlert';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';
import useCreateQuizComponent from '../../components/QuizEditor/hooks/CRUD/useCreateQuizComponent';
import useCreateComponent from '../CRUD/create/useCreateComponent';
import useCreateMake from '../CRUD/create/useCreateMake';
import useCreateTopic from '../CRUD/create/useCreateTopic';
import useCreateTrack from '../CRUD/create/useCreateTrack';

const useCreateHandler = () => {
  const handleError = useErrorHandler();
  const { mode, setLoading, activeAlert } = useCmsContext();
  const { handleChoiceChange, choice } = useEditorContext();
  const { make } = choice;
  const { createQuizComponent } = useCreateQuizComponent();
  const { createNewTrack } = useCreateTrack();
  const { createNewTopic } = useCreateTopic();
  const { createNewMake } = useCreateMake();
  const { createNewComponent } = useCreateComponent();
  const createItem = async () => {
    try {
      setLoading(true);
      let data;
      switch (mode) {
        case 'track': {
          data = await createNewTrack();
          break;
        }
        case 'topic': {
          data = await createNewTopic();
          break;
        }
        case 'make': {
          data = await createNewMake();
          break;
        }
        case 'component': {
          data = await createNewComponent();
          break;
        }
        case 'quizComponent': {
          data = await createQuizComponent(make?.quizMake?._id);
          break;
        }
      }
      handleChoiceChange(mode, data);
      activeAlert({ title: 'הצלחנו', text: `<p>נוסף בהצלחה ${mode}ה</p>` });
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };
  return { createItem };
};
export default useCreateHandler;
