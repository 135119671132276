import { deleteTournamentById, getAllTournaments } from 'apis/CMS/tournaments';
import { useTournamentContext } from 'pages/CMSPage/contexts/tournamentContext';
import { useErrorHandler } from 'react-error-boundary';
import { customErrorMsg } from 'utils/helpers';

const useDeleteTournament = () => {
  const handleError = useErrorHandler();
  const {
    setIsLoading,
    setTournaments,
    setCurrentTournament,
    setIsEditing,
    activeAlert,
  } = useTournamentContext();
  const deleteTournament = async (tournamentId) => {
    try {
      setIsLoading(true);
      await deleteTournamentById(tournamentId);
      const { data: allTournaments } = await getAllTournaments();
      setTournaments(allTournaments);
      setCurrentTournament(null);
      setIsEditing(false);
      activeAlert({ title: 'הצלחנו', text: `<p>הטורניר נמחק בהצלחה</p>` });
      setIsLoading(false);
    } catch (error) {
      return handleError(customErrorMsg('deleteTournament', error));
    }
  };
  return { deleteTournament };
};

export default useDeleteTournament;
