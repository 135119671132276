import { createNewTournament } from 'apis/CMS/tournaments';
import { useTournamentContext } from 'pages/CMSPage/contexts/tournamentContext';
import { useErrorHandler } from 'react-error-boundary';
import { customErrorMsg } from 'utils/helpers';

const useCreateTournament = () => {
  const handleError = useErrorHandler();
  const { setIsLoading, activeAlert } = useTournamentContext();
  const createTournament = async () => {
    try {
      setIsLoading(true);
      const result = await createNewTournament();
      activeAlert({ title: 'הצלחנו', text: `<p>הטורניר נוצר בהצלחה</p>` });
      setIsLoading(false);
      return { data: result?.data };
    } catch (error) {
      return handleError(customErrorMsg('createTournament', error));
    }
  };
  return { createTournament };
};

export default useCreateTournament;
