import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';
import { device } from 'utils/helpers/deviceScreen';

export const NavBarContainer = styled.div`
  display: flex;
  direction: rtl;
  width: 100%;
  align-items: center;
  overflow-x: hidden;
`;
export const TrackNav = styled.li`
  display: flex;
  justify-content: right;
  align-items: center;
  text-align: center;
  color: var(--color-black);
  font-size: 1.4rem;

  font-family: ${primaryFont.regular};
  margin-top: 0.7rem;
  cursor: pointer;
  margin-left: 2rem;
  width: 100%;
  white-space: nowrap;
  @media ${device.tablet} {
    font-size: 1.8rem;
  }
`;
export const NavBarWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  scroll-behavior: smooth;
  a {
    font-family: ${primaryFont.regular};
  }
  &::-webkit-scrollbar {
    display: none;
  }
  width: ${({ isMobile }) => (isMobile ? '100%' : '85%')};
  /* margin: ${({ isMobile }) => !isMobile && '0 auto'}; */
  .selected {
    white-space: nowrap;
    ${TrackNav} {
      color: var(--color-red);
      cursor: default;
      transition: all 0.1s ease-in-out;
    }
  }
`;
