import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Image = styled.img`
  max-height: 40%;
  max-width: 40%;
  margin: 12rem auto;
`;

export const Text = styled.p`
  font-family: ${primaryFont.bold};
  font-size: 5rem;
  color: var(--color-charcoal-grey);
  text-align: center;
`;
