import AlertView from './AlertView';

/*
 *  For using the alert you should set a state in the parent component
 *  Then, send the setAlert function to this component as props
 *  This container will set a timer for 3 sec, and then change the alert state to false
 */

const AlertContainer = ({ alert }) => {
  const { active, alertMessage } = alert;

  return (
    <AlertView title={alertMessage?.title} text={alertMessage?.text} show={active} />
  );
};

export default AlertContainer;
