import switchIcon from 'assets/icons/switchIcon.svg';
import trashIcon from 'assets/icons/trashIcon.svg';
import * as S from './Image.style';
import imageUploader from 'assets/icons/imageUploader.svg';

import { useRef } from 'react';
import Input from '../Input/Input';
const Image = (props) => {
  const { value, onChange, label } = props;

  const inputFile = useRef(null);

  const renderImageUploader = () => (
    <>
      <S.FileWrapper
        onClick={() => {
          inputFile.current.click();
        }}
      >
        <S.ImageUploadIcon alt="image" src={imageUploader} />
        <S.ImageUploadText>גרור קובץ או לחץ כדי להוסיף תמונה</S.ImageUploadText>
      </S.FileWrapper>
    </>
  );

  const renderUploadedImageEditor = () => (
    <>
      <S.Image alt={'image'} src={value} />
      <S.ChangeFileContainer>
        <S.DeleteIcon onClick={() => onChange('')}>
          <img alt="trash" src={trashIcon} />
        </S.DeleteIcon>
        <S.SwitchIcon onClick={() => inputFile.current.click()}>
          <img alt="switch" src={switchIcon} />
        </S.SwitchIcon>
      </S.ChangeFileContainer>
    </>
  );
  return (
    <>
      <S.Label htmlFor={label}>{label}</S.Label>
      <S.FileContainer id={label}>
        {value ? renderUploadedImageEditor() : renderImageUploader()}
        <Input
          display="none"
          type={'file'}
          ref={inputFile}
          onChange={(event) => onChange(event.target.files[0])}
        />
      </S.FileContainer>
    </>
  );
};

export default Image;
