import myMakes from 'assets/icons/my_makes.svg';
import ActionButton from 'pages/CMSPage/components/ActionButton/ActionButton';
import ComponentSequenceButton from 'pages/CMSPage/components/ComponentSequenceButton/ComponentSequenceButton';
import Selector from 'pages/CMSPage/components/inputTypes/Selector/Selector';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import * as S from './Menu.style';
import MenuLayout from 'pages/CMSPage/layouts/MenuLayout/MenuLayout';
import useMenuHandler from './hooks/useMenuHandler';
import Circle from 'pages/CMSPage/components/Circle/Circle';
import GoBackButton from 'pages/CMSPage/components/GoBackButton/GoBackButton';
const Menu = () => {
  const {
    tracks,
    makes,
    topics,
    choice,
    components,
    handleChoiceChange,
    quizComponents,
  } = useEditorContext();
  const { mode, currentSelectedData, setMode } = useCmsContext();
  const { make, track, topic } = choice;
  const {
    deleteItem,
    createItem,
    handleSequenceComponentsButton,
    handleSelectorChange,
    handlePreviousMode,
  } = useMenuHandler();

  const renderActionButtonByMode = () => {
    switch (mode) {
      case 'track':
        return `טראק`;
      case 'topic':
        return `טופיק`;
      case 'make':
        return `מייק`;
      case 'quizComponent':
      case 'component':
        return `קומפוננטה`;
      default:
        return ``;
    }
  };

  const selectors = [
    {
      options: [...tracks.map(({ trackName }) => trackName)],
      values: [...tracks.map(({ _id }) => _id)],
      defaultOption: 'בחר טראק',
      showLabel: false,
      onChange: (newValue) => handleSelectorChange('track', newValue),
      value: track?._id,
      label: 'טראק',
      width: '20rem',
    },
    {
      options: [...topics.map(({ topicName }) => topicName)],
      values: [...topics.map(({ _id }) => _id)],
      defaultOption: 'בחר טופיק',
      showLabel: false,
      onChange: (newValue) => handleSelectorChange('topic', newValue),
      value: topic?._id,
      label: 'טופיק',
      width: '20rem',
    },
    {
      options: [...makes.map(({ makeName }) => makeName)],
      values: [...makes.map(({ _id }) => _id)],
      defaultOption: 'בחר מייק',
      showLabel: false,
      onChange: (newValue) => handleSelectorChange('make', newValue),
      value: make?._id,
      label: 'מייק',
      width: '20rem',
    },
  ];

  const renderMenuSelectors = () => {
    return selectors.map((selector, index) => <Selector {...selector} key={index} />);
  };
  const componentSequenceButtonProps = {
    components: components,
    mode: mode,
    callback: handleSequenceComponentsButton,
  };

  const addButtonProps = {
    value: `הוסף ${renderActionButtonByMode()} +`,
    handleOnClick: createItem,
    disabled: !mode,
    background: 'green',
  };
  const deleteButtonProps = {
    value: `מחק ${renderActionButtonByMode()} +`,
    handleOnClick: deleteItem,
    disabled:
      !mode || currentSelectedData?.type === 'summary' || quizComponents.length == 1,
    background: 'red',
  };
  return (
    <>
      <MenuLayout>
        <S.RightContent>
          <Circle
            icon={myMakes}
            handleOnClick={() => {
              handleChoiceChange(null);
              setMode(null);
            }}
          />
          <GoBackButton callback={handlePreviousMode} />
          {mode !== 'quizComponent' && renderMenuSelectors()}
        </S.RightContent>
        {mode !== 'quizComponent' && (
          <ComponentSequenceButton {...componentSequenceButtonProps} />
        )}
        <S.ActionButtonsContainer>
          <ActionButton {...addButtonProps} />
          <ActionButton {...deleteButtonProps} />
        </S.ActionButtonsContainer>
      </MenuLayout>
    </>
  );
};

export default Menu;
