import { store } from 'services/redux/store';

import { metaApi } from '../metaApi/metaApi';
export const getMetaData = async (metaStudentId, type, makeId, topicId) => {
  return await store.dispatch(
    metaApi.endpoints.getMetaContent.initiate({
      metaStudentId,
      convertTo: type,
      makeId,
      topicId,
    })
  );
};
