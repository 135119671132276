import { deleteMakeRequest } from 'apis/CMS/editor';
import { getMakesByTopic } from 'apis/makes';
import { getTopics } from 'apis/topics';
import { useEditorContext } from 'pages/CMSPage/EditorType/MainEditor/context/editorContext';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

const useDeleteMake = () => {
  const handleError = useErrorHandler();
  const userId = useSelector((state) => state.auth?.user?._id);
  const { choice, setTopics, setMakes, setComponents } = useEditorContext();
  const { track, topic, make } = choice;
  const trackId = track?._id;
  const topicId = topic?._id;
  const makeId = make?._id;

  const deleteMake = async () => {
    try {
      await deleteMakeRequest(topicId, makeId);
      const { data: topicData } = await getTopics(trackId, userId);
      setTopics(topicData);
      const { data: makeData } = await getMakesByTopic(topicId, userId);
      setMakes(makeData);
      setComponents([]);
    } catch (error) {
      handleError(error);
    }
  };
  return { deleteMake };
};

export default useDeleteMake;
