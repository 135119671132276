import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';
import { useEffect, useState } from 'react';
import Selector from '../../inputTypes/Selector/Selector';

const TYPES_MINI_GAMES = {
  LevitatingGuru: 'הגורו המרחף',
};

const MiniGames = ({ component }) => {
  const { typeMiniGame } = component;
  const { handleData } = useCmsContext();
  const [gameType, setGameType] = useState(typeMiniGame);

  useEffect(() => {
    if (typeMiniGame) setGameType(typeMiniGame);
  }, [typeMiniGame]);

  const changeGameType = (val) => {
    Object.entries(TYPES_MINI_GAMES).forEach((entry) => {
      const [key, value] = entry;
      if (`משחקון - ${value}` === val) {
        setGameType(value);
        handleData('typeMiniGame', key);
      }
    });
  };

  return (
    <>
      <Selector
        options={Object.keys(TYPES_MINI_GAMES).map(
          (item) => `משחקון - ${TYPES_MINI_GAMES[item]}`
        )}
        values={Object.keys(TYPES_MINI_GAMES).map(
          (item) => `משחקון - ${TYPES_MINI_GAMES[item]}`
        )}
        value={gameType}
        label={'בחר סוג משחק'}
        onChange={(value) => changeGameType(value)}
        defaultOption={'בחר משחק'}
        showLabel
      />
    </>
  );
};

export default MiniGames;
