import styled from 'styled-components/macro';
import plusIcon2 from 'assets/icons/plusIcon2.svg';
import { DefaultLabelStyle } from '../../GlobalStyle/Global.style';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
`;

export const PlusButton = styled.button`
  appearance: none;
  background: var(--color-white);

  background-image: url(${plusIcon2});
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 100%;
  height: 4rem;
`;
export const Label = styled(DefaultLabelStyle)``;
