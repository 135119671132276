import AlertContainer from 'components/Alert';
import Spinner from 'components/Spinner';
import Menu from './components/Menu/Menu';
import { FacilitatorProvider } from './context/useFacilitatorContext';
import MakeEditor from './components/MakeEditor/MakeEditor';
import ComponentEditor from './components/ComponentEditor/ComponentEditor';
import CMSHeader from 'pages/CMSPage/components/CMSHeader/CMSHeader';
import DefaultMenu from 'pages/CMSPage/components/DefaultMenu/DefaultMenu';
import { useCmsContext } from 'pages/CMSPage/contexts/cmsContext';

const FacilitatorMode = () => {
  const { mode, loading, currentSelectedData, alert } = useCmsContext();

  const rednderFacilitatorMode = () => {
    if (!currentSelectedData) return;
    switch (mode) {
      case 'make':
        return <MakeEditor />;
      case 'component':
        return <ComponentEditor />;
      default:
        return <DefaultMenu />;
    }
  };
  return (
    <>
      <AlertContainer alert={alert} />
      {loading && <Spinner />}
      <CMSHeader />
      <FacilitatorProvider>
        <Menu />
        {rednderFacilitatorMode()}
      </FacilitatorProvider>
    </>
  );
};

export default FacilitatorMode;
