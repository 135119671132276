import { useEffect, useState } from 'react';

const useReorderItems = (list, onDragEnd) => {
  const [itemList, setItemList] = useState(list);

  useEffect(() => {
    setItemList(list);
  }, [list]);

  const reorder = (result) => {
    const { draggableId: itemId, destination, source } = result;
    if (!destination || !source) return;
    const { index: destinationIndex } = destination;
    const { index: sourceIndex } = source;
    const newItemList = Array.from(itemList);
    const [removed] = newItemList.splice(sourceIndex, 1);
    newItemList.splice(destinationIndex, 0, removed);
    setItemList([...newItemList]);
    onDragEnd(itemId, destinationIndex, sourceIndex);
  };
  return { reorder, itemList };
};

export default useReorderItems;
